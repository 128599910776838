import { useCallback, useState } from 'react';
import { AxiosError, Method } from 'axios';

import { $api } from '../http';

export interface UseHttpI {
  url: string;
  method: Method;
  body?: any;
  headers?: any;
  params?: any;
}

export const useHttp = () => {
  const [loading, setLoading] = useState(false);
  const [httpError, setHttpError] = useState('');
  const request = useCallback(
    async ({
      url,
      method = 'GET',
      body = null,
      headers = {},
      params = null,
    }: UseHttpI) => {
      setLoading(true);
      try {
        const response = await $api({
          method: method,
          url: url,
          data: body,
          headers: headers,
          params: params,
        });

        setLoading(false);
        return response;
      } catch (err) {
        setLoading(false);
        if (err instanceof AxiosError) {
          if (err.response) setHttpError(err.response.data.message);
          else setHttpError(err.message);
          throw err;
        } else {
          throw err;
        }
      }
    },
    [],
  );
  const clearError = useCallback(() => setHttpError(''), []);

  return { loading, request, httpError, clearError };
};
