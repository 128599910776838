export interface IPaymentOnline {
  id: number;
  paymentSystem: string;
  paymentSystemId: number;
  paymentSystemOrderId: string;
  entity: string;
  entityId: number;
  userId: number;
  paymentStatusId: number;
  status: number;
  phone: string;
  email: string;
  sum: number;
  kkmId: string;
  kkmStatus: number;
  numberOnec: string;
  idOnec: string;
  paymentsReferenceId: number;
}

export interface PaymentOnlineState {
  paymentsOnline: IPaymentOnline[];
  error: string | null;
  loading: boolean;
  currentPage: number;
  limit: number;
  totalPaymentsOnline: number;
}

export enum PaymentOnlineActionTypes {
  FETCH_PAYMENT_ONLINE = 'FETCH_PAYMENT_ONLINE',
  FETCH_PAYMENTS_ONLINE = 'FETCH_PAYMENTS_ONLINE',
  FETCH_PAYMENTS_ONLINE_ERROR = 'FETCH_PAYMENTS_ONLINE_ERROR',
  SET_PAYMENT_ONLINE_PAGE = 'SET_PAYMENT_ONLINE_PAGE',
  SET_TOTAL_PAYMENT_ONLINE_PAGE = 'SET_TOTAL_PAYMENT_ONLINE_PAGE',
}

interface FetchPaymentOnlineAction {
  type: PaymentOnlineActionTypes.FETCH_PAYMENT_ONLINE;
}

interface FetchPaymentsOnlineAction {
  type: PaymentOnlineActionTypes.FETCH_PAYMENTS_ONLINE;
  payload: IPaymentOnline[];
}

interface FetchPaymentsOnlineErrorAction {
  type: PaymentOnlineActionTypes.FETCH_PAYMENTS_ONLINE_ERROR;
  payload: string;
}

interface SetPaymentOnlinePageAction {
  type: PaymentOnlineActionTypes.SET_PAYMENT_ONLINE_PAGE;
  payload: number;
}

interface SetTotalPaymentOnlinePageAction {
  type: PaymentOnlineActionTypes.SET_TOTAL_PAYMENT_ONLINE_PAGE;
  payload: number;
}

export type PaymentOnlineAction =
  | FetchPaymentOnlineAction
  | FetchPaymentsOnlineAction
  | FetchPaymentsOnlineErrorAction
  | SetPaymentOnlinePageAction
  | SetTotalPaymentOnlinePageAction;
