export interface IAttributeGroup {
  id: number;
  name: string | null;
  position: number;
}

export interface AttributeGroupState {
  attributeGroups: IAttributeGroup[];
  error: string | null;
  loading: boolean;
  currentPage: number;
  limit: number;
  totalAttributeGroups: number;
}

export enum AttributeGroupActionTypes {
  FETCH_ATTRIBUTE_GROUP = 'FETCH_ATTRIBUTE_GROUP',
  FETCH_ATTRIBUTE_GROUPS = 'FETCH_ATTRIBUTE_GROUPS',
  FETCH_ATTRIBUTE_GROUPS_ERROR = 'FETCH_ATTRIBUTE_GROUPS_ERROR',
  SET_ATTRIBUTE_GROUP_PAGE = 'SET_ATTRIBUTE_GROUP_PAGE',
  SET_TOTAL_ATTRIBUTE_GROUP_PAGE = 'SET_TOTAL_ATTRIBUTE_GROUP_PAGE',
  FETCH_ATTRIBUTE_GROUPS_SELECT = 'FETCH_ATTRIBUTE_GROUPS_SELECT',
}

interface FetchAttributeGroupAction {
  type: AttributeGroupActionTypes.FETCH_ATTRIBUTE_GROUP;
}

interface FetchAttributeGroupsAction {
  type: AttributeGroupActionTypes.FETCH_ATTRIBUTE_GROUPS;
  payload: IAttributeGroup[];
}

interface FetchAttributeGroupsErrorAction {
  type: AttributeGroupActionTypes.FETCH_ATTRIBUTE_GROUPS_ERROR;
  payload: string;
}

interface SetAttributeGroupAction {
  type: AttributeGroupActionTypes.SET_ATTRIBUTE_GROUP_PAGE;
  payload: number;
}

interface SetTotalAttributeGroupPageAction {
  type: AttributeGroupActionTypes.SET_TOTAL_ATTRIBUTE_GROUP_PAGE;
  payload: number;
}

interface FetchAttributeGroupsSelectAction {
  type: AttributeGroupActionTypes.FETCH_ATTRIBUTE_GROUPS_SELECT;
  payload: IAttributeGroup[];
}

export type AttributeGroupAction =
  | FetchAttributeGroupAction
  | FetchAttributeGroupsAction
  | FetchAttributeGroupsErrorAction
  | SetAttributeGroupAction
  | SetTotalAttributeGroupPageAction
  | FetchAttributeGroupsSelectAction;
