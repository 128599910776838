import React, { useCallback, useEffect, useState } from "react";
import { Button, Form, Modal, Upload } from "antd";
import { RcFile, UploadFile, UploadProps } from "antd/es/upload/interface";
import { useNavigate, useParams } from "react-router-dom";

import styles from "./Image.module.scss";
import { useHttp, UseHttpI } from "../../../hooks/useHttp";
import { ApiUrl } from "../../../types/ApiUrl";
import NotificationAlert from "../../../components/Notification";
import { statusImageOptions } from "../../../helpers/imageHelper";
import { SelectOptionHelper } from "../../../helpers/selectHelper";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useActions } from "../../../hooks/useActions";
import { useHttpUploadAntd } from "../../../hooks/useHttpUploadAntd";
import FormInput from "../../../components/FormItems/FormInput";
import FormSelect from "../../../components/FormItems/FormSelect";
import { useTrimField } from "../../../hooks/useTrimField";

interface ImageProps {
  titlePage: string;
  submitMethod: string;
}

export const Image: React.FC<ImageProps> = ({ titlePage, submitMethod }) => {
  const { geoZones } = useTypedSelector((state) => state.geoZone);
  const { user } = useTypedSelector((state) => state.auth);
  const { sliderGalleries } = useTypedSelector((state) => state.sliderGallery);

  const navigate = useNavigate();

  const { id } = useParams();
  const { request } = useHttp();
  const { requestUpload } = useHttpUploadAntd();

  const { fetchGeoZonesForSelect, fetchSliderGalleries } = useActions();

  const [fileList, setFileList] = useState<UploadFile[]>([]);
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");

  const geoZoneOptions = SelectOptionHelper(geoZones);
  const sliderOptions = SelectOptionHelper(sliderGalleries);

  const getData = useCallback(async () => {
    if (id) {
      try {
        const fetchImage = await request({
          url: `${ApiUrl.IMAGE}/${id}`,
          method: "get"
        });

        if (fetchImage.data?.filePath) {
          setFileList([
            {
              uid: id,
              name: fetchImage.data?.filePath,
              status: "done",
              url: `${ApiUrl.FILE}/${fetchImage.data?.filePath}?dir=image&root=0`
            }
          ]);
        }

        const sliders = fetchImage.data.sliders;

        const gallery = sliders.map((item: any) => {
          return item.id;
        });

        const position = sliders.map((item: any) => {
          return item.SliderGalleryImage.position;
        });

        form.setFieldsValue({
          name: fetchImage.data?.name,
          description: fetchImage.data?.description,
          updateUserId: fetchImage.data?.updateUserId,
          alt: fetchImage.data?.alt,
          link: fetchImage.data?.link,
          geoZoneId: fetchImage.data?.geoZoneId,
          status: fetchImage.data?.status.toString(),
          sliders: sliders.length > 0 ? gallery : undefined,
          position: sliders.length > 0 ? Number(position) : undefined
        });
      } catch (e) {
      }
    }
  }, [request]);

  const [form] = Form.useForm();

  useEffect(() => {
    fetchSliderGalleries({ limit: "0" });
    fetchGeoZonesForSelect();
    getData();
    if (!id) {
      form.setFieldsValue({
        status: "0"
      });
    }
  }, [getData]);

  const onChange: UploadProps["onChange"] = ({ fileList: newFileList }) => {
    setFileList(newFileList);
  };

  const handleCancel = () => setPreviewOpen(false);

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const onPreview = async (file: UploadFile) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setPreviewImage(file.url || (file.preview as string));
    setPreviewOpen(true);
    setPreviewTitle(file.name);
  };

  const onFinish = async (e: any) => {
    try {
      useTrimField(form, null, e);

      const link = form.getFieldValue("link") ? form.getFieldValue("link") : "/";

      const requestObject: UseHttpI =
        submitMethod === "add"
          ? {
            url: ApiUrl.IMAGE,
            method: "post",
            body: {
              ...e,
              link: link,
              updateUserId: user?.id,
              filePath: fileList[0]?.response?.name
                ? fileList[0].response.name
                : null
            }
          }
          : {
            url: `${ApiUrl.IMAGE}/${id}`,
            method: "put",
            body: {
              ...e,
              link: link,
              updateUserId: user?.id,
              filePath: fileList[0]?.response?.name
                ? fileList[0].response.name
                : fileList[0]?.name
                  ? fileList[0].name
                  : null
            }
          };

      const response = await request(requestObject);
      switch (response.config.method) {
        case "post":
          navigate("/content/images/list");
          return NotificationAlert("success", "Изображение добавлено");
        case "put":
          return NotificationAlert("success", "Изображение обновлено");
        default:
          return NotificationAlert("error", "Упс");
      }
    } catch (err: any) {
      if (err.response.data.statusCode === 403) {
        return NotificationAlert(
          "error",
          "Изображение не добавлено, попробуйте загрузить изображение."
        );
      } else {
        return NotificationAlert("error", err.message);
      }
    }
  };

  return (
    <div className={styles.main}>
      <h1>{titlePage}</h1>
      <Form onFinish={onFinish} form={form} labelCol={{ span: 6 }}>
        <FormInput
          name={"name"}
          label={"Название"}
          placeholder={"Введите название картинки"}
          required={true}
          labelCol={{ span: 24 }}
        />
        <FormInput
          name={"alt"}
          label={"Альтернативный текст"}
          placeholder={"Введите альтернативный текст"}
          required={true}
          labelCol={{ span: 24 }}
        />
        <FormInput
          name={"description"}
          label={"Описание"}
          placeholder={"Описание"}
          textArea={true}
          labelCol={{ span: 24 }}
        />
        <FormInput
          name={"link"}
          label={"Ссылка"}
          placeholder={"Введите ссылку"}
          labelCol={{ span: 24 }}
        />
        <FormSelect
          name={"sliders"}
          label={"Галерея"}
          placeholder={"Выберите галерею"}
          options={sliderOptions}
          showSearch={true}
          mode={"multiple"}
          labelCol={{ span: 24 }}
        />
        <FormSelect
          name={"status"}
          label={"Статус"}
          placeholder={"Выберите статус"}
          options={statusImageOptions}
          labelCol={{ span: 24 }}
        />
        <FormSelect
          name={"geoZoneId"}
          label={"Геозона"}
          placeholder={"Выберите геозону"}
          options={geoZoneOptions}
          showSearch={true}
          labelCol={{ span: 24 }}
        />
        <FormInput
          name={"position"}
          label={"Позиция (number)"}
          placeholder={"Введите позицию"}
          type={"number"}
          labelCol={{ span: 24 }}
        />
        <Upload
          action="/api/image/file"
          listType="picture-card"
          fileList={fileList}
          customRequest={requestUpload}
          onChange={onChange}
          onPreview={onPreview}
          accept=".jpg,.jpeg,.png,.gif"
        >
          {fileList && fileList.length < 1 && "+ Загрузить изображение"}
        </Upload>
        <Modal
          open={previewOpen}
          title={previewTitle}
          footer={null}
          onCancel={handleCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {submitMethod === "add" ? "Добавить" : "Обновить"}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
