import { Dispatch } from 'redux';

import { ApiUrl } from '../../types/ApiUrl';
import { $api } from '../../http';
import {
  SliderGalleryAction,
  SliderGalleryActionTypes,
} from '../../types/entities/SliderGallery';

interface querySliderGalleryParamsInterface {
  limit?: any;
  page?: any;
  sortId?: string;
  name?: string;
  description?: string;
  status?: string;
}

export const fetchSliderGalleries = (
  queryParams: querySliderGalleryParamsInterface,
) => {
  const {
    limit = '25',
    page = 1,
    sortId = 'DESC',
    name,
    description,
    status,
  } = queryParams;

  const urlSliderGalleryParams = new URLSearchParams();
  urlSliderGalleryParams.append('limit', limit);
  urlSliderGalleryParams.append('page', page);
  urlSliderGalleryParams.append('sortId', sortId);
  if (name !== undefined) urlSliderGalleryParams.append('name', name);
  if (description !== undefined)
    urlSliderGalleryParams.append('description', description);
  if (status !== undefined) urlSliderGalleryParams.append('status', status);

  return async (dispatch: Dispatch<SliderGalleryAction>) => {
    try {
      dispatch({ type: SliderGalleryActionTypes.FETCH_SLIDER_GALLERY });
      const response = await $api.get(
        `${ApiUrl.SLIDER_GALLERY}?${urlSliderGalleryParams.toString()}`,
      );
      dispatch({
        type: SliderGalleryActionTypes.FETCH_SLIDER_GALLERIES,
        payload: response.data.items,
      });
      dispatch({
        type: SliderGalleryActionTypes.SET_TOTAL_SLIDER_GALLERY_PAGE,
        payload: response.data.total,
      });
      dispatch({
        type: SliderGalleryActionTypes.SET_SLIDER_GALLERY_PAGE,
        payload: page,
      });
    } catch (e) {
      dispatch({
        type: SliderGalleryActionTypes.FETCH_SLIDER_GALLERIES_ERROR,
        payload: 'Произошла ошибка при загрузке слайдеров',
      });
    }
  };
};
