import React from 'react';
import { Link, Route } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import { ComponentsLayout } from '../../layout/ComponentsLayout/ComponentsLayout';
import { AccessMap } from '../../Pages/Roles/AccessMap';
import { AddRole } from '../../Pages/Roles/Role/AddRole/AddRole';
import { UpdateRole } from '../../Pages/Roles/Role/UpdateRole/UpdateRole';
import { RolesList } from '../../Pages/Roles/RolesList/RolesList';

export const RolesRoutes = (
  <>
    <Route
      path="/roles/add"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <AddRole />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/roles/update/:value"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <UpdateRole />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/roles/list"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <RolesList />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/roles/access-map"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <AccessMap />
          </ComponentsLayout>
        </>
      }
    />
  </>
);
