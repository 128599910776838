import { notification } from 'antd';

interface OpenNotificationI {
  message: string;
  description?: string;
  onClick?: any;
}

export const OpenNotification = ({
  message,
  description,
  onClick,
}: OpenNotificationI) => {
  notification.open({
    message,
    description,
    onClick,
  });
};
