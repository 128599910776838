import React from 'react';
import { Link, Route } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import { ComponentsLayout } from '../../layout/ComponentsLayout/ComponentsLayout';
import { PaymentsOnlineList } from '../../Pages/PaymentsOnline/PaymentsOnlineList/PaymentsOnlineList';
import { UpdatePaymentOnline } from '../../Pages/PaymentsOnline/PaymentOnline/UpdatePaymentOnline/UpdatePaymentOnline';
import { AddPaymentOnline } from '../../Pages/PaymentsOnline/PaymentOnline/AddPaymentOnline/AddPaymentOnline';

export const PaymentOnlineRoutes = (
  <>
    <Route
      path="/services/payment/payment-online/add"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Добавить онлайн платёж</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <AddPaymentOnline />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/services/payment/payment-online/update/:id"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/services/payment/payment-online/list">
                Список онлайн платежей
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Обновление онлайн платежа</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <UpdatePaymentOnline />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/services/payment/payment-online/list"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Список онлайн платежей</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <PaymentsOnlineList />
          </ComponentsLayout>
        </>
      }
    />
  </>
);
