import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Pagination, Row, Table } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import styles from './CategoriesList.module.scss';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useActions } from '../../../hooks/useActions';
import { createTree } from '../../../helpers/selectHelper';
import { statusCategoryOptions } from '../../../helpers/categoryHelper';
import { StatusEditor } from '../../../components/Editors/StatusEditor';
import { ApiUrl } from '../../../types/ApiUrl';
import FormTreeSelect from '../../../components/FormItems/FormTreeSelect';
import FormSelect from '../../../components/FormItems/FormSelect';

export const CategoriesList: React.FC = () => {
  const { categories, categoriesList, limit, currentPage, totalCategories } =
    useTypedSelector((state) => state.category);

  const [sort, setSort] = useState('DESC');
  const [edit, setEdit] = useState(false);

  const categoryTree = createTree(categories);

  const { fetchCategoriesList, fetchCategoriesForSelect } = useActions();

  const [form] = Form.useForm();

  useEffect(() => {
    let parents;
    if (Array.isArray(form.getFieldValue('parent'))) {
      parents = form.getFieldValue('parent').join(',');
    }
    let categoryIds;
    if (Array.isArray(form.getFieldValue('id'))) {
      categoryIds = form.getFieldValue('id').join(',');
    }
    fetchCategoriesForSelect();

    fetchCategoriesList({
      limit: limit,
      page: currentPage,
      sortId: sort,
      id: categoryIds,
      parentId: parents,
      status: form.getFieldValue('status'),
    });
  }, [edit]);

  const onShowSizeChange = (currentPage: number, limit: number) => {
    let parents;
    if (Array.isArray(form.getFieldValue('parent'))) {
      parents = form.getFieldValue('parent').join(',');
    }
    let categoryIds;
    if (Array.isArray(form.getFieldValue('id'))) {
      categoryIds = form.getFieldValue('id').join(',');
    }
    fetchCategoriesList({
      limit: limit,
      page: currentPage,
      sortId: sort,
      id: categoryIds,
      parentId: parents,
      status: form.getFieldValue('status'),
    });
  };

  const handlePagination = (currentPage: number, limit: number) => {
    let parents;
    if (Array.isArray(form.getFieldValue('parent'))) {
      parents = form.getFieldValue('parent').join(',');
    }
    let categoryIds;
    if (Array.isArray(form.getFieldValue('id'))) {
      categoryIds = form.getFieldValue('id').join(',');
    }
    fetchCategoriesList({
      limit: limit,
      page: currentPage,
      sortId: sort,
      id: categoryIds,
      parentId: parents,
      status: form.getFieldValue('status'),
    });
  };

  const applyFilters = () => {
    let categoryIds;
    if (Array.isArray(form.getFieldValue('id'))) {
      categoryIds = form.getFieldValue('id').join(',');
    }
    let parents;
    if (Array.isArray(form.getFieldValue('parent'))) {
      parents = form.getFieldValue('parent').join(',');
    }
    fetchCategoriesList({
      limit: limit,
      page: 1,
      sortId: sort,
      id: categoryIds,
      parentId: parents,
      status: form.getFieldValue('status'),
    });
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.field === 'id') {
      if (sorter.order === 'ascend') {
        setSort('ASC');
        fetchCategoriesList({ limit: limit, page: currentPage, sortId: 'ASC' });
      } else {
        setSort('DESC');
        fetchCategoriesList({
          limit: limit,
          page: currentPage,
          sortId: 'DESC',
        });
      }
    }
  };

  const columns = [
    {
      title: 'ID категории',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => {
        return (
          <div key={id}>
            <Link to={`/categories/update/${id}`}>{id}</Link>
          </div>
        );
      },
      sorter: true,
    },
    {
      title: 'Изображение',
      dataIndex: 'image',
      key: 'image',
      render: (image: string) => {
        if (image) {
          return (
            <img
              alt="Изображение категории"
              style={{ maxWidth: 150 }}
              src={`${ApiUrl.FILE}/${image}?dir=store,category&root=0`}
            />
          );
        } else {
          return 'Изображение не выбрано';
        }
      },
    },
    {
      title: 'Наименование категории',
      dataIndex: 'name',
      key: 'name',
      render: (name: string) => {
        if (name) {
          return <>{name}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Алиас',
      dataIndex: 'slug',
      key: 'slug',
      render: (slug: string) => {
        if (slug) {
          return <>{slug}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Родительская категория',
      dataIndex: 'parentCategory',
      key: 'parentCategory',
      render: (parentCategory: any) => {
        if (parentCategory) {
          return <>{parentCategory.name}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Статус',
      key: 'status',
      render: (data: any) => {
        return (
          <StatusEditor
            status={data.status}
            id={data.id}
            options={statusCategoryOptions}
            setEdit={setEdit}
            url={ApiUrl.UPDATE_CATEGORY_STATUS}
          />
        );
      },
    },

    {
      title: 'Действия с товаром',
      dataIndex: 'id',
      key: 'edit',
      render: (id: string) => {
        return (
          <>
            <Link to={`/categories/update/${id}`}>
              <EditOutlined style={{ marginLeft: 50 }} />
            </Link>
          </>
        );
      },
    },
  ];

  return (
    <div className={styles.main}>
      <h1>Список категорий</h1>
      <h4 style={{ textAlign: 'left' }}>Фильтры полей</h4>
      <Form form={form}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <FormTreeSelect
              name={'id'}
              label={'Название категории'}
              placeholder={'Выберите категорию'}
              treeData={categoryTree}
              labelCol={{ span: 24 }}
              showSearch={true}
              multiple={true}
            />
          </Col>
          <Col span={12}>
            <FormSelect
              name={'status'}
              label={'Статус'}
              placeholder={'Выберите статус'}
              options={statusCategoryOptions}
              showSearch={true}
              labelCol={{ span: 24 }}
            />
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <FormTreeSelect
              showSearch={true}
              name={'parent'}
              label={'Родительская категория'}
              placeholder={'Выберите категорию'}
              treeData={categoryTree}
              labelCol={{ span: 24 }}
              multiple={true}
            />
          </Col>
          <Col span={12}>
            <Button
              style={{ marginTop: 40 }}
              type="primary"
              htmlType="submit"
              onClick={applyFilters}
            >
              Применить фильтры
            </Button>
            <Button
              style={{ marginLeft: 20 }}
              type="ghost"
              htmlType="reset"
              onClick={() => {
                return fetchCategoriesList({
                  limit: limit,
                  page: 1,
                  sortId: sort,
                });
              }}
            >
              Очистить фильтры
            </Button>
          </Col>
        </Row>
      </Form>
      <Pagination
        style={{ marginBottom: 20 }}
        total={totalCategories}
        current={currentPage}
        showQuickJumper={true}
        onChange={handlePagination}
        onShowSizeChange={onShowSizeChange}
        pageSizeOptions={[10, 25, 50, 100]}
        defaultPageSize={25}
      />
      <Table
        bordered
        columns={columns}
        dataSource={categoriesList}
        pagination={false}
        onChange={handleChange}
        rowKey="id"
      />
    </div>
  );
};
