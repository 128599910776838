import React from 'react';

import { Shop } from '../Shop';
import { UPDATE_SUBMIT_METHOD } from '../../../../types/constants';

export const UpdateShop: React.FC = () => {
  const titlePage = 'Обновление магазина';
  const submitMethod = UPDATE_SUBMIT_METHOD;

  const props = {
    titlePage: titlePage,
    submitMethod: submitMethod,
  };

  return (
    <>
      <Shop {...props} />
    </>
  );
};
