import { combineReducers } from 'redux';

import { productReducer } from './productReducer';
import { categoryReducer } from './categoryReducer';
import { producerReducer } from './producerReducer';
import { shopReducer } from './shopReducer';
import { orderReducer } from './order/orderReducer';
import { userReducer } from './userReducer';
import { deliveryReducer } from './deliveryReducer';
import { paymentReducer } from './payment/paymentReducer';
import { authReducer } from './authReducer';
import { attributeReducer } from './attribute/attributeReducer';
import { attributeGroupReducer } from './attribute/attributeGroupReducer';
import { typeReducer } from './typeReducer';
import { sliderGalleryReducer } from './sliderGalleryReducer';
import { menuReducer } from './menu/menuReducer';
import { menuItemReducer } from './menu/menuItemReducer';
import { productSliderGroupReducer } from './productSliderGroupReducer';
import { geoZoneReducer } from './geo/geoZoneReducer';
import { roleReducer } from './roleReducer';
import { geoRegionReducer } from './geo/geoRegionReducer';
import { geoCityReducer } from './geo/geoCityReducer';
import { orderHistoryReducer } from './order/orderHistoryReducer';
import { newsReducer } from './newsReducer';
import { pageReducer } from './pageReducer';
import { paymentOnlineReducer } from './payment/paymentOnlineReducer';
import { productLogReducer } from './productLogReducer';
import { paymentReferenceReducer } from './payment/paymentReferenceReducer';
import { imageReducer } from './imageReducer';

export const rootReducer = combineReducers({
  product: productReducer,
  category: categoryReducer,
  producer: producerReducer,
  shop: shopReducer,
  productSliderGroup: productSliderGroupReducer,
  order: orderReducer,
  orderHistory: orderHistoryReducer,
  attribute: attributeReducer,
  attributeGroup: attributeGroupReducer,
  type: typeReducer,
  user: userReducer,
  delivery: deliveryReducer,
  payment: paymentReducer,
  paymentOnline: paymentOnlineReducer,
  paymentReference: paymentReferenceReducer,
  sliderGallery: sliderGalleryReducer,
  menu: menuReducer,
  menuItem: menuItemReducer,
  auth: authReducer,
  role: roleReducer,
  geoZone: geoZoneReducer,
  geoRegion: geoRegionReducer,
  geoCity: geoCityReducer,
  news: newsReducer,
  page: pageReducer,
  productLog: productLogReducer,
  image: imageReducer,
});

export type RootState = ReturnType<typeof rootReducer>;
