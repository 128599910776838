import {
  PaymentReferenceAction,
  PaymentReferenceActionTypes,
  PaymentReferenceState,
} from '../../../types/entities/Payment/PaymentReference';

const initialState: PaymentReferenceState = {
  paymentsReference: [],
  error: null,
  loading: false,
  currentPage: 1,
  limit: 25,
  totalPaymentsReference: 0,
};

export const paymentReferenceReducer = (
  state = initialState,
  action: PaymentReferenceAction,
): PaymentReferenceState => {
  switch (action.type) {
    case PaymentReferenceActionTypes.FETCH_PAYMENT_REFERENCE:
      return { ...state, loading: true };
    case PaymentReferenceActionTypes.FETCH_PAYMENTS_REFERENCE_ERROR:
      return { ...state, error: action.payload, loading: false };
    case PaymentReferenceActionTypes.FETCH_PAYMENTS_REFERENCE:
      return {
        ...state,
        error: null,
        paymentsReference: action.payload,
        loading: false,
      };
    case PaymentReferenceActionTypes.SET_PAYMENT_REFERENCE_PAGE:
      return {
        ...state,
        error: null,
        currentPage: action.payload,
        loading: false,
      };
    case PaymentReferenceActionTypes.SET_TOTAL_PAYMENT_REFERENCE_PAGE:
      return {
        ...state,
        error: null,
        totalPaymentsReference: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
