import { ISliderGalleryImage } from './SliderGalleryImage';
import { IUser } from './User';

export interface ISliderGallery {
  id: number;
  name: string;
  description: string | null;
  code: string;
  status: number;
  createdAt: Date;
  updatedAt: Date;
  slides: ISliderGalleryImage[];
  userId: number;
  user: IUser | undefined;
}

export interface SliderGalleryState {
  sliderGalleries: ISliderGallery[];
  error: string | null;
  loading: boolean;
  currentPage: number;
  limit: number;
  totalSliderGalleries: number;
}

export enum SliderGalleryActionTypes {
  FETCH_SLIDER_GALLERY = 'FETCH_SLIDER_GALLERY',
  FETCH_SLIDER_GALLERIES = 'FETCH_SLIDER_GALLERIES',
  FETCH_SLIDER_GALLERIES_ERROR = 'FETCH_SLIDER_GALLERIES_ERROR',
  SET_SLIDER_GALLERY_PAGE = 'SET_SLIDER_GALLERY_PAGE',
  SET_TOTAL_SLIDER_GALLERY_PAGE = 'SET_TOTAL_SLIDER_GALLERY_PAGE',
}

interface FetchSliderGalleryAction {
  type: SliderGalleryActionTypes.FETCH_SLIDER_GALLERY;
}

interface FetchSliderGalleriesAction {
  type: SliderGalleryActionTypes.FETCH_SLIDER_GALLERIES;
  payload: ISliderGallery[];
}

interface FetchSliderGalleriesErrorAction {
  type: SliderGalleryActionTypes.FETCH_SLIDER_GALLERIES_ERROR;
  payload: string;
}

interface SetSliderGalleryPageAction {
  type: SliderGalleryActionTypes.SET_SLIDER_GALLERY_PAGE;
  payload: number;
}

interface SetTotalSliderGalleryPageAction {
  type: SliderGalleryActionTypes.SET_TOTAL_SLIDER_GALLERY_PAGE;
  payload: number;
}

export type SliderGalleryAction =
  | FetchSliderGalleryAction
  | FetchSliderGalleriesAction
  | FetchSliderGalleriesErrorAction
  | SetSliderGalleryPageAction
  | SetTotalSliderGalleryPageAction;
