import React from 'react';
import { Link, Route } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import { ComponentsLayout } from '../../layout/ComponentsLayout/ComponentsLayout';
import { UpdateAttributeGroup } from '../../Pages/AttributeGroups/AttributeGroup/UpdateAttributeGroup/UpdateAttributeGroup';
import { AddAttributeGroup } from '../../Pages/AttributeGroups/AttributeGroup/AddAttributeGroup/AddAttributeGroup';
import { AttributeGroupsList } from '../../Pages/AttributeGroups/AttributeGroupsList/AttributeGroupsList';

export const AttributeGroupRoutes = (
  <>
    <Route
      path="/attributes/groups/add"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Добавить группу атрибутов</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <AddAttributeGroup />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/attributes/groups/update/:id"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/attributes/groups/list">Список групп атрибутов</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Обновление группы атрибутов</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <UpdateAttributeGroup />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/attributes/groups/list"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Список групп атрибутов</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <AttributeGroupsList />
          </ComponentsLayout>
        </>
      }
    />
  </>
);
