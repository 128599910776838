import { Dispatch } from 'redux';

import { ApiUrl } from '../../../types/ApiUrl';
import {
  AttributeAction,
  AttributeActionTypes,
} from '../../../types/entities/Attribute/Attribute';
import { $api } from '../../../http';

interface queryAttributeParamsInterface {
  limit?: any;
  page?: any;
  sortId?: string;
  title?: string;
  type?: string;
  group?: string;
  typeProduct?: string;
}

export const fetchAttributes = (queryParams: queryAttributeParamsInterface) => {
  const {
    limit = '25',
    page = 1,
    sortId = 'DESC',
    title,
    type,
    group,
    typeProduct,
  } = queryParams;

  const urlAttributeParams = new URLSearchParams();
  urlAttributeParams.append('limit', limit);
  urlAttributeParams.append('page', page);
  urlAttributeParams.append('sortId', sortId);

  if (title !== undefined) urlAttributeParams.append('title', title);
  if (type !== undefined) urlAttributeParams.append('type', type);
  if (group !== undefined) urlAttributeParams.append('group', group);
  if (typeProduct !== undefined)
    urlAttributeParams.append('typeProduct', typeProduct);

  return async (dispatch: Dispatch<AttributeAction>) => {
    try {
      dispatch({ type: AttributeActionTypes.FETCH_ATTRIBUTE });
      const response = await $api.get(
        `${ApiUrl.ATTRIBUTE}?${urlAttributeParams.toString()}`,
      );
      dispatch({
        type: AttributeActionTypes.FETCH_ATTRIBUTES,
        payload: response.data.items,
      });
      dispatch({
        type: AttributeActionTypes.SET_TOTAL_ATTRIBUTE_PAGE,
        payload: response.data.total,
      });
      dispatch({
        type: AttributeActionTypes.SET_ATTRIBUTE_PAGE,
        payload: page,
      });
    } catch (e) {
      dispatch({
        type: AttributeActionTypes.FETCH_ATTRIBUTES_ERROR,
        payload: 'Произошла ошибка при загрузке атрибутов',
      });
    }
  };
};
