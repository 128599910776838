import { IProduct } from './Product';

export interface IProducer {
  id: number;
  name: string | null;
  description: string | null;
  images: string[] | null;
  products?: IProduct[];
}

export interface ProducerState {
  producers: IProducer[];
  error: string | null;
  loading: boolean;
  currentPage: number;
  limit: number;
  totalProducers: number;
}

export enum ProducerActionTypes {
  FETCH_PRODUCER = 'FETCH_PRODUCER',
  FETCH_PRODUCERS = 'FETCH_PRODUCERS',
  FETCH_PRODUCERS_ERROR = 'FETCH_PRODUCERS_ERROR',
  SET_PRODUCER_PAGE = 'SET_PRODUCER_PAGE',
  SET_TOTAL_PRODUCER_PAGE = 'SET_TOTAL_PRODUCER_PAGE',
}

interface FetchProducerAction {
  type: ProducerActionTypes.FETCH_PRODUCER;
}

interface FetchProducersAction {
  type: ProducerActionTypes.FETCH_PRODUCERS;
  payload: IProducer[];
}

interface FetchProducerErrorAction {
  type: ProducerActionTypes.FETCH_PRODUCERS_ERROR;
  payload: string;
}

interface SetProducerPageAction {
  type: ProducerActionTypes.SET_PRODUCER_PAGE;
  payload: number;
}

interface SetTotalProducerPageAction {
  type: ProducerActionTypes.SET_TOTAL_PRODUCER_PAGE;
  payload: number;
}

export type ProducerAction =
  | FetchProducerAction
  | FetchProducersAction
  | FetchProducerErrorAction
  | SetProducerPageAction
  | SetTotalProducerPageAction;
