import React from 'react';
import { Link, Route } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import { Auth } from '../../Pages/Auth/Auth';
import { ComponentsLayout } from '../../layout/ComponentsLayout/ComponentsLayout';

export const LoginRoutes = (
  <>
    <Route
      path="/login"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Вход</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <Auth />
          </ComponentsLayout>
        </>
      }
    />
  </>
);

export const RegistrationRoutes = (
  <>
    <Route
      path="/registration"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Регистрация</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <Auth />
          </ComponentsLayout>
        </>
      }
    />
  </>
);
