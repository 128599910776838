import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Pagination, Row, Table } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import styles from './UsersList.module.scss';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useActions } from '../../../hooks/useActions';
import { statusUserOptions } from '../../../helpers/userHelper';
import { IRole } from '../../../types/entities/Role';
import { SelectOptionHelper } from '../../../helpers/selectHelper';
import FormInput from '../../../components/FormItems/FormInput';
import FormSelect from '../../../components/FormItems/FormSelect';

export const UsersList: React.FC = () => {
  const { usersList, limit, currentPage, totalUsers } = useTypedSelector(
    (state) => state.user,
  );

  const { roles } = useTypedSelector((state) => state.role);

  const [sort, setSort] = useState('DESC');

  const { fetchUsersList, fetchUsersForSelect, fetchRoles } = useActions();

  const roleOptions = SelectOptionHelper(roles);

  const [form] = Form.useForm();

  useEffect(() => {
    let roles;
    if (Array.isArray(form.getFieldValue('role'))) {
      roles = form.getFieldValue('role').join(',');
    }
    fetchUsersForSelect();
    fetchRoles();
    fetchUsersList({
      limit: limit,
      page: currentPage,
      sortId: sort,
      email: form.getFieldValue('email'),
      firstName: form.getFieldValue('firstName'),
      lastName: form.getFieldValue('lastName'),
      phone: form.getFieldValue('phoneUser'),
      status: form.getFieldValue('status'),
      roles: roles,
    });
  }, []);

  const onShowSizeChange = (currentPage: number, limit: number) => {
    let roles;
    if (Array.isArray(form.getFieldValue('role'))) {
      roles = form.getFieldValue('role').join(',');
    }
    fetchUsersList({
      limit: limit,
      page: currentPage,
      sortId: sort,
      email: form.getFieldValue('email'),
      firstName: form.getFieldValue('firstName'),
      lastName: form.getFieldValue('lastName'),
      phone: form.getFieldValue('phoneUser'),
      status: form.getFieldValue('status'),
      roles: roles,
    });
  };

  const handlePagination = (currentPage: number, limit: number) => {
    let roles;
    if (Array.isArray(form.getFieldValue('role'))) {
      roles = form.getFieldValue('role').join(',');
    }
    fetchUsersList({
      limit: limit,
      page: currentPage,
      sortId: sort,
      email: form.getFieldValue('email'),
      firstName: form.getFieldValue('firstName'),
      lastName: form.getFieldValue('lastName'),
      phone: form.getFieldValue('phoneUser'),
      status: form.getFieldValue('status'),
      roles: roles,
    });
  };

  const applyFilters = () => {
    let roles;
    if (Array.isArray(form.getFieldValue('role'))) {
      roles = form.getFieldValue('role').join(',');
    }
    fetchUsersList({
      limit: limit,
      page: 1,
      sortId: sort,
      email: form.getFieldValue('email'),
      firstName: form.getFieldValue('firstName'),
      lastName: form.getFieldValue('lastName'),
      phone: form.getFieldValue('phoneUser'),
      status: form.getFieldValue('status'),
      roles: roles,
    });
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.field === 'id') {
      if (sorter.order === 'ascend') {
        setSort('ASC');
        fetchUsersList({ limit: limit, page: currentPage, sortId: 'ASC' });
      } else {
        setSort('DESC');
        fetchUsersList({ limit: limit, page: currentPage, sortId: 'DESC' });
      }
    }
  };

  const columns = [
    {
      title: 'ID пользователя',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => {
        return (
          <div key={id}>
            <Link to={`/users/update/${id}`}>{id}</Link>
          </div>
        );
      },
      sorter: true,
    },
    {
      title: 'Имя',
      key: 'name',
      render: (data: any) => {
        return (
          `${
            data.lastName !== null && data.lastName !== 'null'
              ? data.lastName
              : ''
          }` +
          ' ' +
          `${
            data.firstName !== null && data.firstName !== 'null'
              ? data.firstName
              : ''
          }` +
          ' ' +
          `${
            data.middleName !== null && data.middleName !== 'null'
              ? data.middleName
              : ''
          }`
        );
      },
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      key: 'phone',
      render: (phone: string) => {
        if (phone) {
          return <>{phone}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Телефон подтверждён',
      dataIndex: 'phoneConfirm',
      key: 'phoneConfirm',
      render: (phoneConfirm: string) => {
        if (phoneConfirm) {
          return <>Да</>;
        } else {
          return <>Нет</>;
        }
      },
    },
    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
      render: (email: string) => {
        if (email) {
          return <>{email}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Роль',
      key: 'role',
      render: (data: any) => {
        if (data.roles.length > 0) {
          return data.roles.map((role: IRole, index: any) => {
            return (
              <span key={role.id}>
                {role.description}
                {index === data.roles.length - 1 ? '' : ', '}
              </span>
            );
          });
        } else {
          return '---';
        }
      },
    },
    {
      title: 'Статус',
      dataIndex: 'status',
      key: 'status',
      render: (status: any) => {
        switch (status) {
          case 1:
            return <>Активен</>;
          case 2:
            return <>Заблокирован</>;
          case 3:
            return <>Не активирован</>;
          default:
            return 'Не указано';
        }
      },
    },
    {
      title: 'Email подтверждён',
      dataIndex: 'emailConfirm',
      key: 'emailConfirm',
      render: (emailConfirm: string) => {
        if (emailConfirm) {
          return <>Да</>;
        } else {
          return <>Нет</>;
        }
      },
    },
    {
      title: 'Регистрация',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt: string) => {
        const createDate = new Date(createdAt).toLocaleString('ru', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        });
        if (createdAt) {
          return <>{createDate}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Визит',
      dataIndex: 'visitTime',
      key: 'visitTime',
      render: (visitTime: string) => {
        const createDate = new Date(visitTime).toLocaleString('ru', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: 'numeric',
          minute: 'numeric',
          second: 'numeric',
        });
        if (visitTime) {
          return <>{createDate}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Действия с пользователем',
      dataIndex: 'id',
      key: 'edit',
      render: (id: string) => {
        return (
          <>
            <Link to={`/users/update/${id}`}>
              <EditOutlined style={{ marginLeft: 50 }} />
            </Link>
          </>
        );
      },
    },
  ];

  return (
    <div className={styles.main}>
      <h1>Список пользователей</h1>
      <h4 style={{ textAlign: 'left' }}>Фильтры полей</h4>
      <Form form={form}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={8}>
            <FormInput
              name={'email'}
              label={'Email'}
              placeholder={'Введите email'}
              search={true}
              labelCol={{ span: 24 }}
              onSearch={(value) => {
                fetchUsersList({
                  limit: limit,
                  page: currentPage,
                  sortId: sort,
                  email: value,
                });
              }}
            />
          </Col>
          <Col span={8}>
            <FormInput
              name={'firstName'}
              label={'Имя'}
              placeholder={'Введите имя'}
              search={true}
              labelCol={{ span: 24 }}
              onSearch={(value) => {
                fetchUsersList({
                  limit: limit,
                  page: currentPage,
                  sortId: sort,
                  firstName: value,
                });
              }}
            />
          </Col>
          <Col span={8}>
            <FormInput
              name={'lastName'}
              label={'Фамилия'}
              placeholder={'Введите фамилию'}
              search={true}
              labelCol={{ span: 24 }}
              onSearch={(value) => {
                fetchUsersList({
                  limit: limit,
                  page: currentPage,
                  sortId: sort,
                  lastName: value,
                });
              }}
            />
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={8}>
            <FormInput
              name={'phoneUser'}
              label={'Телефон'}
              placeholder={'Введите номер телефона'}
              search={true}
              labelCol={{ span: 24 }}
              onSearch={(value) => {
                fetchUsersList({
                  limit: limit,
                  page: currentPage,
                  sortId: sort,
                  phone: value,
                });
              }}
            />
          </Col>
          <Col span={8}>
            <FormSelect
              name={'status'}
              label={'Статус'}
              placeholder={'Выберите статус'}
              showSearch={true}
              options={statusUserOptions}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={8}>
            <FormSelect
              name={'role'}
              label={'Роль'}
              placeholder={'Выберите роль'}
              showSearch={true}
              mode={'multiple'}
              options={roleOptions}
              labelCol={{ span: 24 }}
            />
          </Col>
        </Row>
        <Button
          style={{ marginBottom: 20 }}
          type="primary"
          htmlType="submit"
          onClick={applyFilters}
        >
          Применить фильтры
        </Button>
        <Button
          style={{ marginLeft: 20 }}
          type="ghost"
          htmlType="reset"
          onClick={() => {
            return fetchUsersList({
              limit: limit,
              page: 1,
              sortId: sort,
            });
          }}
        >
          Очистить фильтры
        </Button>
      </Form>
      <Pagination
        style={{ marginBottom: 20 }}
        total={totalUsers}
        current={currentPage}
        showQuickJumper={true}
        onChange={handlePagination}
        onShowSizeChange={onShowSizeChange}
        pageSizeOptions={[10, 25, 50, 100]}
        defaultPageSize={25}
      />
      <Table
        bordered
        columns={columns}
        dataSource={usersList}
        onChange={handleChange}
        pagination={false}
        rowKey="id"
      />
    </div>
  );
};
