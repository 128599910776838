export interface IPaymentReference {
  id: number;
  paymentSystem: string;
  paymentSystemId: number;
  entity: string;
  entityId: number;
  userId: number;
  managerId: number;
  phone: string;
  email: string;
  sum: number;
  status: number;
}

export interface PaymentReferenceState {
  paymentsReference: IPaymentReference[];
  error: string | null;
  loading: boolean;
  currentPage: number;
  limit: number;
  totalPaymentsReference: number;
}

export enum PaymentReferenceActionTypes {
  FETCH_PAYMENT_REFERENCE = 'FETCH_PAYMENT_REFERENCE',
  FETCH_PAYMENTS_REFERENCE = 'FETCH_PAYMENTS_REFERENCE',
  FETCH_PAYMENTS_REFERENCE_ERROR = 'FETCH_PAYMENTS_REFERENCE_ERROR',
  SET_PAYMENT_REFERENCE_PAGE = 'SET_PAYMENT_REFERENCE_PAGE',
  SET_TOTAL_PAYMENT_REFERENCE_PAGE = 'SET_TOTAL_PAYMENT_REFERENCE_PAGE',
}

interface FetchPaymentReferenceAction {
  type: PaymentReferenceActionTypes.FETCH_PAYMENT_REFERENCE;
}

interface FetchPaymentsReferenceAction {
  type: PaymentReferenceActionTypes.FETCH_PAYMENTS_REFERENCE;
  payload: IPaymentReference[];
}

interface FetchPaymentsReferenceErrorAction {
  type: PaymentReferenceActionTypes.FETCH_PAYMENTS_REFERENCE_ERROR;
  payload: string;
}

interface SetPaymentReferencePageAction {
  type: PaymentReferenceActionTypes.SET_PAYMENT_REFERENCE_PAGE;
  payload: number;
}

interface SetTotalPaymentReferencePageAction {
  type: PaymentReferenceActionTypes.SET_TOTAL_PAYMENT_REFERENCE_PAGE;
  payload: number;
}

export type PaymentReferenceAction =
  | FetchPaymentReferenceAction
  | FetchPaymentsReferenceAction
  | FetchPaymentsReferenceErrorAction
  | SetPaymentReferencePageAction
  | SetTotalPaymentReferencePageAction;
