import {
  MenuAction,
  MenuActionTypes,
  MenuState,
} from '../../../types/entities/Menu/Menu';

const initialState: MenuState = {
  menus: [],
  error: null,
  loading: false,
  currentPage: 1,
  limit: 25,
  totalMenus: 0,
};

export const menuReducer = (
  state = initialState,
  action: MenuAction,
): MenuState => {
  switch (action.type) {
    case MenuActionTypes.FETCH_MENU:
      return { ...state, loading: true };
    case MenuActionTypes.FETCH_MENUS_ERROR:
      return { ...state, error: action.payload, loading: false };
    case MenuActionTypes.FETCH_MENUS:
      return { ...state, error: null, menus: action.payload, loading: false };
    case MenuActionTypes.SET_MENU_PAGE:
      return {
        ...state,
        error: null,
        currentPage: action.payload,
        loading: false,
      };
    case MenuActionTypes.SET_TOTAL_MENU_PAGE:
      return {
        ...state,
        error: null,
        totalMenus: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
