import {
  DeliveryAction,
  DeliveryActionTypes,
  DeliveryState,
} from '../../types/entities/Delivery';

const initialState: DeliveryState = {
  deliveries: [],
  deliveriesList: [],
  error: null,
  loading: false,
  currentPage: 1,
  limit: 25,
  totalDeliveries: 0,
};

export const deliveryReducer = (
  state = initialState,
  action: DeliveryAction,
): DeliveryState => {
  switch (action.type) {
    case DeliveryActionTypes.FETCH_DELIVERY:
      return { ...state, loading: true };
    case DeliveryActionTypes.FETCH_DELIVERIES_ERROR:
      return { ...state, error: action.payload, loading: false };
    case DeliveryActionTypes.FETCH_DELIVERIES:
      return {
        ...state,
        error: null,
        deliveriesList: action.payload,
        loading: false,
      };
    case DeliveryActionTypes.SET_DELIVERY_PAGE:
      return {
        ...state,
        error: null,
        currentPage: action.payload,
        loading: false,
      };
    case DeliveryActionTypes.SET_TOTAL_DELIVERY_PAGE:
      return {
        ...state,
        error: null,
        totalDeliveries: action.payload,
        loading: false,
      };
    case DeliveryActionTypes.FETCH_DELIVERIES_SELECT:
      return {
        ...state,
        error: null,
        deliveries: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
