import { Dispatch } from 'redux';

import { $api } from '../../../http';
import { ApiUrl } from '../../../types/ApiUrl';
import {
  GeoZoneAction,
  GeoZoneActionTypes,
} from '../../../types/entities/Geo/GeoZone';

interface queryGeoZoneParamsInterface {
  limit?: any;
  page?: any;
  sortId?: string;
  id?: string;
  name?: string;
  description?: string;
}

export const fetchGeoZonesForSelect = () => {
  return async (dispatch: Dispatch<GeoZoneAction>) => {
    try {
      dispatch({ type: GeoZoneActionTypes.FETCH_GEO_ZONE });
      const response = await $api.get(`${ApiUrl.GEO_ZONE_SELECT}`);
      dispatch({
        type: GeoZoneActionTypes.FETCH_GEO_ZONES_SELECT,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: GeoZoneActionTypes.FETCH_GEO_ZONES_ERROR,
        payload: 'Произошла ошибка при загрузке геозон',
      });
    }
  };
};

export const fetchGeoZonesList = (queryParams: queryGeoZoneParamsInterface) => {
  const {
    limit = '10',
    page = 1,
    sortId = 'DESC',
    id,
    name,
    description,
  } = queryParams;

  const urlGeoZoneParams = new URLSearchParams();
  urlGeoZoneParams.append('limit', limit);
  urlGeoZoneParams.append('page', page);
  urlGeoZoneParams.append('sortId', sortId);

  if (id !== undefined) urlGeoZoneParams.append('id', id);
  if (name !== undefined) urlGeoZoneParams.append('name', name);
  if (description !== undefined)
    urlGeoZoneParams.append('description', description);

  return async (dispatch: Dispatch<GeoZoneAction>) => {
    try {
      dispatch({ type: GeoZoneActionTypes.FETCH_GEO_ZONE });
      const response = await $api.get(
        `${ApiUrl.GEO_ZONE}?${urlGeoZoneParams.toString()}`,
      );
      dispatch({
        type: GeoZoneActionTypes.FETCH_GEO_ZONES,
        payload: response.data.items,
      });
      dispatch({
        type: GeoZoneActionTypes.SET_TOTAL_GEO_ZONE_PAGE,
        payload: response.data.total,
      });
      dispatch({ type: GeoZoneActionTypes.SET_GEO_ZONE_PAGE, payload: page });
    } catch (e) {
      dispatch({
        type: GeoZoneActionTypes.FETCH_GEO_ZONES_ERROR,
        payload: 'Произошла ошибка при загрузке геозон',
      });
    }
  };
};
