import React from 'react';

import { AttributeGroup } from '../AttributeGroup';
import { UPDATE_SUBMIT_METHOD } from '../../../../types/constants';

export const UpdateAttributeGroup = () => {
  const titlePage = 'Обновление группы атрибутов';
  const submitMethod = UPDATE_SUBMIT_METHOD;

  const props = {
    titlePage: titlePage,
    submitMethod: submitMethod,
  };

  return (
    <>
      <AttributeGroup {...props} />
    </>
  );
};
