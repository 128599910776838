import { useState } from 'react';

export const useCheckbox = (initialValue: any) => {
  const [value, setValue] = useState(initialValue);

  const onChange = (e: any) => {
    if (e.target.checked === true) {
      setValue(1);
    } else {
      setValue(0);
    }
  };

  return { value, setValue, onChange };
};
