import React from 'react';

import { PaymentOnline } from '../PaymentOnline';
import { UPDATE_SUBMIT_METHOD } from '../../../../types/constants';

export const UpdatePaymentOnline: React.FC = () => {
  const titlePage = 'Обновление онлайн платежа';
  const submitMethod = UPDATE_SUBMIT_METHOD;

  const props = {
    titlePage: titlePage,
    submitMethod: submitMethod,
  };

  return (
    <>
      <PaymentOnline {...props} />
    </>
  );
};
