import {
  RoleAction,
  RoleActionTypes,
  RoleState,
} from '../../types/entities/Role';

const initialState: RoleState = {
  roles: [],
  error: null,
  loading: false,
};

export const roleReducer = (
  state = initialState,
  action: RoleAction,
): RoleState => {
  switch (action.type) {
    case RoleActionTypes.FETCH_ROLE:
      return { ...state, loading: true };
    case RoleActionTypes.FETCH_ROLES_ERROR:
      return { ...state, error: action.payload, loading: false };
    case RoleActionTypes.FETCH_ROLES:
      return { ...state, error: null, roles: action.payload, loading: false };
    default:
      return state;
  }
};
