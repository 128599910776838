import { FC } from 'react';
import { useTypedSelector } from '../../hooks/useTypedSelector';

interface RolesInterceptorProps {
  roles?: string[]; // список разрешенных ролей
  children?: any;
}

export const RolesInterceptor: FC<RolesInterceptorProps> = ({
  roles,
  children,
}) => {
  const { user, isAdmin } = useTypedSelector((state) => state.auth);
  if (isAdmin) {
    return <>{children}</>;
  }
  const userRoles = user?.roles;
  if (!userRoles) return <></>;
  if (!roles) return <>{children}</>;
  for (const role of userRoles) {
    if (roles.includes(role.value)) return <>{children}</>;
  }
  return <></>;
};

