import React, { useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { Spin } from 'antd';

import { ShopRoutes } from './Shop';
import { ProductRoutes } from './Product';
import { DashboardRoutes } from './Dashboard';
import { CategoryRoutes } from './Category';
import { ProducerRoutes } from './Producer';
import { OrderRoutes } from './Order';
import { AttributeRoutes } from './Attribute';
import { UserRoutes } from './User';
import { LoginRoutes, RegistrationRoutes } from './Auth';
import { useTypedSelector } from '../hooks/useTypedSelector';
import { AttributeGroupRoutes } from './AttributeGroup';
import { DeliveryRoutes } from './Delivery';
import { PaymentRoutes } from './Payment';
import { PaymentOnlineRoutes } from './PaymentOnline';
import { SliderRoutes } from './Slider';
import { MenuRoutes } from './Menu';
import { MenuItemsRoutes } from './MenuItem';
import { ProductSliderGroupRoutes } from './ProductSliderGroup';
import { GeoZoneRoutes } from './GeoZone';
import { GeoRegionRoutes } from './GeoRegion';
import { GeoCityRoutes } from './GeoCity';
import { OrderHistoryRoutes } from './OrderHistory';
import { NewsRoutes } from './News';
import { PageRoutes } from './Page';
import { PaymentReferenceRoutes } from './PaymentReference';
import { ImageRoutes } from './Image';
import { useActions } from '../hooks/useActions';
import { SEOFilesRoutes } from './SEOFiles';
import { RolesRoutes } from './Roles';
import { ConstantsRoutes } from './Constants';
import { ServicesRoutes } from './Services';
import { CommentsRoutes } from './Comments';
import { ArticlesRoutes } from './Articles';
import { TypesRoutes } from './Types';
import { KkmRoutes } from './Kkm';
import { AdditionalServices } from './AdditionalServices'

export const useRoutes = () => {
  const { checkAuthLocalStorage } = useActions();
  const auth = useTypedSelector((state) => state.auth);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkAuthLocalStorage();
    setLoading(false);
  }, []);

  if (loading)
    return (
      <div className="spinAntD">
        <Spin />
      </div>
    );

  if (!auth.isAuth) {
    return (
      <Routes>
        {/** /login; **/}
        {LoginRoutes}

        <Route path="*" element={<Navigate replace to="/login" />} />
      </Routes>
    );
  } else {
    return (
      <Routes>
        {/** /registration; /logout; /refresh **/}
        {RegistrationRoutes}

        {/** /dashboard **/}
        {DashboardRoutes}

        {ConstantsRoutes}

        {/** /product **/}
        {ProductRoutes}

        {/** /shop **/}
        {ShopRoutes}

        {/** /category **/}
        {CategoryRoutes}

        {/** /producer **/}
        {ProducerRoutes}

        {/** /orders **/}
        {OrderRoutes}

        {/** /orderHistories **/}
        {OrderHistoryRoutes}

        {/** /users **/}
        {UserRoutes}

        {/** /attributes **/}
        {AttributeRoutes}

        {/** /attributeGroups **/}
        {AttributeGroupRoutes}

        {/** /deliveries **/}
        {DeliveryRoutes}

        {/** /payments **/}
        {PaymentRoutes}

        {/** /paymentsOnline **/}
        {PaymentOnlineRoutes}

        {/** /paymentsReference **/}
        {PaymentReferenceRoutes}

        {/** /sliders **/}
        {SliderRoutes}

        {/** /productSliderGroups **/}
        {ProductSliderGroupRoutes}

        {/** /geoZones **/}
        {GeoZoneRoutes}

        {/** /geoRegions **/}
        {GeoRegionRoutes}

        {/** /geoCities **/}
        {GeoCityRoutes}

        {/** /menus **/}
        {MenuRoutes}

        {/** /menuItems **/}
        {MenuItemsRoutes}

        {/** /news **/}
        {NewsRoutes}

        {/** /pages **/}
        {PageRoutes}

        {/** /images **/}
        {ImageRoutes}

        {/** /seo-files **/}
        {SEOFilesRoutes}

        {/** /roles **/}
        {RolesRoutes}

        {ServicesRoutes}

        {CommentsRoutes}
        
        {ArticlesRoutes}

        {KkmRoutes}

        {AdditionalServices}

        {TypesRoutes}

        <Route path="*" element={<Navigate replace to="/dashboard" />} />
      </Routes>
    );
  }
};
