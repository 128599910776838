export interface IMenu {
  id: number;
  name: string;
  description: string;
  code: string;
  status: number;
}

export interface MenuState {
  menus: IMenu[];
  error: string | null;
  loading: boolean;
  currentPage: number;
  limit: number;
  totalMenus: number;
}

export enum MenuActionTypes {
  FETCH_MENU = 'FETCH_MENU',
  FETCH_MENUS = 'FETCH_MENUS',
  FETCH_MENUS_ERROR = 'FETCH_MENUS_ERROR',
  SET_MENU_PAGE = 'SET_MENU_PAGE',
  SET_TOTAL_MENU_PAGE = 'SET_TOTAL_MENU_PAGE',
}

interface FetchMenuAction {
  type: MenuActionTypes.FETCH_MENU;
}

interface FetchMenusAction {
  type: MenuActionTypes.FETCH_MENUS;
  payload: IMenu[];
}

interface FetchMenusErrorAction {
  type: MenuActionTypes.FETCH_MENUS_ERROR;
  payload: string;
}

interface SetMenuPageAction {
  type: MenuActionTypes.SET_MENU_PAGE;
  payload: number;
}

interface SetTotalMenuPageAction {
  type: MenuActionTypes.SET_TOTAL_MENU_PAGE;
  payload: number;
}

export type MenuAction =
  | FetchMenuAction
  | FetchMenusAction
  | FetchMenusErrorAction
  | SetMenuPageAction
  | SetTotalMenuPageAction;
