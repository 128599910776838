import React, { useMemo, useRef } from 'react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface OnChangeHandler {
  (e: any): void;
}

type Props = {
  text?: string;
  placeholder?: string;
  onChange?: any;
};

const TextEditor: React.FC<Props> = ({ text, onChange, placeholder }) => {
  const quillRef = useRef(null);

  const imageHandler = () => {
    const editor = (quillRef.current as any).editor;
    const range = editor.getSelection();
    const link = prompt('Вставьте ссылку на изображение');
    if (link) {
      editor.insertEmbed(range.index, 'image', link);
      editor.setSelection(range.index + 1);
    }
  };

  const modules = useMemo(() => {
    return {
      toolbar: {
        container: [
          [{ header: [1, 2, false] }],
          ['bold', 'italic', 'underline', 'strike', 'blockquote'],
          [
            { list: 'ordered' },
            { list: 'bullet' },
            { indent: '-1' },
            { indent: '+1' },
          ],
          ['image', 'link', 'code'],
          ['clean'],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    };
  }, []);

  const formats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'image',
    'link',
    'code',
  ];

  return (
    <ReactQuill
      ref={quillRef}
      theme="snow"
      value={text || ''}
      modules={modules}
      formats={formats}
      onChange={onChange}
      placeholder={placeholder}
    />
  );
};

export default TextEditor;
