import {
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Modal,
  Row,
  Select,
  Table,
} from 'antd';
import { useEffect, useState } from 'react';
import FormCheckbox from '../../components/FormItems/FormCheckbox';
import FormInput from '../../components/FormItems/FormInput';
import FormSelect from '../../components/FormItems/FormSelect';
import { RolesInterceptor } from '../../components/Interceptors/RolesInterceptor';
import { statusProducerOptions } from '../../helpers/producerHelper';
import { useHttp } from '../../hooks/useHttp';
import { fetchProducers } from '../../store/action-creators/producer';
import { ROLES } from '../../types/constants';

export const AccessMap: React.FC = () => {
  const [endpoints, setEndpoints] = useState<any[]>([]);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [total, setTotal] = useState<number>(0);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [urlFilter, setUrlFilter] = useState<string>('');
  const [sort, setSort] = useState({ key: 'id', order: 'ASC' });
  const { request } = useHttp();
  const pageSize = 15;

  const getData = async (
    page = currentPage,
    sortProp = sort,
    urlFilterValue = urlFilter,
  ) => {
    console.log('getData', page, sortProp, urlFilterValue);
    const response = await request({
      url: `/api/roles-guard`,
      method: 'get',
      params: {
        limit: pageSize,
        page: page,
        sortKey: sortProp.key,
        order: sortProp.order,
        url: urlFilterValue,
      },
    });
    setEndpoints(response.data?.data);
    setTotal(response.data?.total);
  };

  const onFinish = async () => {
    const response = await request({
      url: `/api/roles-guard`,
      method: 'put',
      body: endpoints,
    });
  };

  const addEndPoin = async (e: any) => {
    roles.forEach((role) => {
      if (e[role.name] === undefined) e[role.name] = false;
    });
    const response = await request({
      url: `/api/roles-guard`,
      method: 'post',
      body: e,
    });
    setEndpoints([response.data].concat(endpoints));
    setShowModal(false);
  };

  useEffect(() => {
    getData();
  }, []);
  const [form] = Form.useForm();
  const [filtersForm] = Form.useForm();

  const options = [
    { value: 'GET', label: 'GET' },
    { value: 'POST', label: 'POST' },
    { value: 'PUT', label: 'PUT' },
    { value: 'DELETE', label: 'DELETE' },
    { value: 'PATCH', label: 'PATCH' },
  ];

  const inputs = [
    { name: 'method', label: 'Метод' },
    { name: 'url', label: 'url' },
    { name: 'slug', label: 'Параметры' },
    { name: 'description', label: 'Описание' },
  ];
  const roles = [
    { name: 'admin', label: 'Админ' },
    { name: 'content', label: 'Контент' },
    { name: 'seo', label: 'СЕО' },
    { name: 'operator', label: 'Оператор' },
    { name: 'isOpen', label: 'Открытый' },
  ];

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    console.log(sorter);
    if (sorter.order === 'ascend') {
      setSort({ key: sorter.key, order: 'ASC' });
      getData(undefined, { key: sorter.columnKey, order: 'ASC' });
    } else {
      setSort({ key: sorter.key, order: 'DESC' });
      getData(undefined, { key: sorter.columnKey, order: 'DESC' });
    }
  };

  const applyFilters = (e: any) => {
    console.log(e);
    setUrlFilter(e.urlFilter);
    getData(undefined, undefined, e.urlFilter);
  };

  const columns = inputs
    .map((input) => ({
      title: input.label,
      key: input.name,
      width: '10%',
      sorter: true,
      render: (data: any) => {
        if (data) {
          return <div key={input.name}>{data[input.name]}</div>;
        } else {
          return 'Не указано';
        }
      },
    }))
    .concat(
      roles.map((role) => ({
        title: role.label,
        key: role.name,
        width: '5%',
        sorter: true,
        render: (data: any) => {
          if (data) {
            return (
              <div
                key={role.name}
                style={{ display: 'flex', justifyContent: 'center' }}
              >
                <Checkbox
                  onChange={() => {
                    setEndpoints(
                      endpoints.map((endpoint) => {
                        if (endpoint.id === data.id) {
                          endpoint[role.name] = !data[role.name];
                        }
                        return endpoint;
                      }),
                    );
                  }}
                  disabled={data.isOpen && role.name !== 'isOpen'}
                  checked={data[role.name]}
                />
              </div>
            );
          } else {
            return 'Не указано';
          }
        },
      })),
    );

  return (
    <RolesInterceptor roles={[ROLES.admin]}>
      <div>
        <h4 style={{ textAlign: 'left' }}>Фильтры полей</h4>
        <Form form={filtersForm} onFinish={applyFilters}>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={12}>
              <FormInput
                name={'urlFilter'}
                label={'url'}
                placeholder={'url'}
                labelCol={{ span: 24 }}
                search={true}
              />
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={24} style={{ marginBottom: 20 }}>
              <Button type="primary" htmlType="submit">
                Применить фильтры
              </Button>
              <Button style={{ marginLeft: 20 }} type="ghost" htmlType="reset">
                Очистить фильтры
              </Button>
            </Col>
          </Row>
        </Form>
        <Form form={form} onFinish={onFinish}>
          <Table
            onChange={handleChange}
            columns={columns}
            dataSource={endpoints}
            pagination={{
              total: total,
              pageSize: pageSize,
              showSizeChanger: false,
              onChange: (e) => {
                setCurrentPage(e);
                getData(e);
              },
            }}
            rowKey="id"
          />
          <Form.Item>
            <Button htmlType="submit" type="primary">
              Обновить
            </Button>
          </Form.Item>
          <Form.Item>
            <Button onClick={(e) => setShowModal(true)} type="primary">
              Добавить
            </Button>
          </Form.Item>
        </Form>
      </div>
      <Modal
        open={showModal}
        title={'Добавить ендпоинт'}
        onCancel={() => setShowModal(false)}
        footer={null}
      >
        <Form onFinish={addEndPoin}>
          <FormSelect
            name={'method'}
            label={'Метод'}
            placeholder={'Выберите метод'}
            options={options}
            labelCol={{ span: 5 }}
          />
          {inputs.map((input) =>
            input.name !== 'method' ? (
              <FormInput
                key={input.name}
                name={input.name}
                label={input.label}
                placeholder={input.label}
                labelCol={{ span: 5 }}
              />
            ) : (
              <></>
            ),
          )}
          <div style={{ display: 'flex', gap: '17px' }}>
            {roles.map((role) => (
              <FormCheckbox
                key={role.name}
                text={role.label}
                name={role.name}
                checked={false}
              ></FormCheckbox>
            ))}
          </div>
          <Form.Item>
            <Button htmlType="submit" type="primary">
              Добавить
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </RolesInterceptor>
  );
};
