import React from 'react';
import { Link, Route } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import { ComponentsLayout } from '../../layout/ComponentsLayout/ComponentsLayout';
import { NewsList } from '../../Pages/News/NewsList/NewsList';
import { AddNews } from '../../Pages/News/News/AddNews/AddNews';
import { UpdateNews } from '../../Pages/News/News/UpdateNews/UpdateNews';
import { ComponentsLayoutBlock } from '../../layout/ComponentLayoutBlock/ComponentLayoutBlock';

export const NewsRoutes = (
  <>
    <Route
      path="/content/news/add"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Добавить новость</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayoutBlock>
            <AddNews />
          </ComponentsLayoutBlock>
        </>
      }
    />
    <Route
      path="/content/news/update/:id"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/categories/list">Список новостей</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Обновление новости</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayoutBlock>
            <UpdateNews />
          </ComponentsLayoutBlock>
        </>
      }
    />
    <Route
      path="/content/news/list"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Список новостей</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <NewsList />
          </ComponentsLayout>
        </>
      }
    />
  </>
);
