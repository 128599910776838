import { Dispatch } from 'redux';

import {
  AttributeGroupAction,
  AttributeGroupActionTypes,
} from '../../../types/entities/Attribute/AttributeGroup';
import { ApiUrl } from '../../../types/ApiUrl';
import { $api } from '../../../http';

interface queryAttributeGroupParamsInterface {
  limit?: any;
  page?: any;
  sortId?: string;
  id?: string;
  name?: string;
  position?: string;
}

export const fetchAttributeGroupsForSelect = () => {
  return async (dispatch: Dispatch<AttributeGroupAction>) => {
    try {
      dispatch({ type: AttributeGroupActionTypes.FETCH_ATTRIBUTE_GROUP });
      const response = await $api.get(
        `${ApiUrl.GET_ALL_ATTRIBUTE_GROUPS_FOR_SELECT}`,
      );
      dispatch({
        type: AttributeGroupActionTypes.FETCH_ATTRIBUTE_GROUPS_SELECT,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: AttributeGroupActionTypes.FETCH_ATTRIBUTE_GROUPS_ERROR,
        payload: 'Произошла ошибка при загрузке категорий',
      });
    }
  };
};

export const fetchAttributeGroupsList = (
  queryParams: queryAttributeGroupParamsInterface,
) => {
  const {
    limit = '25',
    page = 1,
    sortId = 'DESC',
    id,
    name,
    position,
  } = queryParams;

  const urlAttributeGroupParams = new URLSearchParams();
  urlAttributeGroupParams.append('limit', limit);
  urlAttributeGroupParams.append('page', page);
  urlAttributeGroupParams.append('sortId', sortId);

  if (id !== undefined) urlAttributeGroupParams.append('id', id);
  if (name !== undefined) urlAttributeGroupParams.append('name', name);
  if (position !== undefined)
    urlAttributeGroupParams.append('position', position);

  return async (dispatch: Dispatch<AttributeGroupAction>) => {
    try {
      dispatch({ type: AttributeGroupActionTypes.FETCH_ATTRIBUTE_GROUP });
      const response = await $api.get(
        `${
          ApiUrl.GET_ALL_ATTRIBUTE_GROUPS
        }?${urlAttributeGroupParams.toString()}`,
      );
      dispatch({
        type: AttributeGroupActionTypes.FETCH_ATTRIBUTE_GROUPS,
        payload: response.data.items,
      });
      dispatch({
        type: AttributeGroupActionTypes.SET_TOTAL_ATTRIBUTE_GROUP_PAGE,
        payload: response.data.total,
      });
      dispatch({
        type: AttributeGroupActionTypes.SET_ATTRIBUTE_GROUP_PAGE,
        payload: page,
      });
    } catch (e) {
      dispatch({
        type: AttributeGroupActionTypes.FETCH_ATTRIBUTE_GROUPS_ERROR,
        payload: 'Произошла ошибка при загрузке групп атрибутов',
      });
    }
  };
};
