import React from 'react';
import { Link, Route } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import { ComponentsLayout } from '../../layout/ComponentsLayout/ComponentsLayout';
import { MenusList } from '../../Pages/Menu/MenusList/MenusList';
import { AddMenu } from '../../Pages/Menu/Menu/AddMenu/AddMenu';
import { UpdateMenu } from '../../Pages/Menu/Menu/UpdateMenu/UpdateMenu';

export const MenuRoutes = (
  <>
    <Route
      path="/menu/menu/add"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Добавить меню</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <AddMenu />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/menu/menu/update/:id"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/menu/menu/list">Список меню</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Обновление меню</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <UpdateMenu />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/menu/menu/list"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Список меню</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <MenusList />
          </ComponentsLayout>
        </>
      }
    />
  </>
);
