import React, { useEffect, useState } from "react";
import { Button, Col, Form, Pagination, Row, Table } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import styles from "./ShopsList.module.scss";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useActions } from "../../../hooks/useActions";
import { StatusEditor } from "../../../components/Editors/StatusEditor";
import { ApiUrl } from "../../../types/ApiUrl";
import { statusShopOptions, typeShopOptions } from "../../../helpers/shopHelper";
import { SelectOptionHelper } from "../../../helpers/selectHelper";
import FormInput from "../../../components/FormItems/FormInput";
import FormSelect from "../../../components/FormItems/FormSelect";

export const ShopsList: React.FC = () => {
  const { shops, limit, currentPage, totalShops } = useTypedSelector(
    (state) => state.shop
  );

  const { geoCities } = useTypedSelector((state) => state.geoCity);

  const [sort, setSort] = useState("DESC");
  const [edit, setEdit] = useState(false);

  const [form] = Form.useForm();

  const { fetchShops, fetchGeoCitiesForSelect } = useActions();

  const citiesOptions = SelectOptionHelper(geoCities);

  useEffect(() => {
    fetchGeoCitiesForSelect();
    fetchShops({
      limit: limit,
      page: currentPage,
      sortId: sort,
      title: form.getFieldValue("title"),
      status: form.getFieldValue("status"),
      cityId: form.getFieldValue("cityId"),
      code: form.getFieldValue("code"),
      id: form.getFieldValue("id"),
      type: form.getFieldValue("type")
    });
  }, [edit]);

  const onShowSizeChange = (currentPage: number, limit: number) => {
    fetchShops({
      limit: limit,
      page: currentPage,
      sortId: sort,
      title: form.getFieldValue("title"),
      status: form.getFieldValue("status"),
      cityId: form.getFieldValue("cityId"),
      code: form.getFieldValue("code"),
      id: form.getFieldValue("id"),
      type: form.getFieldValue("type")
    });
  };

  const handlePagination = (currentPage: number, limit: number) => {
    fetchShops({
      limit: limit,
      page: currentPage,
      sortId: sort,
      title: form.getFieldValue("title"),
      status: form.getFieldValue("status"),
      cityId: form.getFieldValue("cityId"),
      code: form.getFieldValue("code"),
      id: form.getFieldValue("id"),
      type: form.getFieldValue("type")
    });
  };

  const applyFilters = () => {
    fetchShops({
      limit: limit,
      page: 1,
      sortId: sort,
      title: form.getFieldValue("title"),
      status: form.getFieldValue("status"),
      cityId: form.getFieldValue("cityId"),
      code: form.getFieldValue("code"),
      id: form.getFieldValue("id"),
      type: form.getFieldValue("type")
    });
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.field === "id") {
      if (sorter.order === "ascend") {
        setSort("ASC");
        fetchShops({ limit: limit, page: currentPage, sortId: "ASC" });
      } else {
        setSort("DESC");
        fetchShops({ limit: limit, page: currentPage, sortId: "DESC" });
      }
    }
  };

  const columns = [
    {
      title: "ID магазина",
      dataIndex: "id",
      key: "id",
      render: (id: string) => {
        return (
          <div key={id}>
            <Link to={`/shops/update/${id}`}>{id}</Link>
          </div>
        );
      },
      sorter: true
    },
    {
      title: "Code",
      dataIndex: "code",
      key: "code",
      render: (code: string) => {
        if (code) {
          return <>{code}</>;
        } else {
          return "Не указано";
        }
      }
    },
    {
      title: "Название",
      dataIndex: "title",
      key: "title",
      render: (title: string) => {
        if (title) {
          return <>{title}</>;
        } else {
          return "Не указано";
        }
      }
    },
    {
      title: "Город",
      key: "cityId",
      render: (data: any) => {
        if (data.city) {
          return <>{data.city.name}</>;
        } else {
          return "---";
        }
      }
    },
    {
      title: "Статус",
      key: "status",
      render: (data: any) => {
        return (
          <StatusEditor
            status={data.status}
            id={data.id}
            options={statusShopOptions}
            setEdit={setEdit}
            url={ApiUrl.UPDATE_SHOP_STATUS}
          />
        );
      }
    },
    {
      title: "Действия с товаром",
      dataIndex: "id",
      key: "edit",
      render: (id: string) => {
        return (
          <>
            <Link to={`/shops/update/${id}`}>
              <EditOutlined style={{ marginLeft: 50 }} />
            </Link>
          </>
        );
      }
    }
  ];

  return (
    <div className={styles.main}>
      <h1>Список магазинов</h1>
      <h4 style={{ textAlign: "left" }}>Фильтры полей</h4>
      <Form form={form}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <FormInput
              name={"title"}
              label={"Название"}
              placeholder={"Выберите название магазина"}
              search={true}
              labelCol={{ span: 24 }}
              onSearch={(value) => {
                fetchShops({
                  limit: limit,
                  page: currentPage,
                  sortId: sort,
                  title: value
                });
              }}
            />
          </Col>
          <Col span={12}>
            <FormInput
              name={"code"}
              label={"Код магазина/склада"}
              placeholder={"Введите код магазина/склада"}
              search={true}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={12}>
            <FormInput
              name={"id"}
              label={"ID магазина/склада"}
              placeholder={"Введите id магазина/склада"}
              search={true}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={12}>
            <FormSelect
              name={"type"}
              label={"Тип пункта"}
              placeholder={"Магазин/Склад"}
              showSearch={true}
              options={typeShopOptions}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={12}>
            <FormSelect
              name={"status"}
              label={"Статус"}
              placeholder={"Выберите статус"}
              options={statusShopOptions}
              showSearch={true}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={12}>
            <FormSelect
              name={"cityId"}
              label={"Город"}
              placeholder={"Выберите город"}
              options={citiesOptions}
              showSearch={true}
              labelCol={{ span: 24 }}
            />
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <Button
              type="primary"
              htmlType="submit"
              onClick={applyFilters}
              style={{ margin: 10 }}
            >
              Применить фильтры
            </Button>

            <Button
              style={{ margin: 10 }}
              type="ghost"
              htmlType="reset"
              onClick={() => {
                return fetchShops({
                  limit: limit,
                  page: 1,
                  sortId: sort
                });
              }}
            >
              Очистить фильтры
            </Button>
          </Col>
        </Row>
      </Form>
      <Pagination
        style={{ marginBottom: 20 }}
        total={totalShops}
        current={currentPage}
        showQuickJumper={true}
        onChange={handlePagination}
        onShowSizeChange={onShowSizeChange}
        pageSizeOptions={[10, 25, 50, 100]}
        defaultPageSize={25}
      />
      <Table
        bordered
        columns={columns}
        dataSource={shops}
        pagination={false}
        onChange={handleChange}
        rowKey="id"
      />
    </div>
  );
};
