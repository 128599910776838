import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Pagination, Row, Table } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useActions } from '../../../hooks/useActions';
import styles from './PaymentsOnlineList.module.scss';
import { StatusEditor } from '../../../components/Editors/StatusEditor';
import { ApiUrl } from '../../../types/ApiUrl';
import { statusPaymentOnlineOptions } from '../../../helpers/paymentOnlineHelper';
import { SelectOptionHelper } from '../../../helpers/selectHelper';
import FormInput from '../../../components/FormItems/FormInput';
import FormSelect from '../../../components/FormItems/FormSelect';

export const PaymentsOnlineList: React.FC = () => {
  const { payments } = useTypedSelector((state) => state.payment);
  const { paymentsOnline, limit, currentPage, totalPaymentsOnline } =
    useTypedSelector((state) => state.paymentOnline);

  const [sort, setSort] = useState('DESC');
  const [edit, setEdit] = useState(false);

  const { fetchPaymentsOnline, fetchPaymentsForSelect } = useActions();

  const paymentsOptions = SelectOptionHelper(payments);

  const [form] = Form.useForm();

  useEffect(() => {
    fetchPaymentsForSelect('all');
    fetchPaymentsOnline({
      limit: limit,
      page: currentPage,
      sortId: sort,
      id: form.getFieldValue('id'),
      paymentSystemId: form.getFieldValue('paymentSystemId'),
      entityId: form.getFieldValue('entityId'),
      status: form.getFieldValue('status'),
      sum: form.getFieldValue('sum'),
    });
  }, [edit]);

  const onShowSizeChange = (currentPage: number, limit: number) => {
    fetchPaymentsOnline({
      limit: limit,
      page: currentPage,
      sortId: sort,
      id: form.getFieldValue('id'),
      paymentSystemId: form.getFieldValue('paymentSystemId'),
      entityId: form.getFieldValue('entityId'),
      status: form.getFieldValue('status'),
      sum: form.getFieldValue('sum'),
    });
  };

  const handlePagination = (currentPage: number, limit: number) => {
    fetchPaymentsOnline({
      limit: limit,
      page: currentPage,
      sortId: sort,
      id: form.getFieldValue('id'),
      paymentSystemId: form.getFieldValue('paymentSystemId'),
      entityId: form.getFieldValue('entityId'),
      status: form.getFieldValue('status'),
      sum: form.getFieldValue('sum'),
    });
  };

  const applyFilters = () => {
    fetchPaymentsOnline({
      limit: limit,
      page: 1,
      sortId: sort,
      id: form.getFieldValue('id'),
      paymentSystemId: form.getFieldValue('paymentSystemId'),
      entityId: form.getFieldValue('entityId'),
      status: form.getFieldValue('status'),
      sum: form.getFieldValue('sum'),
    });
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.field === 'id') {
      if (sorter.order === 'ascend') {
        setSort('ASC');
        fetchPaymentsOnline({ limit: limit, page: currentPage, sortId: 'ASC' });
      } else {
        setSort('DESC');
        fetchPaymentsOnline({
          limit: limit,
          page: currentPage,
          sortId: 'DESC',
        });
      }
    }
  };

  const columns = [
    {
      title: 'ID платежа',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => {
        return (
          <div key={id}>
            <Link to={`/services/payment/payment-online/update/${id}`}>
              {id}
            </Link>
          </div>
        );
      },
      sorter: true,
    },
    {
      title: 'Способ оплаты',
      key: 'paymentSystem',
      render: (data: any) => {
        if (data.paymentMethod) {
          return <>{data.paymentMethod.name}</>;
        } else {
          return '---';
        }
      },
    },
    {
      title: 'Номер заказа/кредита',
      dataIndex: 'entityId',
      key: 'entityId',
      render: (entityId: string) => {
        if (entityId) {
          return <>{entityId}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Сумма',
      dataIndex: 'sum',
      key: 'sum',
      render: (sum: string) => {
        if (sum) {
          return <>{sum}</>;
        } else {
          return '---';
        }
      },
    },
    {
      title: 'Дата оплаты',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (data: string) => {
        if (data) {
          return <>{new Date(data).toLocaleDateString()}</>;
        } else {
          return 'не оплачен';
        }
      },
    },
    {
      title: 'Пользователь',
      key: 'user',
      render: (data: any) => {
        if (data.user) {
          return (
            `${
              data.user.lastName !== null && data.user.lastName !== 'null'
                ? data.user.lastName
                : ''
            }` +
            ' ' +
            `${
              data.user.firstName !== null && data.user.firstName !== 'null'
                ? data.user.firstName
                : ''
            }` +
            ' ' +
            `${
              data.user.middleName !== null && data.user.middleName !== 'null'
                ? data.user.middleName
                : ''
            }`
          );
        } else {
          return '---';
        }
      },
    },
    {
      title: 'Статус',
      key: 'status',
      render: (data: any) => {
        return (
          <StatusEditor
            status={data.status}
            id={data.id}
            options={statusPaymentOnlineOptions}
            setEdit={setEdit}
            url={ApiUrl.UPDATE_PAYMENT_ONLINE_STATUS}
          />
        );
      },
    },
    {
      title: 'Действия с платежом',
      dataIndex: 'id',
      key: 'edit',
      render: (id: string) => {
        return (
          <>
            <Link to={`/services/payment/payment-online/update/${id}`}>
              <EditOutlined style={{ marginLeft: 50 }} />
            </Link>
          </>
        );
      },
    },
  ];

  return (
    <div className={styles.main}>
      <h1>Онлайн платежи</h1>
      <h4 style={{ textAlign: 'left' }}>Фильтры полей</h4>
      <Form form={form}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <FormInput
              name={'id'}
              label={'Id'}
              placeholder={'Введите id'}
              search={true}
              onSearch={(value) => {
                fetchPaymentsOnline({
                  limit: limit,
                  page: currentPage,
                  sortId: sort,
                  id: value,
                });
              }}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={12}>
            <FormSelect
              name={'paymentSystemId'}
              label={'Способ оплаты'}
              placeholder={'Выберите способ оплаты'}
              options={paymentsOptions}
              showSearch={true}
              labelCol={{ span: 24 }}
            />
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <FormInput
              name={'entityId'}
              label={'Номер заказа/кредита'}
              placeholder={'Введите номер'}
              search={true}
              onSearch={(value) => {
                fetchPaymentsOnline({
                  limit: limit,
                  page: currentPage,
                  sortId: sort,
                  entityId: value,
                });
              }}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={12}>
            <FormSelect
              name={'status'}
              label={'Статус'}
              placeholder={'Выберите статус'}
              options={statusPaymentOnlineOptions}
              showSearch={true}
              labelCol={{ span: 24 }}
            />
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <FormInput
              name={'sum'}
              label={'Сумма'}
              placeholder={'Введите сумму'}
              search={true}
              onSearch={(value) => {
                fetchPaymentsOnline({
                  limit: limit,
                  page: currentPage,
                  sortId: sort,
                  sum: value,
                });
              }}
              labelCol={{ span: 24 }}
            />
          </Col>
        </Row>
        <Button
          style={{ marginBottom: 20 }}
          type="primary"
          htmlType="submit"
          onClick={applyFilters}
        >
          Применить фильтры
        </Button>
        <Button
          style={{ marginLeft: 20 }}
          type="ghost"
          htmlType="reset"
          onClick={() => {
            return fetchPaymentsOnline({
              limit: limit,
              page: 1,
              sortId: sort,
            });
          }}
        >
          Очистить фильтры
        </Button>
      </Form>
      <Pagination
        style={{ marginBottom: 20 }}
        total={totalPaymentsOnline}
        current={currentPage}
        showQuickJumper={true}
        onChange={handlePagination}
        onShowSizeChange={onShowSizeChange}
        pageSizeOptions={[10, 25, 50, 100]}
        defaultPageSize={25}
      />
      <Table
        bordered
        columns={columns}
        dataSource={paymentsOnline}
        pagination={false}
        onChange={handleChange}
        rowKey="id"
      />
    </div>
  );
};
