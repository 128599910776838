import React from 'react';
import FormInput from '../../../../../components/FormItems/FormInput';

export const SEO: React.FC = () => {
  const fields = [
    {
      label: 'metaTitle',
      name: 'metaTitle',
      key: '0',
      maxLength: 50,
    },
    {
      label: 'metaDescription',
      name: 'metaDescription',
      key: '1',
      maxLength: 200,
    },
    {
      label: 'metaKeywords',
      name: 'keywords',
      key: '2',
      maxLength: 100,
    },
  ];

  return (
    <div>
      {fields.map((item) => (
        <FormInput
          key={item.key}
          label={item.label}
          name={item.name}
          textArea={true}
          labelCol={{ span: 24 }}
          maxLength={item.maxLength}
        />
      ))}
    </div>
  );
};
