import React, { useEffect } from 'react';
import FormSelect from '../FormItems/FormSelect';

interface MultiSelectProps {
  value: any;
  form: any;
  name: number;
  options: { label: string; value: number }[];
}

export const MultiSelectCustom: React.FC<MultiSelectProps> = ({
  value,
  form,
  name,
  options,
}) => {
  const selectOptions = value.map((el: any) => {
    return el.optionValue;
  });
  useEffect(() => {
    form.setFieldsValue({
      ['attributeTypeProductMultiSelect' + name]: selectOptions,
    });
  }, []);

  return (
    <FormSelect
      name={'attributeTypeProductMultiSelect' + name}
      options={options}
      showSearch={true}
      mode={'multiple'}
      labelCol={{ span: 24 }}
    />
  );
};
