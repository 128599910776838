export interface IPage {
  id: number;
  title: string | null;
  description: string | null;
  image: string | null;
  status: number;
  content: string | null;
  views: number;
  url: string;
  keywords: string[] | null;
  isNews: boolean;
}

export interface PageState {
  pages: IPage[];
  error: string | null;
  loading: boolean;
  currentPage: number;
  limit: number;
  totalPages: number;
}

export enum PageActionTypes {
  FETCH_PAGE = 'FETCH_PAGE',
  FETCH_PAGES = 'FETCH_PAGES',
  FETCH_PAGES_ERROR = 'FETCH_PAGES_ERROR',
  SET_PAGE_PAGE = 'SET_PAGE_PAGE',
  SET_TOTAL_PAGE_PAGE = 'SET_TOTAL_PAGE_PAGE',
}

interface FetchPageAction {
  type: PageActionTypes.FETCH_PAGE;
}

interface FetchPagesAction {
  type: PageActionTypes.FETCH_PAGES;
  payload: IPage[];
}

interface FetchPagesErrorAction {
  type: PageActionTypes.FETCH_PAGES_ERROR;
  payload: string;
}

interface SetPagePageAction {
  type: PageActionTypes.SET_PAGE_PAGE;
  payload: number;
}

interface SetTotalPagePageAction {
  type: PageActionTypes.SET_TOTAL_PAGE_PAGE;
  payload: number;
}

export type PageAction =
  | FetchPageAction
  | FetchPagesAction
  | FetchPagesErrorAction
  | SetPagePageAction
  | SetTotalPagePageAction;
