import { FormInstance } from 'antd';

export const useTrimField = (
  form: FormInstance<any>,
  fields: string[] | null,
  e?: any,
) => {
  if (!fields) fields = Object.keys(form.getFieldsValue());
  for (const field of fields) {
    let value = form.getFieldValue(field);
    if (typeof value === 'string') {
      value = value.trim();
      form.setFieldValue(field, value);
      if (e) e[field] = value;
    }
  }
};
