import React from 'react';
import { Link, Route } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import { ComponentsLayout } from '../../layout/ComponentsLayout/ComponentsLayout';
import { CommentsList } from '../../Pages/Comments/CommentsList';

export const CommentsRoutes = (
  <>
    <Route
      path="/comments/list"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Список отзывов</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <CommentsList />
          </ComponentsLayout>
        </>
      }
    />
  </>
);
