import { Dispatch } from 'redux';

import { $api } from '../../../http';
import { ApiUrl } from '../../../types/ApiUrl';
import {
  OrderHistoryAction,
  OrderHistoryActionTypes,
} from '../../../types/entities/Order/OrderHistory';

interface queryOrderHistoryParamsInterface {
  limit?: any;
  page?: any;
  sortId?: string;
  id?: string;
  orderId?: string;
  field?: string;
  newValue?: string;
  oldValue?: string;
}

export const fetchOrderHistories = (
  queryParams: queryOrderHistoryParamsInterface,
) => {
  const {
    limit = '25',
    page = 1,
    sortId = 'DESC',
    id,
    orderId,
    field,
    newValue,
    oldValue,
  } = queryParams;

  const urlOrderHistoryParams = new URLSearchParams();
  urlOrderHistoryParams.append('limit', limit);
  urlOrderHistoryParams.append('page', page);
  urlOrderHistoryParams.append('sortId', sortId);

  if (id !== undefined) urlOrderHistoryParams.append('id', id);
  if (orderId !== undefined) urlOrderHistoryParams.append('orderId', orderId);
  if (field !== undefined) urlOrderHistoryParams.append('field', field);
  if (newValue !== undefined)
    urlOrderHistoryParams.append('newValue', newValue);
  if (oldValue !== undefined)
    urlOrderHistoryParams.append('oldValue', oldValue);

  return async (dispatch: Dispatch<OrderHistoryAction>) => {
    try {
      dispatch({ type: OrderHistoryActionTypes.FETCH_ORDER_HISTORY });
      const response = await $api.get(
        `${ApiUrl.ORDER_HISTORY}?${urlOrderHistoryParams.toString()}`,
      );
      dispatch({
        type: OrderHistoryActionTypes.FETCH_ORDER_HISTORIES,
        payload: response.data.items,
      });
      dispatch({
        type: OrderHistoryActionTypes.SET_TOTAL_ORDER_HISTORY_PAGE,
        payload: response.data.total,
      });
      dispatch({
        type: OrderHistoryActionTypes.SET_ORDER_HISTORY_PAGE,
        payload: page,
      });
    } catch (e) {
      dispatch({
        type: OrderHistoryActionTypes.FETCH_ORDER_HISTORIES_ERROR,
        payload: 'Произошла ошибка при загрузке истории заказов',
      });
    }
  };
};
