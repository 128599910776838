const MatchRegExp = {
  email: new RegExp(
    /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/iu,
  ),
  phone: new RegExp(
    /^(\+7|7|8)?[\s\-]?\(?[489]\d{2}\)?[\s\-]?\d{3}[\s\-]?\d{2}[\s\-]?\d{2}$/,
  ),
  slug: new RegExp(/^([\w#!:,.?+=&%\-\/]*)$/),
};

export enum ValidateType {
  email = 'email',
  phone = 'phone',
  slug = 'slug',
}

/**
 * @param value - значение для валидации
 * @param listValidateTypes - список типов валидации
 * */
interface UseValidatorI {
  value: any;
  listValidateTypes: Array<
    ValidateType.phone | ValidateType.email | ValidateType.slug
  >;
}

/**
 * @param { value, listValidateTypes }
 * @return возвращает boolean массив(какие поля отвалидировались, а какие нет)
 * */
export const useValidator = ({
  value,
  listValidateTypes,
}: UseValidatorI): Array<boolean> => {
  return listValidateTypes.map((elem) => {
    return MatchRegExp[elem].test(value);
  });
};

/**
 * @param { value, listValidateTypes }
 * @return возвращает название типа из перечня типов
 * */
export const searchTypeValidator = ({
  value,
  listValidateTypes,
}: UseValidatorI): ValidateType => {
  const listValidate = useValidator({ value, listValidateTypes });
  return listValidateTypes[listValidate.indexOf(true)];
};
