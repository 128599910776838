import { IProducer } from './Producer';
import { ICategory } from './Category';

export interface IProduct {
  id: number;
  title: string | null;
  images: string[] | null;
  description: string | null;
  producerId: number | null;
  producer?: IProducer;
  categoryId: number | null;
  category?: ICategory;
  status: number;
  sku: string;
  slug?: string;
  shortDescription?: string;
  averagePrice: number;
  metaTitle?: string;
  metaDescription?: string;
  keywords?: string;
}

export interface ProductState {
  products: IProduct[]; // для получения нескольких товаров
  error: string | null;
  loading: boolean;
  currentPage: number;
  limit: number;
  totalProducts: number;
}

export enum ProductActionTypes {
  FETCH_PRODUCT = 'FETCH_PRODUCT',
  FETCH_PRODUCTS = 'FETCH_PRODUCTS',
  FETCH_PRODUCTS_ERROR = 'FETCH_PRODUCTS_ERROR',
  SET_PRODUCT_PAGE = 'SET_PRODUCT_PAGE',
  SET_TOTAL_PRODUCT_PAGE = 'SET_TOTAL_PRODUCT_PAGE',
}

interface FetchAction {
  type: ProductActionTypes.FETCH_PRODUCT;
}

interface FetchProductsAction {
  type: ProductActionTypes.FETCH_PRODUCTS;
  payload: IProduct[];
}

interface FetchProductsErrorAction {
  type: ProductActionTypes.FETCH_PRODUCTS_ERROR;
  payload: string;
}

interface SetProductPageAction {
  type: ProductActionTypes.SET_PRODUCT_PAGE;
  payload: number;
}

interface SetTotalProductPageAction {
  type: ProductActionTypes.SET_TOTAL_PRODUCT_PAGE;
  payload: number;
}

export type ProductAction =
  | FetchAction
  | FetchProductsAction
  | FetchProductsErrorAction
  | SetProductPageAction
  | SetTotalProductPageAction;
