import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Pagination, Row, Table } from 'antd';
import { Link } from 'react-router-dom';

import styles from './RolesList.module.scss';
import { useHttp } from '../../../hooks/useHttp';
import { ApiUrl } from '../../../types/ApiUrl';

export const RolesList: React.FC = () => {
  const [roles, setRoles] = useState([]);
  const { request } = useHttp();

  const getData = async (page = 1) => {
    const response = await request({
      url: `${ApiUrl.ROLE}`,
      method: 'get',
    });
    setRoles(response.data);
  };

  useEffect(() => {
    getData();
  }, []);

  const columns = [
    {
      title: 'Значение',
      dataIndex: 'value',
      key: 'value',
      render: (value: string) => {
        if (value) {
          return (
            <div key={value}>
              <Link to={`/roles/update/${value}`}>{value}</Link>
            </div>
          );
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
      render: (description: string) => {
        if (description) {
          return <>{description}</>;
        } else {
          return 'Не указано';
        }
      },
    },
  ];

  return (
    <div className={styles.main}>
      <h1>Список ролей</h1>
      <Table
        bordered
        columns={columns}
        dataSource={roles}
        pagination={false}
        rowKey="id"
      />
    </div>
  );
};
