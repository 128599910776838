import {
  PaymentAction,
  PaymentActionTypes,
  PaymentState,
} from '../../../types/entities/Payment/Payment';

const initialState: PaymentState = {
  payments: [],
  paymentsList: [],
  error: null,
  loading: false,
  currentPage: 1,
  limit: 25,
  totalPayments: 0,
};

export const paymentReducer = (
  state = initialState,
  action: PaymentAction,
): PaymentState => {
  switch (action.type) {
    case PaymentActionTypes.FETCH_PAYMENT:
      return { ...state, loading: true };
    case PaymentActionTypes.FETCH_PAYMENTS_ERROR:
      return { ...state, error: action.payload, loading: false };
    case PaymentActionTypes.FETCH_PAYMENTS:
      return {
        ...state,
        error: null,
        paymentsList: action.payload,
        loading: false,
      };
    case PaymentActionTypes.SET_PAYMENT_PAGE:
      return {
        ...state,
        error: null,
        currentPage: action.payload,
        loading: false,
      };
    case PaymentActionTypes.SET_TOTAL_PAYMENT_PAGE:
      return {
        ...state,
        error: null,
        totalPayments: action.payload,
        loading: false,
      };
    case PaymentActionTypes.FETCH_PAYMENTS_SELECT:
      return {
        ...state,
        error: null,
        payments: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
