import React from 'react';
import { Breadcrumb } from 'antd';
import { Link, Route } from 'react-router-dom';

import { ComponentsLayout } from '../../layout/ComponentsLayout/ComponentsLayout';
import { OrdersList } from '../../Pages/Orders/OrdersList/OrdersList';
import { Order } from '../../Pages/Orders/Order/Order';

export const OrderRoutes = (
  <>
    <Route
      path="/orders/update/:id"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/orders/list">Список заказов</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Редактирование заказа</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <Order />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/orders/list"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Список заказов</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <OrdersList />
          </ComponentsLayout>
        </>
      }
    />
  </>
);
