import {
  AttributeGroupAction,
  AttributeGroupActionTypes,
  AttributeGroupState,
} from '../../../types/entities/Attribute/AttributeGroup';

const initialState: AttributeGroupState = {
  attributeGroups: [],
  error: null,
  loading: false,
  currentPage: 1,
  limit: 25,
  totalAttributeGroups: 0,
};

export const attributeGroupReducer = (
  state = initialState,
  action: AttributeGroupAction,
): AttributeGroupState => {
  switch (action.type) {
    case AttributeGroupActionTypes.FETCH_ATTRIBUTE_GROUP:
      return { ...state, loading: true };
    case AttributeGroupActionTypes.FETCH_ATTRIBUTE_GROUPS_ERROR:
      return { ...state, error: action.payload, loading: false };
    case AttributeGroupActionTypes.FETCH_ATTRIBUTE_GROUPS:
      return {
        ...state,
        error: null,
        attributeGroups: action.payload,
        loading: false,
      };
    case AttributeGroupActionTypes.SET_ATTRIBUTE_GROUP_PAGE:
      return {
        ...state,
        error: null,
        currentPage: action.payload,
        loading: false,
      };
    case AttributeGroupActionTypes.SET_TOTAL_ATTRIBUTE_GROUP_PAGE:
      return {
        ...state,
        error: null,
        totalAttributeGroups: action.payload,
        loading: false,
      };
    case AttributeGroupActionTypes.FETCH_ATTRIBUTE_GROUPS_SELECT:
      return {
        ...state,
        error: null,
        attributeGroups: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
