import React, { useEffect } from 'react';
import FormInput from '../FormItems/FormInput';

interface TextareaProps {
  value: string;
  form: any;
  name: number;
}

export const TextareaCustom: React.FC<TextareaProps> = ({
  value,
  form,
  name,
}) => {
  useEffect(() => {
    form.setFieldsValue({
      ['attributeTypeProductTextarea' + name]: value,
    });
  }, []);

  return (
    <FormInput
      name={'attributeTypeProductTextarea' + name}
      textArea={true}
      labelCol={{ span: 24 }}
    />
  );
};
