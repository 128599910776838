import { IOrder } from '../Order/Order';
import { IDelivery } from '../Delivery';

export interface IPayment {
  id: number;
  module: string;
  name: string;
  description: string;
  settings: string;
  position: number;
  status: number;
  buttonName: string;
  verifyPhone: number;
  deliveries: IDelivery[];
  orders: IOrder[];
}

export interface PaymentState {
  payments: IPayment[];
  paymentsList: IPayment[];
  error: string | null;
  loading: boolean;
  currentPage: number;
  limit: number;
  totalPayments: number;
}

export enum PaymentActionTypes {
  FETCH_PAYMENT = 'FETCH_PAYMENT',
  FETCH_PAYMENTS = 'FETCH_PAYMENTS',
  FETCH_PAYMENTS_ERROR = 'FETCH_PAYMENTS_ERROR',
  SET_PAYMENT_PAGE = 'SET_PAYMENT_PAGE',
  SET_TOTAL_PAYMENT_PAGE = 'SET_TOTAL_PAYMENT_PAGE',
  FETCH_PAYMENTS_SELECT = 'FETCH_PAYMENTS_SELECT',
}

interface FetchPaymentAction {
  type: PaymentActionTypes.FETCH_PAYMENT;
}

interface FetchPaymentsAction {
  type: PaymentActionTypes.FETCH_PAYMENTS;
  payload: IPayment[];
}

interface FetchPaymentsErrorAction {
  type: PaymentActionTypes.FETCH_PAYMENTS_ERROR;
  payload: string;
}

interface SetPaymentPageAction {
  type: PaymentActionTypes.SET_PAYMENT_PAGE;
  payload: number;
}

interface SetTotalPaymentPageAction {
  type: PaymentActionTypes.SET_TOTAL_PAYMENT_PAGE;
  payload: number;
}

interface FetchPaymentsSelectAction {
  type: PaymentActionTypes.FETCH_PAYMENTS_SELECT;
  payload: IPayment[];
}

export type PaymentAction =
  | FetchPaymentAction
  | FetchPaymentsAction
  | FetchPaymentsErrorAction
  | SetPaymentPageAction
  | SetTotalPaymentPageAction
  | FetchPaymentsSelectAction;
