import { IUser } from '../entities/User';

export enum AuthTypes {
  TOKEN = 'token',
  USER = 'user',
}

export interface AuthResponse {
  accessToken: string;
  refreshToken?: string;
  user: IUser;
}

export interface AuthState {
  user: IUser | null;
  isAuth: boolean;
  error: string | null;
  loading: boolean;
  isAdmin: boolean;
}

export enum AuthActionTypes {
  FETCH = 'FETCH',
  END_FETCH = 'END_FETCH',
  LOGIN = 'LOGIN',
  REGISTRATION = 'REGISTRATION',
  LOGOUT = 'LOGOUT',
  ERROR = 'ERROR',
}

interface FetchAction {
  type: AuthActionTypes.FETCH;
}

interface EndFetchAction {
  type: AuthActionTypes.END_FETCH;
}

interface LoginAction {
  type: AuthActionTypes.LOGIN;
  payload: AuthResponse;
}

interface RegistrationAction {
  type: AuthActionTypes.REGISTRATION;
  payload: AuthResponse;
}

interface LogoutAction {
  type: AuthActionTypes.LOGOUT;
}

interface ErrorAction {
  type: AuthActionTypes.ERROR;
  payload: string;
}

export type AuthAction =
  | FetchAction
  | EndFetchAction
  | LoginAction
  | RegistrationAction
  | LogoutAction
  | ErrorAction;
