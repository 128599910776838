import {
  ProductLogAction,
  ProductLogActionTypes,
  ProductLogState,
} from '../../types/entities/ProductLog';

const initialState: ProductLogState = {
  productLogs: [],
  error: null,
  loading: false,
  currentPage: 1,
  limit: 25,
  totalProductLogs: 0,
};

export const productLogReducer = (
  state = initialState,
  action: ProductLogAction,
): ProductLogState => {
  switch (action.type) {
    case ProductLogActionTypes.FETCH_PRODUCT_LOG:
      return { ...state, loading: true };
    case ProductLogActionTypes.FETCH_PRODUCT_LOGS_ERROR:
      return { ...state, error: action.payload, loading: false };
    case ProductLogActionTypes.FETCH_PRODUCT_LOGS:
      return {
        ...state,
        error: null,
        productLogs: action.payload,
        loading: false,
      };
    case ProductLogActionTypes.SET_PRODUCT_LOG_PAGE:
      return {
        ...state,
        error: null,
        currentPage: action.payload,
        loading: false,
      };
    case ProductLogActionTypes.SET_TOTAL_PRODUCT_LOG_PAGE:
      return {
        ...state,
        error: null,
        totalProductLogs: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
