import { Dispatch } from 'redux';

import { ApiUrl } from '../../../types/ApiUrl';
import { $api } from '../../../http';
import {
  MenuItemAction,
  MenuItemActionTypes,
} from '../../../types/entities/Menu/MenuItem';

interface queryMenuItemParamsInterface {
  limit?: any;
  page?: any;
  sortId?: string;
  title?: string;
  menuId?: string;
  status?: string;
  parentId?: string;
}

export const fetchMenuItemsForSelect = () => {
  return async (dispatch: Dispatch<MenuItemAction>) => {
    try {
      dispatch({ type: MenuItemActionTypes.FETCH_MENU_ITEM });
      const response = await $api.get(`${ApiUrl.MENU_ITEM_SELECT}`);
      dispatch({
        type: MenuItemActionTypes.FETCH_MENU_ITEMS_SELECT,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: MenuItemActionTypes.FETCH_MENU_ITEMS_ERROR,
        payload: 'Произошла ошибка при загрузке пунктов меню',
      });
    }
  };
};

export const fetchMenuItemsList = (
  queryParams: queryMenuItemParamsInterface,
) => {
  const {
    limit = '25',
    page = 1,
    sortId = 'DESC',
    title,
    menuId,
    status,
    parentId,
  } = queryParams;

  const urlMenuItemParams = new URLSearchParams();
  urlMenuItemParams.append('limit', limit);
  urlMenuItemParams.append('page', page);
  urlMenuItemParams.append('sortId', sortId);
  if (title !== undefined) urlMenuItemParams.append('title', title);
  if (menuId !== undefined) urlMenuItemParams.append('menuId', menuId);
  if (status !== undefined) urlMenuItemParams.append('status', status);
  if (parentId !== undefined) urlMenuItemParams.append('parentId', parentId);

  return async (dispatch: Dispatch<MenuItemAction>) => {
    try {
      dispatch({ type: MenuItemActionTypes.FETCH_MENU_ITEM });
      const response = await $api.get(
        `${ApiUrl.GET_ALL_MENU_ITEMS}?${urlMenuItemParams.toString()}`,
      );
      dispatch({
        type: MenuItemActionTypes.FETCH_MENU_ITEMS,
        payload: response.data.items,
      });
      dispatch({
        type: MenuItemActionTypes.SET_TOTAL_MENU_ITEM_PAGE,
        payload: response.data.total,
      });
      dispatch({ type: MenuItemActionTypes.SET_MENU_ITEM_PAGE, payload: page });
    } catch (e) {
      dispatch({
        type: MenuItemActionTypes.FETCH_MENU_ITEMS_ERROR,
        payload: 'Произошла ошибка при загрузке пунктов меню',
      });
    }
  };
};
