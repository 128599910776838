import React from 'react';
import { Link, Route } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import { ComponentsLayout } from '../../layout/ComponentsLayout/ComponentsLayout';
import { MenuItemsList } from '../../Pages/MenuItem/MenuItemsList/MenuItemsList';
import { AddMenuItem } from '../../Pages/MenuItem/MenuItem/AddMenuItem/AddMenuItem';
import { UpdateMenuItem } from '../../Pages/MenuItem/MenuItem/UpdateMenuItem/UpdateMenuItem';

export const MenuItemsRoutes = (
  <>
    <Route
      path="/menu/menu-item/add"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Добавить пункт меню</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <AddMenuItem />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/menu/menu-item/update/:id"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/menu/menu-item/list">Пункты меню</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Обновление пункта меню</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <UpdateMenuItem />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/menu/menu-item/list"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Пункты меню</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <MenuItemsList />
          </ComponentsLayout>
        </>
      }
    />
  </>
);
