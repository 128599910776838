import { IGeoCity } from './GeoCity';
import { IGeoCityToZone } from './GeoCityToZone';

export interface IGeoRegion {
  id: number;
  name: string;
  status: number;
  geoCities: IGeoCity[];
  geoCityToZones: IGeoCityToZone[];
}

export interface GeoRegionState {
  geoRegions: IGeoRegion[];
  geoRegionsList: IGeoRegion[];
  error: string | null;
  loading: boolean;
  currentPage: number;
  limit: number;
  totalGeoRegions: number;
}

export enum GeoRegionActionTypes {
  FETCH_GEO_REGION = 'FETCH_GEO_REGION',
  FETCH_GEO_REGIONS = 'FETCH_GEO_REGIONS',
  FETCH_GEO_REGIONS_ERROR = 'FETCH_GEO_REGIONS_ERROR',
  SET_GEO_REGION_PAGE = 'SET_GEO_REGION_PAGE',
  SET_TOTAL_GEO_REGION_PAGE = 'SET_TOTAL_GEO_REGION_PAGE',
  FETCH_GEO_REGIONS_SELECT = 'FETCH_GEO_REGIONS_SELECT',
}

interface FetchGeoRegionAction {
  type: GeoRegionActionTypes.FETCH_GEO_REGION;
}

interface FetchGeoRegionsAction {
  type: GeoRegionActionTypes.FETCH_GEO_REGIONS;
  payload: IGeoRegion[];
}

interface FetchGeoRegionsErrorAction {
  type: GeoRegionActionTypes.FETCH_GEO_REGIONS_ERROR;
  payload: string;
}

interface SetGeoRegionPageAction {
  type: GeoRegionActionTypes.SET_GEO_REGION_PAGE;
  payload: number;
}

interface SetTotalGeoRegionPageAction {
  type: GeoRegionActionTypes.SET_TOTAL_GEO_REGION_PAGE;
  payload: number;
}

interface FetchGeoRegionsSelectAction {
  type: GeoRegionActionTypes.FETCH_GEO_REGIONS_SELECT;
  payload: IGeoRegion[];
}

export type GeoRegionAction =
  | FetchGeoRegionAction
  | FetchGeoRegionsAction
  | FetchGeoRegionsErrorAction
  | SetGeoRegionPageAction
  | SetTotalGeoRegionPageAction
  | FetchGeoRegionsSelectAction;
