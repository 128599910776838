export const SelectOptionHelper = (fetchData: any[]) => {
  return fetchData.map((element) => {
    return {
      value: element.id,
      label: element.name
        ? element.name
        : element.title
        ? element.title
        : element.description,
      // parentId: element.parentId,
    };
  });
};

export const SelectOptionUserHelper = (fetchData: any[]) => {
  return fetchData.map((element) => {
    return {
      value: element.id,
      label:
        element.lastName + ' ' + element.firstName + ' ' + element.middleName,
    };
  });
};

export function createTree(fetchData: any[]) {
  if (!fetchData || !fetchData.length) {
    return [];
  }

  const map = new Map();

  for (const item of fetchData) {
    const newItem = {
      value: item.id,
      title: item.name,
      children: item.children,
      parentId: item.parentId,
      id: item.id,
    };
    if (map.has(item.parentId)) {
      map.get(item.parentId).push(newItem);
    } else {
      map.set(item.parentId, [newItem]);
    }
  }
  for (const items of map)
    for (const item of items[1])
      if (map.has(item.id)) item.children = map.get(item.id);

  for (const mainItem of map.get(null))
    if (map.has(mainItem.id)) {
      mainItem.children = map.get(mainItem.id);
    }
  map.get(null).sort((a: any, b: any) => {
    return a.title.localeCompare(b.title);
  });
  return map.get(null);
}

export function createTreeMenu(fetchData: any[]) {
  if (!fetchData || !fetchData.length) {
    return [];
  }
  const map = new Map();

  for (const item of fetchData) {
    const newItem = {
      value: item.id,
      title: item.title,
      children: [],
      parentId: item.parentId === null ? null : item.parentId,
      id: item.id,
    };
    if (map.has(item.parentId)) {
      map.get(item.parentId).push(newItem);
    } else {
      map.set(item.parentId, [newItem]);
    }
  }

  for (const items of map)
    for (const item of items[1])
      if (map.has(item.id)) item.children = map.get(item.id);

  for (const mainItem of map.get(null))
    if (map.has(mainItem.id)) {
      mainItem.children = map.get(mainItem.id);
    }
  map.get(null).sort((a: any, b: any) => {
    return a.title.localeCompare(b.title);
  });
  return map.get(null);
}
