import { IPayment } from './Payment/Payment';
import { IGeoZone } from './Geo/GeoZone';
import { IOrder } from './Order/Order';

export interface IDelivery {
  id: number;
  name: string;
  description: string;
  price: number;
  freeFrom: number;
  position: number;
  status: number;
  separatePayment: number;
  serviceName: string;
  payments: IPayment[];
  geoZoneId: number;
  geoZone: IGeoZone | undefined;
  onecCode: string;
  orders: IOrder[];
}

export interface DeliveryState {
  deliveries: IDelivery[];
  deliveriesList: IDelivery[];
  error: string | null;
  loading: boolean;
  currentPage: number;
  limit: number;
  totalDeliveries: number;
}

export enum DeliveryActionTypes {
  FETCH_DELIVERY = 'FETCH_DELIVERY',
  FETCH_DELIVERIES = 'FETCH_DELIVERIES',
  FETCH_DELIVERIES_ERROR = 'FETCH_DELIVERIES_ERROR',
  SET_DELIVERY_PAGE = 'SET_DELIVERY_PAGE',
  SET_TOTAL_DELIVERY_PAGE = 'SET_TOTAL_DELIVERY_PAGE',
  FETCH_DELIVERIES_SELECT = 'FETCH_DELIVERIES_SELECT',
}

interface FetchDeliveryAction {
  type: DeliveryActionTypes.FETCH_DELIVERY;
}

interface FetchDeliveriesAction {
  type: DeliveryActionTypes.FETCH_DELIVERIES;
  payload: IDelivery[];
}

interface FetchDeliveriesErrorAction {
  type: DeliveryActionTypes.FETCH_DELIVERIES_ERROR;
  payload: string;
}

interface SetDeliveryPageAction {
  type: DeliveryActionTypes.SET_DELIVERY_PAGE;
  payload: number;
}

interface SetTotalDeliveryPageAction {
  type: DeliveryActionTypes.SET_TOTAL_DELIVERY_PAGE;
  payload: number;
}

interface FetchDeliveriesSelectAction {
  type: DeliveryActionTypes.FETCH_DELIVERIES_SELECT;
  payload: IDelivery[];
}

export type DeliveryAction =
  | FetchDeliveryAction
  | FetchDeliveriesAction
  | FetchDeliveriesErrorAction
  | SetDeliveryPageAction
  | SetTotalDeliveryPageAction
  | FetchDeliveriesSelectAction;
