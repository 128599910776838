import { Dispatch } from 'redux';
import jwtDecode, { JwtPayload } from 'jwt-decode';
import { AuthAction, AuthActionTypes, AuthTypes } from '../../types/Auth/Auth';
import AuthService from '../../services/AuthService';
import { searchTypeValidator, ValidateType } from '../../hooks/useValidator';
import { IUser } from '../../types/entities/User';

export const registration = (userData: IUser) => {
  return async (dispatch: Dispatch<AuthAction>) => {
    try {
      dispatch({ type: AuthActionTypes.FETCH });
      const response = await AuthService.registration(userData);
      localStorage.setItem(AuthTypes.TOKEN, response.data.accessToken);
      dispatch({ type: AuthActionTypes.REGISTRATION, payload: response.data });
    } catch (e) {
      dispatch({
        type: AuthActionTypes.ERROR,
        payload: 'Произошла ошибка при регистрации',
      });
    }
  };
};

/**
 * @params loginData - номер телефона или email
 * @params codePass - код из смс/email
 * */
export const loginByCode = (loginData: string, codePass: string) => {
  return async (dispatch: Dispatch<AuthAction>) => {
    try {
      dispatch({ type: AuthActionTypes.FETCH });

      const useValidatorParams = {
        value: loginData,
        listValidateTypes: [ValidateType.email, ValidateType.phone],
      };
      const field = searchTypeValidator(useValidatorParams);
      const loginParams = { phone: '', email: '', codePass };
      if (field !== ValidateType.slug) loginParams[field] = loginData;

      const response = await AuthService.loginByCode(loginParams);
      localStorage.setItem(AuthTypes.TOKEN, response.data.accessToken);
      localStorage.setItem(AuthTypes.USER, JSON.stringify(response.data.user));
      dispatch({ type: AuthActionTypes.LOGIN, payload: response.data });
    } catch (e) {
      dispatch({
        type: AuthActionTypes.ERROR,
        payload: 'Произошла ошибка при авторизации',
      });
      throw e;
    }
  };
};

export const loginByPassword = (loginData: string, password: string) => {
  return async (dispatch: Dispatch<AuthAction>) => {
    try {
      dispatch({ type: AuthActionTypes.FETCH });
      const useValidatorParams = {
        value: loginData,
        listValidateTypes: [ValidateType.email, ValidateType.phone],
      };
      const field = searchTypeValidator(useValidatorParams);
      const loginParams = { phone: '', email: '', password };
      if (field !== ValidateType.slug) loginParams[field] = loginData;

      const response = await AuthService.loginByPassword(loginParams);
      localStorage.setItem(AuthTypes.TOKEN, response.data.accessToken);
      localStorage.setItem(AuthTypes.USER, JSON.stringify(response.data.user));
      dispatch({ type: AuthActionTypes.LOGIN, payload: response.data });
    } catch (e) {
      dispatch({
        type: AuthActionTypes.ERROR,
        payload: 'Произошла ошибка при авторизации',
      });
      throw e;
    }
  };
};

export const logout = () => {
  return async (dispatch: Dispatch<AuthAction>) => {
    try {
      dispatch({ type: AuthActionTypes.FETCH });
      await AuthService.logout();
      localStorage.removeItem(AuthTypes.TOKEN);
      dispatch({ type: AuthActionTypes.LOGOUT });
    } catch (e) {
      dispatch({
        type: AuthActionTypes.ERROR,
        payload: 'Произошла ошибка при выходе из аккаунта',
      });
      throw e;
    }
  };
};

export const checkAuthLocalStorage = () => {
  return async (dispatch: Dispatch<AuthAction>) => {
    try {
      const accessToken = localStorage.getItem(AuthTypes.TOKEN);
      const user = localStorage.getItem(AuthTypes.USER);
      let flagRefresh = false;
      if (accessToken && user) {
        const decoded = jwtDecode<JwtPayload>(accessToken); // Декодируем токен, чтобы получить его время жизни.
        //decoded.exp(в секундах) - время жизни токена
        if (
          decoded.exp &&
          new Date(new Date().getMilliseconds() + decoded.exp * 1000) <
            new Date()
        ) {
          // Флаг, чтобы выполнить refresh токена авторизации или нет
          // если accessToken есть в localStorage и умер, то обновим
          flagRefresh = true;
        } else {
          dispatch({
            type: AuthActionTypes.LOGIN,
            payload: { accessToken, isAuth: true, user: JSON.parse(user) },
          });
        }
      } else {
        // Флаг, чтобы выполнить refresh токена авторизации или нет
        // если accessToken'а нет в localStorage, то обновим
        flagRefresh = true;
      }

      if (flagRefresh && user) {
        try {
          const response = await AuthService.refresh();
          dispatch({ type: AuthActionTypes.LOGIN, payload: response.data });
        } catch (e) {
          dispatch({
            type: AuthActionTypes.ERROR,
            payload: 'Сессия истекла, авторизуйтесь повторно',
          });
        }
      }
    } catch (e) {
      dispatch({
        type: AuthActionTypes.ERROR,
        payload: 'Произошла ошибка при выходе из аккаунта',
      });
    }
  };
};
