import React from 'react';
import { Link, Route } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import { ComponentsLayout } from '../../layout/ComponentsLayout/ComponentsLayout';
import { ProductSliderGroupsList } from '../../Pages/ProductSliderGroups/ProductSliderGroupsList/ProductSliderGroupsList';
import { AddProductSliderGroup } from '../../Pages/ProductSliderGroups/ProductSliderGroup/AddProductSliderGroup/AddProductSliderGroup';
import { UpdateProductSliderGroup } from '../../Pages/ProductSliderGroups/ProductSliderGroup/UpdateProductSliderGroup/UpdateProductSliderGroup';

export const ProductSliderGroupRoutes = (
  <>
    <Route
      path="/content/product-slider-group/add"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Добавить ленту</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <AddProductSliderGroup />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/content/product-slider-group/update/:id"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/content/product-slider-group/list">Список лент</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Обновление ленты</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <UpdateProductSliderGroup />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/content/product-slider-group/list"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Список лент</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <ProductSliderGroupsList />
          </ComponentsLayout>
        </>
      }
    />
  </>
);
