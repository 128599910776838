import React from 'react';
import { Link, Route } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import { ComponentsLayout } from '../../layout/ComponentsLayout/ComponentsLayout';
import { PaymentsReferenceList } from '../../Pages/PaymentsReference/PaymentsReferenceList/PaymentsReferenceList';
import { AddPaymentReference } from '../../Pages/PaymentsReference/PaymentReference/AddPaymentReference/AddPaymentReference';
import { UpdatePaymentReference } from '../../Pages/PaymentsReference/PaymentReference/UpdatePaymentReference/UpdatePaymentReference';

export const PaymentReferenceRoutes = (
  <>
    <Route
      path="/services/payment/payment-reference/add"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Добавить платёж по ссылке</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <AddPaymentReference />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/services/payment/payment-reference/update/:id"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/services/payment/payment-reference/list">
                Список платежей
              </Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Обновление платежа по ссылке</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <UpdatePaymentReference />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/services/payment/payment-reference/list"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Список платежей по ссылке</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <PaymentsReferenceList />
          </ComponentsLayout>
        </>
      }
    />
  </>
);
