import React from 'react';
import { Link, Route } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import { ComponentsLayout } from '../../layout/ComponentsLayout/ComponentsLayout';
import { GeoZonesList } from '../../Pages/GeoZones/GeoZonesList/GeoZonesList';
import { AddGeoZone } from '../../Pages/GeoZones/GeoZone/AddGeoZone/AddGeoZone';
import { UpdateGeoZone } from '../../Pages/GeoZones/GeoZone/UpdateGeoZone/UpdateGeoZone';

export const GeoZoneRoutes = (
  <>
    <Route
      path="/content/geo/geo-zone/add"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Добавить геозону</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <AddGeoZone />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/content/geo/geo-zone/update/:id"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/content/geo/geo-zone/list">Список геозон</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Обновление геозоны</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <UpdateGeoZone />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/content/geo/geo-zone/list"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Список геозон</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <GeoZonesList />
          </ComponentsLayout>
        </>
      }
    />
  </>
);
