import {
  ProductAction,
  ProductActionTypes,
  ProductState,
} from '../../types/entities/Product';

const initialState: ProductState = {
  products: [],
  error: null,
  loading: false,
  currentPage: 1,
  limit: 25,
  totalProducts: 0,
};

export const productReducer = (
  state = initialState,
  action: ProductAction,
): ProductState => {
  switch (action.type) {
    case ProductActionTypes.FETCH_PRODUCT:
      return { ...state, loading: true };
    case ProductActionTypes.FETCH_PRODUCTS_ERROR:
      return { ...state, error: action.payload, loading: false };
    case ProductActionTypes.FETCH_PRODUCTS:
      return {
        ...state,
        error: null,
        products: action.payload,
        loading: false,
      };
    case ProductActionTypes.SET_PRODUCT_PAGE:
      return {
        ...state,
        error: null,
        currentPage: action.payload,
        loading: false,
      };
    case ProductActionTypes.SET_TOTAL_PRODUCT_PAGE:
      return {
        ...state,
        error: null,
        totalProducts: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
