import {
  GeoZoneAction,
  GeoZoneActionTypes,
  GeoZoneState,
} from '../../../types/entities/Geo/GeoZone';

const initialState: GeoZoneState = {
  geoZones: [],
  geoZonesList: [],
  error: null,
  loading: false,
  currentPage: 1,
  limit: 10,
  totalGeoZones: 0,
};

export const geoZoneReducer = (
  state = initialState,
  action: GeoZoneAction,
): GeoZoneState => {
  switch (action.type) {
    case GeoZoneActionTypes.FETCH_GEO_ZONE:
      return { ...state, loading: true };
    case GeoZoneActionTypes.FETCH_GEO_ZONES_ERROR:
      return { ...state, error: action.payload, loading: false };
    case GeoZoneActionTypes.FETCH_GEO_ZONES:
      return {
        ...state,
        error: null,
        geoZonesList: action.payload,
        loading: false,
      };
    case GeoZoneActionTypes.SET_GEO_ZONE_PAGE:
      return {
        ...state,
        error: null,
        currentPage: action.payload,
        loading: false,
      };
    case GeoZoneActionTypes.SET_TOTAL_GEO_ZONE_PAGE:
      return {
        ...state,
        error: null,
        totalGeoZones: action.payload,
        loading: false,
      };
    case GeoZoneActionTypes.FETCH_GEO_ZONES_SELECT:
      return {
        ...state,
        error: null,
        geoZones: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
