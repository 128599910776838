import React from 'react';

import { GeoRegion } from '../GeoRegion';
import { UPDATE_SUBMIT_METHOD } from '../../../../types/constants';

export const UpdateGeoRegion: React.FC = () => {
  const titlePage = 'Обновление региона';
  const submitMethod = UPDATE_SUBMIT_METHOD;

  const props = {
    titlePage: titlePage,
    submitMethod: submitMethod,
  };

  return (
    <>
      <GeoRegion {...props} />
    </>
  );
};
