import { Dispatch } from 'redux';

import { ApiUrl } from '../../types/ApiUrl';
import { $api } from '../../http';
import { TypeAction, TypeActionTypes } from '../../types/entities/Type';

export const fetchTypes = () => {
  return async (dispatch: Dispatch<TypeAction>) => {
    try {
      dispatch({ type: TypeActionTypes.FETCH_TYPE });
      const response = await $api.get(`${ApiUrl.TYPE}`);
      dispatch({
        type: TypeActionTypes.FETCH_TYPES,
        payload: response.data.items,
      });
    } catch (e) {
      dispatch({
        type: TypeActionTypes.FETCH_TYPES_ERROR,
        payload: 'Произошла ошибка при загрузке типов',
      });
    }
  };
};
