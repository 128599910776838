import {
  ShopAction,
  ShopActionTypes,
  ShopState,
} from '../../types/entities/Shop';

const initialState: ShopState = {
  shops: [],
  error: null,
  loading: false,
  currentPage: 1,
  limit: 25,
  totalShops: 0,
};

export const shopReducer = (
  state = initialState,
  action: ShopAction,
): ShopState => {
  switch (action.type) {
    case ShopActionTypes.FETCH_SHOP:
      return { ...state, loading: true };
    case ShopActionTypes.FETCH_SHOPS_ERROR:
      return { ...state, error: action.payload, loading: false };
    case ShopActionTypes.FETCH_SHOPS:
      return { ...state, error: null, shops: action.payload, loading: false };
    case ShopActionTypes.SET_SHOP_PAGE:
      return {
        ...state,
        error: null,
        currentPage: action.payload,
        loading: false,
      };
    case ShopActionTypes.SET_TOTAL_SHOP_PAGE:
      return {
        ...state,
        error: null,
        totalShops: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
