import React, { useEffect, useState } from 'react';
import { Table } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import styles from './DeliveriesList.module.scss';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useActions } from '../../../hooks/useActions';
import { StatusEditor } from '../../../components/Editors/StatusEditor';
import { statusDeliveryOptions } from '../../../helpers/deliveryHelper';
import { ApiUrl } from '../../../types/ApiUrl';

export const DeliveriesList: React.FC = () => {
  const { deliveriesList, limit, currentPage } = useTypedSelector(
    (state) => state.delivery,
  );

  const [sort, setSort] = useState('DESC');
  const [edit, setEdit] = useState(false);

  const { fetchDeliveriesList } = useActions();

  useEffect(() => {
    fetchDeliveriesList({
      limit: limit,
      page: currentPage,
      sortId: sort,
    });
  }, [edit]);

  // const onShowSizeChange = (currentPage: number, limit: number) => {
  //   fetchDeliveriesList({
  //     limit: limit,
  //     page: currentPage,
  //     sortId: sort,
  //   });
  // };
  //
  // const handlePagination = (currentPage: number, limit: number) => {
  //   fetchDeliveriesList({
  //     limit: limit,
  //     page: currentPage,
  //     sortId: sort,
  //   });
  // };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.field === 'id') {
      if (sorter.order === 'ascend') {
        setSort('ASC');
        fetchDeliveriesList({ limit: limit, page: currentPage, sortId: 'ASC' });
      } else {
        setSort('DESC');
        fetchDeliveriesList({
          limit: limit,
          page: currentPage,
          sortId: 'DESC',
        });
      }
    }
  };

  const columns = [
    {
      title: 'ID способа',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => {
        return (
          <div key={id}>
            <Link to={`/services/deliveries/update/${id}`}>{id}</Link>
          </div>
        );
      },
      sorter: true,
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      render: (name: string) => {
        if (name) {
          return <>{name}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Стоимость',
      dataIndex: 'price',
      key: 'price',
      render: (price: number) => {
        if (price.toString()) {
          return <>{price}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Бесплатна от',
      dataIndex: 'freeFrom',
      key: 'freeFrom',
      render: (freeFrom: number) => {
        if (freeFrom) {
          return <>{freeFrom}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Статус',
      key: 'status',
      render: (data: any) => {
        return (
          <StatusEditor
            status={data.status}
            id={data.id}
            options={statusDeliveryOptions}
            setEdit={setEdit}
            url={ApiUrl.UPDATE_DELIVERY_STATUS}
          />
        );
      },
    },
    {
      title: 'Действия со способом',
      dataIndex: 'id',
      key: 'edit',
      render: (id: string) => {
        return (
          <>
            <Link to={`/services/deliveries/update/${id}`}>
              <EditOutlined style={{ marginLeft: 50 }} />
            </Link>
          </>
        );
      },
    },
  ];
  return (
    <div className={styles.main}>
      <h1>Способы доставки</h1>
      {/*<Pagination*/}
      {/*  style={{ marginBottom: 20 }}*/}
      {/*  total={totalDeliveries}*/}
      {/*  current={currentPage}*/}
      {/*  showQuickJumper={true}*/}
      {/*  onChange={handlePagination}*/}
      {/*  onShowSizeChange={onShowSizeChange}*/}
      {/*  pageSizeOptions={[10, 25, 50, 100]}*/}
      {/*  defaultPageSize={25}*/}
      {/*/>*/}
      <Table
        bordered
        columns={columns}
        dataSource={deliveriesList}
        pagination={false}
        onChange={handleChange}
        rowKey="id"
      />
    </div>
  );
};
