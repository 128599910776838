import { Dispatch } from 'redux';

import { ApiUrl } from '../../types/ApiUrl';
import {
  ProducerAction,
  ProducerActionTypes,
} from '../../types/entities/Producer';
import { $api } from '../../http';

interface queryProducerParamsInterface {
  limit?: any;
  page?: any;
  sortId?: string;
  name?: string;
  status?: string;
}

export const fetchProducers = (queryParams: queryProducerParamsInterface) => {
  const { limit = '25', page = 1, sortId = 'DESC', name, status } = queryParams;

  const urlProducerParams = new URLSearchParams();
  urlProducerParams.append('limit', limit);
  urlProducerParams.append('page', page);
  urlProducerParams.append('sortId', sortId);
  if (name !== undefined) urlProducerParams.append('name', name);
  if (status !== undefined) urlProducerParams.append('status', status);

  return async (dispatch: Dispatch<ProducerAction>) => {
    try {
      dispatch({ type: ProducerActionTypes.FETCH_PRODUCER });
      const response = await $api.get(
        `${ApiUrl.PRODUCER}?${urlProducerParams.toString()}`,
      );
      dispatch({
        type: ProducerActionTypes.FETCH_PRODUCERS,
        payload: response.data.items,
      });
      dispatch({
        type: ProducerActionTypes.SET_TOTAL_PRODUCER_PAGE,
        payload: response.data.total,
      });
      dispatch({ type: ProducerActionTypes.SET_PRODUCER_PAGE, payload: page });
    } catch (e) {
      dispatch({
        type: ProducerActionTypes.FETCH_PRODUCERS_ERROR,
        payload: 'Произошла ошибка при загрузке производителей',
      });
    }
  };
};
