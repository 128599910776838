import { Dispatch } from 'redux';

import { ApiUrl } from '../../../types/ApiUrl';
import { $api } from '../../../http';
import { MenuAction, MenuActionTypes } from '../../../types/entities/Menu/Menu';

interface queryMenuParamsInterface {
  limit?: any;
  page?: any;
  sortId?: string;
  name?: string;
  code?: string;
  status?: string;
  description?: string;
}

export const fetchMenus = (queryParams: queryMenuParamsInterface) => {
  const {
    limit = '25',
    page = 1,
    sortId = 'DESC',
    name,
    code,
    status,
    description,
  } = queryParams;

  const urlMenuParams = new URLSearchParams();
  urlMenuParams.append('limit', limit);
  urlMenuParams.append('page', page);
  urlMenuParams.append('sortId', sortId);
  if (name !== undefined) urlMenuParams.append('name', name);
  if (code !== undefined) urlMenuParams.append('code', code);
  if (status !== undefined) urlMenuParams.append('status', status);
  if (description !== undefined)
    urlMenuParams.append('description', description);

  return async (dispatch: Dispatch<MenuAction>) => {
    try {
      dispatch({ type: MenuActionTypes.FETCH_MENU });
      const response = await $api.get(
        `${ApiUrl.MENU}?${urlMenuParams.toString()}`,
      );
      dispatch({
        type: MenuActionTypes.FETCH_MENUS,
        payload: response.data.items,
      });
      dispatch({
        type: MenuActionTypes.SET_TOTAL_MENU_PAGE,
        payload: response.data.total,
      });
      dispatch({ type: MenuActionTypes.SET_MENU_PAGE, payload: page });
    } catch (e) {
      dispatch({
        type: MenuActionTypes.FETCH_MENUS_ERROR,
        payload: 'Произошла ошибка при загрузке меню',
      });
    }
  };
};
