import {
  OrderAction,
  OrderActionTypes,
  OrderState,
} from '../../../types/entities/Order/Order';

const initialState: OrderState = {
  orders: [],
  error: null,
  loading: false,
  currentPage: 1,
  limit: 25,
  totalOrders: 0,
};

export const orderReducer = (
  state = initialState,
  action: OrderAction,
): OrderState => {
  switch (action.type) {
    case OrderActionTypes.FETCH_ORDER:
      return { ...state, loading: true };
    case OrderActionTypes.FETCH_ORDERS_ERROR:
      return { ...state, error: action.payload, loading: false };
    case OrderActionTypes.FETCH_ORDERS:
      return { ...state, error: null, orders: action.payload, loading: false };
    case OrderActionTypes.SET_ORDER_PAGE:
      return {
        ...state,
        error: null,
        currentPage: action.payload,
        loading: false,
      };
    case OrderActionTypes.SET_TOTAL_ORDER_PAGE:
      return {
        ...state,
        error: null,
        totalOrders: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
