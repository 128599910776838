import { FC, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useHttp } from '../../../../hooks/useHttp';
import { ApiUrl } from '../../../../types/ApiUrl';
import { Form, Input } from 'antd';

export const YandexUTM: FC = () => {
  const { id } = useParams();
  const { request } = useHttp();
  const [isExist, setIsExist] = useState<boolean>(false);

  const [form] = Form.useForm();

  const fetchData = async () => {
    const response = await request({
      url: `${ApiUrl.ORDER}/ym/utm/${id}`,
      method: 'get',
    });
    if (response.data) {
      form.setFieldsValue(response.data);
      setIsExist(true);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  const tagsNames = [
    'utm_source',
    'utm_medium',
    'utm_campaign',
    'utm_term',
    'utm_content',
  ];

  return (
    <Form form={form}>
      {!isExist ? (
        <h2>Нет данных о трафике по заказу</h2>
      ) : (
        tagsNames.map((item, index) => (
          <Form.Item key={index} name={item} labelCol={{ span: 10 }} label={item}>
            <Input readOnly={true}></Input>
          </Form.Item>
        ))
      )}
    </Form>
  );
};
