import * as ProductActionCreators from './product';
import * as CategoryActionCreators from './category';
import * as ProducerActionCreators from './producer';
import * as ShopActionCreators from './shop';
import * as OrderActionCreators from './order/order';
import * as OrderHistoryActionCreators from './order/orderHistory';
import * as UserActionCreators from './user';
import * as AttributeActionCreators from './attribute/attribute';
import * as AttributeGroupActionCreators from './attribute/attributeGroup';
import * as TypeActionCreators from './type';
import * as DeliveryActionCreators from './delivery';
import * as PaymentActionCreators from './payment/payment';
import * as PaymentOnlineActionCreators from './payment/paymentOnline';
import * as PaymentReferenceActionCreators from './payment/paymentReference';
import * as RoleActionCreators from './role';
import * as SliderGalleryActionCreators from './sliderGallery';
import * as ProductSliderGroupActionCreators from './productSliderGroup';
import * as MenuActionCreators from './menu/menu';
import * as MenuItemActionCreators from './menu/menuItem';
import * as AuthActionCreators from './auth';
import * as GeoZoneActionCreators from './geo/geoZone';
import * as GeoRegionActionCreators from './geo/geoRegion';
import * as GeoCityActionCreators from './geo/geoCity';
import * as NewsActionCreators from './news';
import * as PageActionCreators from './page';
import * as ProductLogActionCreators from './productLog';
import * as ImageActionCreators from './image';

export default {
  ...ProductActionCreators,
  ...CategoryActionCreators,
  ...ProducerActionCreators,
  ...ShopActionCreators,
  ...OrderActionCreators,
  ...OrderHistoryActionCreators,
  ...UserActionCreators,
  ...AttributeActionCreators,
  ...AttributeGroupActionCreators,
  ...TypeActionCreators,
  ...DeliveryActionCreators,
  ...PaymentActionCreators,
  ...PaymentOnlineActionCreators,
  ...PaymentReferenceActionCreators,
  ...RoleActionCreators,
  ...SliderGalleryActionCreators,
  ...ProductSliderGroupActionCreators,
  ...MenuActionCreators,
  ...MenuItemActionCreators,
  ...AuthActionCreators,
  ...GeoZoneActionCreators,
  ...GeoRegionActionCreators,
  ...GeoCityActionCreators,
  ...NewsActionCreators,
  ...PageActionCreators,
  ...ProductLogActionCreators,
  ...ImageActionCreators,
};
