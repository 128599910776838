import React, { useCallback, useEffect } from 'react';
import { Button, Card, Col, Form, Row } from 'antd';
import { useParams } from 'react-router-dom';

import styles from './User.module.scss';
import {
  emailConfirmUserOptions,
  statusUserOptions,
} from '../../../helpers/userHelper';
import { useHttp, UseHttpI } from '../../../hooks/useHttp';
import { ApiUrl } from '../../../types/ApiUrl';
import NotificationAlert from '../../../components/Notification';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useActions } from '../../../hooks/useActions';
import { SelectOptionHelper } from '../../../helpers/selectHelper';
import FormInput from '../../../components/FormItems/FormInput';
import FormSelect from '../../../components/FormItems/FormSelect';
import FormDatePicker from '../../../components/FormItems/FormDatePicker';
import moment from 'moment';

export const User: React.FC = () => {
  const { roles } = useTypedSelector((state) => state.role);

  const { request } = useHttp();
  const { id } = useParams();

  const { fetchRoles } = useActions();

  const roleOptions = SelectOptionHelper(roles);

  const [form] = Form.useForm();

  const getData = useCallback(async () => {
    if (id) {
      try {
        const fetchUser = await request({
          url: `${ApiUrl.USER}/${id}`,
          method: 'get',
        });

        const roleOption = fetchUser.data.roles.map((item: any) => {
          return item.id;
        });

        const status = statusUserOptions.find((option) => {
          return option.value === fetchUser.data.status.toString();
        });

        if (fetchUser.data.firstName !== 'null') {
          form.setFieldValue('firstName', fetchUser.data.firstName);
        }
        if (fetchUser.data.lastName !== 'null') {
          form.setFieldValue('lastName', fetchUser.data.lastName);
        }
        if (fetchUser.data.middleName !== 'null') {
          form.setFieldValue('middleName', fetchUser.data.middleName);
        }

        form.setFieldValue('email', fetchUser.data.email);
        form.setFieldValue('phone', fetchUser.data.phone);
        form.setFieldValue('status', status);

        if (fetchUser.data.birthDate !== null) {
          form.setFieldValue('birthDate', moment(fetchUser.data.birthDate));
        } else {
          form.setFieldValue('birthDate', undefined);
        }

        form.setFieldValue('roles', roleOption);

        if (fetchUser.data.emailConfirm === true) {
          form.setFieldValue('emailConfirm', '1');
        } else {
          form.setFieldValue('emailConfirm', '0');
        }
      } catch (e) {}
    }
  }, [request]);

  useEffect(() => {
    fetchRoles();
    getData();
  }, [getData]);

  const upload = async (e: any) => {
    try {
      if (e.birthDate === undefined) {
        delete e.birthDate;
      } else if (e.birthDate === null) {
        e.birthDate = null;
      } else {
        e.birthDate = e.birthDate.toISOString();
      }

      e.status = Number(e.status.value);

      if (form.getFieldValue('emailConfirm') === '1') {
        e.emailConfirm = 'true';
      } else {
        e.emailConfirm = 'false';
      }

      if (e.roles.length) {
        e.roles = e.roles.join(',');
      }

      const requestObject: UseHttpI = {
        url: `${ApiUrl.USER}/${id}`,
        method: 'put',
        body: e,
      };

      const response = await request(requestObject);

      switch (response.config.method) {
        case 'put':
          return NotificationAlert('success', 'Пользователь обновлен');
        default:
          return NotificationAlert('error', 'Упс');
      }
    } catch (err: any) {
      if (err.response) {
        return NotificationAlert('error', err.response.data?.message || err.message);
      }
    }
  };

  return (
    <div className={styles.main}>
      <h1>Редактирование пользователя</h1>
      <Form form={form} onFinish={upload} labelCol={{ span: 6 }}>
        <Card
          style={{ marginTop: 16, textAlign: 'left' }}
          type="inner"
          title="Общие фильтры"
        >
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={8}>
              <FormInput
                name={'lastName'}
                label={'Фамилия'}
                placeholder={'Фамилия'}
                labelCol={{ span: 24 }}
              />
            </Col>
            <Col span={8}>
              <FormInput
                name={'firstName'}
                label={'Имя'}
                placeholder={'Имя'}
                labelCol={{ span: 24 }}
              />
            </Col>
            <Col span={8}>
              <FormInput
                name={'middleName'}
                label={'Отчество'}
                placeholder={'Отчество'}
                labelCol={{ span: 24 }}
              />
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={8}>
              <FormInput
                name={'email'}
                label={'Email'}
                placeholder={'Email'}
                labelCol={{ span: 24 }}
              />
            </Col>
            <Col span={8}>
              <FormInput
                name={'phone'}
                label={'Телефон'}
                placeholder={'Телефон'}
                labelCol={{ span: 24 }}
              />
            </Col>
            <Col span={8}>
              <FormDatePicker
                name={'birthDate'}
                label={'День Рождения'}
                placeholder={'День Рождения'}
                labelCol={{ span: 24 }}
                value={form.getFieldValue('birthDate')}
              />
            </Col>
          </Row>
          <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
            <Col span={8}>
              <FormSelect
                name={'status'}
                label={'Статус'}
                placeholder={'Статус'}
                options={statusUserOptions}
                labelCol={{ span: 24 }}
                showSearch={true}
              />
            </Col>
            <Col span={8}>
              <FormSelect
                name={'emailConfirm'}
                label={'Email подтвержден'}
                placeholder={'Email подтвержден'}
                options={emailConfirmUserOptions}
                showSearch={true}
                labelCol={{ span: 24 }}
              />
            </Col>
            <Col span={8}>
              <FormSelect
                name={'roles'}
                label={'Роль'}
                placeholder={'Выберите роль'}
                options={roleOptions}
                showSearch={true}
                labelCol={{ span: 24 }}
                mode={'multiple'}
              />
            </Col>
          </Row>
        </Card>
        <Button
          type="primary"
          htmlType="submit"
          style={{
            backgroundColor: '#428bca',
            color: '#fff',
            cursor: 'pointer',
            marginTop: 15,
          }}
        >
          Сохранить пользователя
        </Button>
      </Form>
    </div>
  );
};
