import { Dispatch } from 'redux';

import { $api } from '../../../http';
import { ApiUrl } from '../../../types/ApiUrl';
import {
  GeoRegionAction,
  GeoRegionActionTypes,
} from '../../../types/entities/Geo/GeoRegion';

interface queryGeoRegionParamsInterface {
  limit?: any;
  page?: any;
  sortId?: string;
  id?: string;
  name?: string;
  status?: string;
}

export const fetchGeoRegionsForSelect = () => {
  return async (dispatch: Dispatch<GeoRegionAction>) => {
    try {
      dispatch({ type: GeoRegionActionTypes.FETCH_GEO_REGION });
      const response = await $api.get(`${ApiUrl.GEO_REGION_SELECT}`);
      dispatch({
        type: GeoRegionActionTypes.FETCH_GEO_REGIONS_SELECT,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: GeoRegionActionTypes.FETCH_GEO_REGIONS_ERROR,
        payload: 'Произошла ошибка при загрузке регионов',
      });
    }
  };
};

export const fetchGeoRegionsList = (
  queryParams: queryGeoRegionParamsInterface,
) => {
  const {
    limit = '25',
    page = 1,
    sortId = 'DESC',
    id,
    name,
    status,
  } = queryParams;

  const urlGeoRegionParams = new URLSearchParams();
  urlGeoRegionParams.append('limit', limit);
  urlGeoRegionParams.append('page', page);
  urlGeoRegionParams.append('sortId', sortId);

  if (id !== undefined) urlGeoRegionParams.append('id', id);
  if (name !== undefined) urlGeoRegionParams.append('name', name);
  if (status !== undefined) urlGeoRegionParams.append('status', status);

  return async (dispatch: Dispatch<GeoRegionAction>) => {
    try {
      dispatch({ type: GeoRegionActionTypes.FETCH_GEO_REGION });
      const response = await $api.get(
        `${ApiUrl.GEO_REGION}?${urlGeoRegionParams.toString()}`,
      );
      dispatch({
        type: GeoRegionActionTypes.FETCH_GEO_REGIONS,
        payload: response.data.items,
      });
      dispatch({
        type: GeoRegionActionTypes.SET_TOTAL_GEO_REGION_PAGE,
        payload: response.data.total,
      });
      dispatch({
        type: GeoRegionActionTypes.SET_GEO_REGION_PAGE,
        payload: page,
      });
    } catch (e) {
      dispatch({
        type: GeoRegionActionTypes.FETCH_GEO_REGIONS_ERROR,
        payload: 'Произошла ошибка при загрузке регионов',
      });
    }
  };
};
