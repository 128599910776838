import { Dispatch } from 'redux';

import { $api } from '../../http';
import { ApiUrl } from '../../types/ApiUrl';
import { NewsAction, NewsActionTypes } from '../../types/entities/News';

interface queryNewsParamsInterface {
  limit?: any;
  page?: any;
  sortId?: string;
  id?: string;
  title?: string;
  slug?: string;
  status?: string;
}

export const fetchNews = (queryParams: queryNewsParamsInterface) => {
  const {
    limit = '25',
    page = 1,
    sortId = 'DESC',
    id,
    title,
    slug,
    status,
  } = queryParams;

  const urlNewsParams = new URLSearchParams();
  urlNewsParams.append('limit', limit);
  urlNewsParams.append('page', page);
  urlNewsParams.append('sortId', sortId);
  if (id !== undefined) urlNewsParams.append('id', id);
  if (title !== undefined) urlNewsParams.append('title', title);
  if (slug !== undefined) urlNewsParams.append('slug', slug);
  if (status !== undefined) urlNewsParams.append('status', status);

  return async (dispatch: Dispatch<NewsAction>) => {
    try {
      dispatch({ type: NewsActionTypes.FETCH_ONE_NEWS });
      const response = await $api.get(
        `${ApiUrl.NEWS}?${urlNewsParams.toString()}`,
      );
      dispatch({
        type: NewsActionTypes.FETCH_MANY_NEWS,
        payload: response.data.items,
      });
      dispatch({
        type: NewsActionTypes.SET_TOTAL_NEW_PAGE,
        payload: response.data.total,
      });
      dispatch({ type: NewsActionTypes.SET_NEW_PAGE, payload: page });
    } catch (e) {
      dispatch({
        type: NewsActionTypes.FETCH_NEWS_ERROR,
        payload: 'Произошла ошибка при загрузке новостей',
      });
    }
  };
};
