import { Dispatch } from 'redux';

import { $api } from '../../http';
import { ApiUrl } from '../../types/ApiUrl';
import { PageAction, PageActionTypes } from '../../types/entities/Page';

interface queryPageParamsInterface {
  limit?: any;
  page?: any;
  sortId?: string;
  id?: string;
  title?: string;
  url?: string;
  status?: string;
}

export const fetchPages = (queryParams: queryPageParamsInterface) => {
  const {
    limit = '25',
    page = 1,
    sortId = 'DESC',
    id,
    title,
    url,
    status,
  } = queryParams;

  const urlPageParams = new URLSearchParams();
  urlPageParams.append('limit', limit);
  urlPageParams.append('page', page);
  urlPageParams.append('sortId', sortId);
  if (id !== undefined) urlPageParams.append('id', id);
  if (title !== undefined) urlPageParams.append('title', title);
  if (url !== undefined) urlPageParams.append('url', url);
  if (status !== undefined) urlPageParams.append('status', status);

  return async (dispatch: Dispatch<PageAction>) => {
    try {
      dispatch({ type: PageActionTypes.FETCH_PAGE });
      const response = await $api.get(
        `${ApiUrl.PAGE}?${urlPageParams.toString()}`,
      );
      dispatch({
        type: PageActionTypes.FETCH_PAGES,
        payload: response.data.items,
      });
      dispatch({
        type: PageActionTypes.SET_TOTAL_PAGE_PAGE,
        payload: response.data.total,
      });
      dispatch({ type: PageActionTypes.SET_PAGE_PAGE, payload: page });
    } catch (e) {
      dispatch({
        type: PageActionTypes.FETCH_PAGES_ERROR,
        payload: 'Произошла ошибка при загрузке страниц',
      });
    }
  };
};
