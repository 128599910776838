import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Pagination, Row, Table } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import styles from '../../Categories/CategoriesList/CategoriesList.module.scss';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useActions } from '../../../hooks/useActions';
import { SelectOptionHelper } from '../../../helpers/selectHelper';
import { typeAttributeOptions } from '../../../helpers/attributeHelper';
import FormInput from '../../../components/FormItems/FormInput';
import FormSelect from '../../../components/FormItems/FormSelect';

export const AttributesList: React.FC = () => {
  const { attributes, limit, currentPage, totalAttributes } = useTypedSelector(
    (state) => state.attribute,
  );
  const { attributeGroups } = useTypedSelector((state) => state.attributeGroup);
  const { types } = useTypedSelector((state) => state.type);

  const [sort, setSort] = useState('DESC');

  const { fetchAttributes, fetchAttributeGroupsForSelect, fetchTypes } =
    useActions();

  const attributeGroupsOptions = SelectOptionHelper(attributeGroups);
  const typeProductOptions = SelectOptionHelper(types);

  const [form] = Form.useForm();

  useEffect(() => {
    fetchAttributes({
      limit: limit,
      page: currentPage,
      sortId: sort,
      title: form.getFieldValue('title'),
      type: form.getFieldValue('type'),
      group: form.getFieldValue('group'),
      typeProduct: form.getFieldValue('typeProduct'),
    });
    fetchAttributeGroupsForSelect();
    fetchTypes();
  }, []);

  const onShowSizeChange = (currentPage: number, limit: number) => {
    fetchAttributes({
      limit: limit,
      page: currentPage,
      sortId: sort,
      title: form.getFieldValue('title'),
      type: form.getFieldValue('type'),
      group: form.getFieldValue('group'),
      typeProduct: form.getFieldValue('typeProduct'),
    });
  };
  const handlePagination = (currentPage: number, limit: number) => {
    fetchAttributes({
      limit: limit,
      page: currentPage,
      sortId: sort,
      title: form.getFieldValue('title'),
      type: form.getFieldValue('type'),
      group: form.getFieldValue('group'),
      typeProduct: form.getFieldValue('typeProduct'),
    });
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.field === 'id') {
      if (sorter.order === 'ascend') {
        setSort('ASC');
        fetchAttributes({ limit: limit, page: currentPage, sortId: 'ASC' });
      } else {
        setSort('DESC');
        fetchAttributes({ limit: limit, page: currentPage, sortId: 'DESC' });
      }
    }
  };

  const applyFilters = () => {
    fetchAttributes({
      limit: limit,
      page: 1,
      sortId: sort,
      title: form.getFieldValue('title'),
      type: form.getFieldValue('type'),
      group: form.getFieldValue('group'),
      typeProduct: form.getFieldValue('typeProduct'),
    });
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => {
        return (
          <div key={id}>
            <Link to={`/attributes/attributes/update/${id}`}>{id}</Link>
          </div>
        );
      },
      sorter: true,
    },
    {
      title: 'Title',
      dataIndex: 'title',
      key: 'title',
      render: (title: string) => {
        if (title) {
          return <>{title}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Type',
      dataIndex: 'type',
      key: 'type',
      render: (type: any) => {
        switch (type) {
          case 0:
            return <>{typeAttributeOptions[0].label}</>;
          case 1:
            return <>{typeAttributeOptions[1].label}</>;
          case 2:
            return <>{typeAttributeOptions[2].label}</>;
          case 3:
            return <>{typeAttributeOptions[3].label}</>;
          case 4:
            return <>{typeAttributeOptions[4].label}</>;
          case 6:
            return <>{typeAttributeOptions[5].label}</>;
          default:
            return 'Не указано';
        }
      },
    },
    {
      title: 'Тип товара',
      key: 'typeProduct',
      render: (data: any) => {
        if (data.typeProduct) {
          return data.typeProduct.map((item: any, index: any) => {
            return (
              <span key={item.id}>
                {item.name}
                {index === data.typeProduct.length - 1 ? '' : ', '}
              </span>
            );
          });
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Группа атрибутов',
      key: 'group',
      render: (data: any) => {
        if (data.group) {
          return <>{data.group.name}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Is Filter',
      dataIndex: 'isFilter',
      key: 'isFilter',
      render: (isFilter: number) => {
        if (isFilter === 1) {
          return <>Да</>;
        } else {
          return <>Нет</>;
        }
      },
    },
    {
      title: 'Выгружать в 1с',
      dataIndex: 'onec',
      key: 'onec',
      render: (onec: number) => {
        if (onec === 1) {
          return <>Да</>;
        } else {
          return <>Нет</>;
        }
      },
    },
    {
      title: 'Sort',
      dataIndex: 'sort',
      key: 'sort',
      render: (sort: string) => {
        if (sort) {
          return <>{sort}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Sort Filters',
      dataIndex: 'sortFilters',
      key: 'sortFilters',
      render: (sortFilters: string) => {
        if (sortFilters) {
          return <>{sortFilters}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Действия с атрибутом',
      dataIndex: 'id',
      key: 'edit',
      render: (id: string) => {
        return (
          <>
            <Link to={`/attributes/attributes/update/${id}`}>
              <EditOutlined style={{ marginLeft: 50 }} />
            </Link>
          </>
        );
      },
    },
  ];

  return (
    <div className={styles.main}>
      <h1>Список атрибутов</h1>
      <h4 style={{ textAlign: 'left' }}>Фильтры полей</h4>
      <Form form={form}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={8}>
            <FormInput
              name={'title'}
              label={'Название атрибута'}
              placeholder={'Выберите название атрибута'}
              search={true}
              onSearch={(value) => {
                fetchAttributes({
                  limit: limit,
                  page: currentPage,
                  sortId: sort,
                  title: value,
                });
              }}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={8}>
            <FormSelect
              name={'type'}
              label={'Type'}
              placeholder={'Выберите тип'}
              options={typeAttributeOptions}
              showSearch={true}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={8}>
            <FormSelect
              name={'group'}
              label={'Группа атрибутов'}
              placeholder={'Выберите группу'}
              options={attributeGroupsOptions}
              showSearch={true}
              labelCol={{ span: 24 }}
            />
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={8}>
            <FormSelect
              name={'typeProduct'}
              label={'Тип товара'}
              placeholder={'Выберите тип товара'}
              options={typeProductOptions}
              showSearch={true}
              labelCol={{ span: 24 }}
            />
          </Col>
        </Row>
        <Button
          style={{ marginBottom: 20 }}
          type="primary"
          htmlType="submit"
          onClick={applyFilters}
        >
          Применить фильтры
        </Button>
        <Button
          style={{ marginLeft: 20 }}
          type="ghost"
          htmlType="reset"
          onClick={() => {
            return fetchAttributes({
              limit: limit,
              page: 1,
              sortId: sort,
            });
          }}
        >
          Очистить фильтры
        </Button>
      </Form>
      <Pagination
        style={{ marginBottom: 20 }}
        total={totalAttributes}
        current={currentPage}
        showQuickJumper={true}
        onChange={handlePagination}
        onShowSizeChange={onShowSizeChange}
        pageSizeOptions={[10, 25, 50, 100]}
        defaultPageSize={25}
      />
      <Table
        bordered
        columns={columns}
        dataSource={attributes}
        pagination={false}
        onChange={handleChange}
        rowKey="id"
      />
    </div>
  );
};
