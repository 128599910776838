import React from 'react';
import { Link, Route } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import { Dashboard } from '../../Pages/Dashboard/Dashboard';
import { ComponentsLayout } from '../../layout/ComponentsLayout/ComponentsLayout';

export const DashboardRoutes = (
  <>
    <Route
      path="/dashboard"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <Dashboard />
          </ComponentsLayout>
        </>
      }
    />
  </>
);
