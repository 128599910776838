import React, { useEffect, useState } from 'react';
import { Button, Form, Input, Row } from 'antd';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';

import { useHttp, UseHttpI } from '../../hooks/useHttp';

interface SortEditorProps {
  sort: number;
  id: number;
  setEdit: any;
  url: string;
}

export const SortEditor: React.FC<SortEditorProps> = ({
  sort,
  id,
  setEdit,
  url,
}): JSX.Element => {
  const [editSortField, setEditSortField] = useState(false);
  const [form] = Form.useForm();
  const { request } = useHttp();

  useEffect(() => {
    form.setFieldsValue({
      sort: sort,
    });
  }, []);

  const onFinish = async (e: any) => {
    try {
      const requestObject: UseHttpI = {
        url: `${url}/${id}`,
        method: 'put',
        body: e,
      };

      await request(requestObject);
      setEditSortField(false);
      setEdit((edit: any) => !edit);
    } catch (e) {}
  };

  const renderSwitch = () => {
    switch (editSortField) {
      case true:
        return (
          <>
            <Row>
              <Form.Item name="sort" valuePropName="value">
                <Input placeholder="Сортировка" />
              </Form.Item>
            </Row>
            <Row style={{ justifyContent: 'space-between', marginTop: 10 }}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                />
              </Form.Item>
              <Button
                type="primary"
                danger
                icon={<CloseOutlined />}
                onClick={() =>
                  setEditSortField((editSortField) => !editSortField)
                }
              />
            </Row>
          </>
        );
      //
      case false:
        return (
          <Button
            type="link"
            onClick={() => setEditSortField((editSortField) => !editSortField)}
          >
            {sort ? sort : 'Не указано'}
          </Button>
        );
    }
  };

  return (
    <Form form={form} onFinish={onFinish}>
      {renderSwitch()}
    </Form>
  );
};
