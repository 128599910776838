import { IUser } from './User';

export interface IRole {
  id: number;
  value: string;
  description: string | null;
  users: IUser[];
}

export interface RoleState {
  roles: IRole[];
  error: string | null;
  loading: boolean;
}

export enum RoleActionTypes {
  FETCH_ROLE = 'FETCH_ROLE',
  FETCH_ROLES = 'FETCH_ROLES',
  FETCH_ROLES_ERROR = 'FETCH_ROLES_ERROR',
}

interface FetchRoleAction {
  type: RoleActionTypes.FETCH_ROLE;
}

interface FetchRolesAction {
  type: RoleActionTypes.FETCH_ROLES;
  payload: IRole[];
}

interface FetchRolesErrorAction {
  type: RoleActionTypes.FETCH_ROLES_ERROR;
  payload: string;
}

export type RoleAction =
  | FetchRoleAction
  | FetchRolesAction
  | FetchRolesErrorAction;
