import React, { useCallback, useEffect, useState } from "react";
import { Alert, Button, Card, Col, Form, Popconfirm, Row, Table } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";

import styles from "./ProductSliderGroup.module.scss";
import { useHttp, UseHttpI } from "../../../hooks/useHttp";
import { IProductSliderGroup } from "../../../types/entities/ProductSliderGroup";
import { ApiUrl } from "../../../types/ApiUrl";
import NotificationAlert from "../../../components/Notification";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useActions } from "../../../hooks/useActions";
import { SelectOptionHelper, SelectOptionUserHelper } from "../../../helpers/selectHelper";
import { DebounceSelect } from "../../../components/DebounceSelect";
import { GeoZoneEditor } from "../../../components/Editors/GeoZoneEditor";
import { SortEditor } from "../../../components/Editors/SortEditor";
import FormInput from "../../../components/FormItems/FormInput";
import FormSelect from "../../../components/FormItems/FormSelect";
import { useTrimField } from "../../../hooks/useTrimField";

interface ProductSliderGroupProps {
  titlePage: string;
  submitMethod: string;
}

interface ProductValue {
  label: string;
  value: string;
}

export const ProductSliderGroup: React.FC<ProductSliderGroupProps> = ({
                                                                        titlePage,
                                                                        submitMethod
                                                                      }) => {
  const { users } = useTypedSelector((state) => state.user);
  const { geoZones } = useTypedSelector((state) => state.geoZone);
  const { user } = useTypedSelector((state) => state.auth);

  const navigate = useNavigate();

  const { id } = useParams();
  const { request } = useHttp();
  const [productSliderGroup, setProductSliderGroup] =
    useState<IProductSliderGroup>();
  const [editGeo, setEditGeo] = useState(false);
  const [editSort, setEditSort] = useState(false);
  const [product, setProduct] = useState<ProductValue[]>([]);

  const { fetchUsersForSelect, fetchGeoZonesForSelect } = useActions();

  const userOptions = SelectOptionUserHelper(users);
  const geoZoneOptions = SelectOptionHelper(geoZones);

  const getData = useCallback(async () => {
    if (id) {
      try {
        const fetchProductSliderGroup = await request({
          url: `${ApiUrl.PRODUCT_SLIDER_GROUP}/${id}`,
          method: "get"
        });
        setProductSliderGroup(fetchProductSliderGroup.data);

        form.setFieldsValue({
          name: fetchProductSliderGroup.data?.name,
          code: fetchProductSliderGroup.data?.code,
          description: fetchProductSliderGroup.data?.description,
          createUserId: fetchProductSliderGroup.data?.createUserId
        });
      } catch (e) {
      }
    }
  }, [request]);

  const [form] = Form.useForm();

  const deleteProductSlider = async (id: number) => {
    try {
      await request({
        url: `${ApiUrl.PRODUCT_SLIDER}/${id}`,
        method: "delete"
      });
      await getData();
      return NotificationAlert("success", "Товар удалён");
    } catch (e) {
    }
  };

  const createProductSlider = async (productId: string) => {
    if (product.length !== 0) {
      try {
        await request({
          url: `${ApiUrl.PRODUCT_SLIDER}`,
          method: "post",
          body: {
            groupId: id,
            productId: productId,
            createUserId: user?.id
          }
        });
        setProduct([]);
        await getData();
        return NotificationAlert("success", "Товар добавлен");
      } catch (e) {
      }
    }
  };

  useEffect(() => {
    fetchGeoZonesForSelect();
    fetchUsersForSelect();
    getData();
  }, [getData, editGeo, editSort]);

  const fetchProductList = async (title: string): Promise<ProductValue[]> => {
    const response = await axios.get(`${ApiUrl.PRODUCT_SEARCH}?title=${title}`);
    return response.data.map((element: any) => ({
      value: element.id,
      label: element.title
    }));
  };

  const onFinish = async (e: any) => {
    try {
      useTrimField(form, null, e);

      const requestObject: UseHttpI =
        submitMethod === "add"
          ? { url: ApiUrl.PRODUCT_SLIDER_GROUP, method: "post", body: e }
          : {
            url: `${ApiUrl.PRODUCT_SLIDER_GROUP}/${id}`,
            method: "put",
            body: e
          };

      const response = await request(requestObject);

      switch (response.config.method) {
        case "post":
          navigate("/content/product-slider-group/list");
          return NotificationAlert("success", "Лента продуктов добавлена");
        case "put":
          return NotificationAlert("success", "Лента продуктов обновлена");
        default:
          return NotificationAlert("error", "Упс");
      }
    } catch (err: any) {
      if (err.response) {
        return err.response.data.map((el: any) => {
          return NotificationAlert("error", el);
        });
      } else {
        return NotificationAlert("error", err.message);
      }
    }
  };

  const columns = [
    {
      title: "Название",
      key: "productTitle",
      render: (data: any) => {
        return (
          <div key={data.productId}>
            <Link to={`/products/update/${data.productId}`}>
              {data.product && data.product.title}
            </Link>
          </div>
        );
      }
    },
    {
      title: "Артикул",
      key: "productSku",
      render: (data: any) => {
        return <>{data.product && data.product.sku}</>;
      }
    },
    {
      title: "Геозона",
      key: "geoZoneId",
      render: (data: any) => {
        return (
          <GeoZoneEditor
            geoZoneId={data.geoZoneId}
            id={data.id}
            options={geoZoneOptions}
            setEdit={setEditGeo}
            url={ApiUrl.UPDATE_PRODUCT_SLIDER_GEO_ZONE}
          />
        );
      }
    },
    {
      title: "Сортировка",
      key: "sort",
      render: (data: any) => {
        return (
          <SortEditor
            sort={data.sort}
            id={data.id}
            setEdit={setEditSort}
            url={ApiUrl.UPDATE_PRODUCT_SLIDER_SORT}
          />
        );
      }
    },
    {
      title: "Пользователь",
      key: "createUserId",
      render: (data: any) => {
        if (data.createUser) {
          const user = data.createUser;
          return (
            `${
              user.lastName !== null && user.lastName !== "null"
                ? user.lastName
                : ""
            }` +
            " " +
            `${
              user.firstName !== null && user.firstName !== "null"
                ? user.firstName
                : ""
            }` +
            " " +
            `${
              user.middleName !== null && user.middleName !== "null"
                ? user.middleName
                : ""
            }`
          );
        } else {
          return "---";
        }
      }
    },
    {
      title: "Действия с товаром",
      key: "actions",

      render: (data: any) => {
        return (
          <Button type="link" style={{ color: "inherit" }}>
            <Popconfirm
              title={"Вы точно хотите удалить продукт?"}
              onConfirm={() => deleteProductSlider(data.id)}
            >
              <DeleteOutlined style={{ cursor: "pointer", fontSize: 22 }} />
            </Popconfirm>
          </Button>
        );
      }
    }
  ];

  return (
    <div className={styles.main}>
      <h1>{titlePage}</h1>
      <Form form={form} onFinish={onFinish} labelCol={{ span: 6 }}>
        <FormInput
          name={"name"}
          label={"Название ленты"}
          placeholder={"Название ленты"}
          required={true}
          labelCol={{ span: 24 }}
        />
        <FormInput
          name={"code"}
          label={"Идентификатор ленты"}
          placeholder={"Идентификатор ленты"}
          required={true}
          labelCol={{ span: 24 }}
        />
        <FormInput
          name={"description"}
          label={"Описание"}
          placeholder={"Описание ленты"}
          textArea={true}
          labelCol={{ span: 24 }}
        />
        <FormSelect
          name={"createUserId"}
          label={"Пользователь"}
          placeholder={"Фамилия Имя Отчество"}
          showSearch={true}
          options={userOptions}
          labelCol={{ span: 24 }}
        />
        <Form.Item>
          <Button style={{ marginTop: 16 }} type="primary" htmlType="submit">
            {submitMethod === "add" ? "Добавить ленту" : "Обновить ленту"}
          </Button>
        </Form.Item>
      </Form>
      {submitMethod === "add" ? (
        <Alert
          message="Чтобы добавить товар в ленту, после создания ленты зайдите в её обновление"
          type="info"
        />
      ) : (
        <>
          <Card
            style={{ marginTop: 16, textAlign: "left" }}
            type="inner"
            title="Товары"
          >
            <Table
              columns={columns}
              dataSource={productSliderGroup?.productSliders}
              pagination={false}
              rowKey="id"
            />
            <Row
              gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
              style={{ marginTop: 20 }}
            >
              <Col span={20}>
                <DebounceSelect
                  mode="multiple"
                  value={product}
                  placeholder="Введите артикул или название товара"
                  fetchOptions={fetchProductList}
                  onChange={(newValue) => {
                    setProduct(newValue as ProductValue[]);
                  }}
                  style={{ width: "100%" }}
                />{" "}
              </Col>
              <Col span={4} style={{ padding: 0 }}>
                <Button
                  style={{
                    backgroundColor: "#428bca",
                    color: "#fff",
                    cursor: "pointer"
                  }}
                  disabled={product.length === 0}
                  onClick={() => createProductSlider(product[0].value)}
                >
                  Добавить
                </Button>
              </Col>
            </Row>
          </Card>
          <Alert
            style={{ marginTop: "10px" }}
            message="Если не можете найти товар, то проверьте, указана ли у него категория"
            type="info"
          />
        </>
      )}
    </div>
  );
};
