import { IRole } from './Role';
import { ISliderGalleryImage } from './SliderGalleryImage';
import { ISliderGallery } from './SliderGallery';
import { IImage } from './Image';
import { IProductSlider } from './ProductSlider';
import { IProductSliderGroup } from './ProductSliderGroup';
import { IOrder } from './Order/Order';
import { INews } from './News';

export interface IUser {
  id?: number;
  firstName?: string;
  middleName?: string | null;
  lastName?: string | null;
  nickName?: string | null;
  email?: string;
  password?: string;
  gender?: boolean;
  birthDate?: string | null;
  status?: number;
  accessLevel?: string;
  visitTime?: string | null;
  emailConfirm?: boolean;
  phone?: string;
  phoneConfirm?: boolean;
  roles?: IRole[];
  sliderGalleryImagesUpdate?: ISliderGalleryImage[];
  sliderGalleriesUpdate?: ISliderGallery[];
  imageUpdate?: IImage[];
  productSliderUpdate?: IProductSlider[];
  productSliderGroupUpdate?: IProductSliderGroup[];
  userOrders?: IOrder[];
  managerOrders?: IOrder[];
  news?: INews[];
}

export interface UserState {
  users: IUser[];
  usersList: IUser[];
  error: string | null;
  loading: boolean;
  currentPage: number;
  limit: number;
  totalUsers: number;
}

export enum UserActionTypes {
  FETCH_USER = 'FETCH_USER',
  FETCH_USERS = 'FETCH_USERS',
  FETCH_USERS_ERROR = 'FETCH_USERS_ERROR',
  SET_USER_PAGE = 'SET_USER_PAGE',
  SET_USER_TOTAL_PAGE = 'SET_USER_TOTAL_PAGE',
  FETCH_USERS_SELECT = 'FETCH_USERS_SELECT',
}

interface FetchUserAction {
  type: UserActionTypes.FETCH_USER;
}

interface FetchUsersAction {
  type: UserActionTypes.FETCH_USERS;
  payload: IUser[];
}

interface FetchUsersErrorAction {
  type: UserActionTypes.FETCH_USERS_ERROR;
  payload: string;
}

interface SetUserPageAction {
  type: UserActionTypes.SET_USER_PAGE;
  payload: number;
}

interface SetTotalUserPageAction {
  type: UserActionTypes.SET_USER_TOTAL_PAGE;
  payload: number;
}

interface FetchUsersSelectAction {
  type: UserActionTypes.FETCH_USERS_SELECT;
  payload: IUser[];
}

export type UserAction =
  | FetchUserAction
  | FetchUsersAction
  | FetchUsersErrorAction
  | SetUserPageAction
  | SetTotalUserPageAction
  | FetchUsersSelectAction;
