import { Form } from 'antd';
import React from 'react';
import { DatePicker } from 'antd/es';
import { Moment } from 'moment';

interface IFormDatePicker {
  name: string;
  label: string;
  placeholder: string;
  labelCol?: { span: number };
  showTime?: boolean;
  value: Moment;
}

const FormDatePicker: React.FC<IFormDatePicker> = ({
  name,
  label,
  placeholder,
  labelCol,
  showTime,
}) => {
  const settings = {
    name,
    label,
    valuePropName: 'value',
    labelCol,
  };

  return (
    <Form.Item {...settings}>
      <DatePicker placeholder={placeholder} showTime={showTime} />
    </Form.Item>
  );
};

export default FormDatePicker;
