import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Pagination, Row, Table } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import styles from './PagesList.module.scss';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useActions } from '../../../hooks/useActions';
import { statusPageOptions } from '../../../helpers/pageHelper';
import { StatusEditor } from '../../../components/Editors/StatusEditor';
import { ApiUrl } from '../../../types/ApiUrl';
import FormInput from '../../../components/FormItems/FormInput';
import FormSelect from '../../../components/FormItems/FormSelect';

export const PagesList: React.FC = () => {
  const { pages, limit, currentPage, totalPages } = useTypedSelector(
    (state) => state.page,
  );

  const [sort, setSort] = useState('DESC');
  const [edit, setEdit] = useState(false);

  const { fetchPages } = useActions();

  const [form] = Form.useForm();

  useEffect(() => {
    fetchPages({
      limit: limit,
      page: currentPage,
      sortId: sort,
      id: form.getFieldValue('id'),
      title: form.getFieldValue('title'),
      url: form.getFieldValue('url'),
      status: form.getFieldValue('status'),
    });
  }, [edit]);

  const onShowSizeChange = (currentPage: number, limit: number) => {
    fetchPages({
      limit: limit,
      page: currentPage,
      sortId: sort,
      id: form.getFieldValue('id'),
      title: form.getFieldValue('title'),
      url: form.getFieldValue('url'),
      status: form.getFieldValue('status'),
    });
  };

  const handlePagination = (currentPage: number, limit: number) => {
    fetchPages({
      limit: limit,
      page: currentPage,
      sortId: sort,
      id: form.getFieldValue('id'),
      title: form.getFieldValue('title'),
      url: form.getFieldValue('url'),
      status: form.getFieldValue('status'),
    });
  };

  const applyFilters = () => {
    fetchPages({
      limit: limit,
      page: 1,
      sortId: sort,
      id: form.getFieldValue('id'),
      title: form.getFieldValue('title'),
      url: form.getFieldValue('url'),
      status: form.getFieldValue('status'),
    });
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.field === 'id') {
      if (sorter.order === 'ascend') {
        setSort('ASC');
        fetchPages({ limit: limit, page: currentPage, sortId: 'ASC' });
      } else {
        setSort('DESC');
        fetchPages({ limit: limit, page: currentPage, sortId: 'DESC' });
      }
    }
  };

  const columns = [
    {
      title: 'ID страницы',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => {
        return (
          <div key={id}>
            <Link to={`/content/pages/update/${id}`}>{id}</Link>
          </div>
        );
      },
      sorter: true,
    },
    {
      title: 'Заголовок',
      dataIndex: 'title',
      key: 'title',
      render: (title: string) => {
        if (title) {
          return <>{title}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'URL',
      dataIndex: 'url',
      key: 'url',
      render: (url: string) => {
        if (url) {
          return <>{url}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Статус',
      key: 'status',
      render: (data: any) => {
        return (
          <StatusEditor
            status={data.status}
            id={data.id}
            options={statusPageOptions}
            setEdit={setEdit}
            url={ApiUrl.UPDATE_PAGE_STATUS}
          />
        );
      },
    },
    {
      title: 'Действия со страницей',
      dataIndex: 'id',
      key: 'edit',
      render: (id: string) => {
        return (
          <>
            <Link to={`/content/pages/update/${id}`}>
              <EditOutlined style={{ marginLeft: 50 }} />
            </Link>
          </>
        );
      },
    },
  ];

  return (
    <div className={styles.main}>
      <h1>Список страниц</h1>
      <h4 style={{ textAlign: 'left' }}>Фильтры полей</h4>
      <Form form={form}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={8}>
            <FormInput
              name={'id'}
              label={'ID'}
              placeholder={'Введите ID'}
              search={true}
              onSearch={(value) => {
                fetchPages({
                  limit: limit,
                  page: currentPage,
                  sortId: sort,
                  id: value,
                });
              }}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={8}>
            <FormInput
              name={'title'}
              label={'Заголовок'}
              placeholder={'Введите заголовок'}
              search={true}
              onSearch={(value) => {
                fetchPages({
                  limit: limit,
                  page: currentPage,
                  sortId: sort,
                  title: value,
                });
              }}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={8}>
            <FormInput
              name={'url'}
              label={'URL'}
              placeholder={'Введите url'}
              search={true}
              onSearch={(value) => {
                fetchPages({
                  limit: limit,
                  page: currentPage,
                  sortId: sort,
                  url: value,
                });
              }}
              labelCol={{ span: 24 }}
            />
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={8}>
            <FormSelect
              name={'status'}
              label={'Статус'}
              placeholder={'Выберите статус'}
              options={statusPageOptions}
              showSearch={true}
              labelCol={{ span: 24 }}
            />
          </Col>
        </Row>
        <Button
          style={{ marginBottom: 20 }}
          type="primary"
          htmlType="submit"
          onClick={applyFilters}
        >
          Применить фильтры
        </Button>
        <Button
          style={{ marginLeft: 20 }}
          type="ghost"
          htmlType="reset"
          onClick={() => {
            return fetchPages({
              limit: limit,
              page: 1,
              sortId: sort,
            });
          }}
        >
          Очистить фильтры
        </Button>
      </Form>
      <Pagination
        style={{ marginBottom: 20 }}
        total={totalPages}
        current={currentPage}
        showQuickJumper={true}
        onChange={handlePagination}
        onShowSizeChange={onShowSizeChange}
        pageSizeOptions={[10, 25, 50, 100]}
        defaultPageSize={25}
      />
      <Table
        bordered
        columns={columns}
        dataSource={pages}
        pagination={false}
        onChange={handleChange}
        rowKey="id"
      />
    </div>
  );
};
