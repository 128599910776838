import React from 'react';
import { Link, Route } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import { UpdateCategory } from '../../Pages/Categories/Category/UpdateCategory/UpdateCategory';
import { AddCategory } from '../../Pages/Categories/Category/AddCategory/AddCategory';
import { CategoriesList } from '../../Pages/Categories/CategoriesList/CategoriesList';
import { ComponentsLayout } from '../../layout/ComponentsLayout/ComponentsLayout';

export const CategoryRoutes = (
  <>
    <Route
      path="/categories/add"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Добавить категорию</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <AddCategory />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/categories/update/:id"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/categories/list">Список категорий</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Обновление категории</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <UpdateCategory />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/categories/list"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Список категорий</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <CategoriesList />
          </ComponentsLayout>
        </>
      }
    />
  </>
);
