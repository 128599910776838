export const statusOrderOptions = [
  { value: 1, label: "Новый" },
  { value: 2, label: "В пути" },
  { value: 3, label: "Выполнен" },
  { value: 4, label: "Отменен" },
  { value: 6, label: "Готов к выдаче" },
  { value: 7, label: "Архив" },
  { value: 8, label: "Проверка остатков" },
  { value: 9, label: "Остатки подтверждены" },
  { value: 10, label: "Одобрение кредита" },
  { value: 11, label: "Обработка" },
  { value: 12, label: "В процессе оплаты" },
  { value: 13, label: "temp" },
  { value: 14, label: "Отказ в кредите" }
];

export const managerOrderOptions = [
  { value: 4145, label: "Любовь Сазонова" },
  { value: 4146, label: "Елена Шарикова" }
];
