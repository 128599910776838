import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Pagination, Row, Table } from 'antd';
import { EditOutlined, EyeOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useActions } from '../../../hooks/useActions';
import { productConditionOptions } from '../../../helpers/productHelper';
import styles from './ProductList.module.scss';
import { createTree, SelectOptionHelper } from '../../../helpers/selectHelper';
import { StatusEditor } from '../../../components/Editors/StatusEditor';
import { ApiUrl } from '../../../types/ApiUrl';
import FormInput from '../../../components/FormItems/FormInput';
import FormSelect from '../../../components/FormItems/FormSelect';
import FormTreeSelect from '../../../components/FormItems/FormTreeSelect';
import { $api } from '../../../http';

export const ProductList: React.FC = () => {
  const { products, limit, currentPage, totalProducts } = useTypedSelector(
    (state) => state.product,
  );
  const { producers } = useTypedSelector((state) => state.producer);
  const { categories } = useTypedSelector((state) => state.category);

  const [sort, setSort] = useState('nosort');
  const [edit, setEdit] = useState(false);

  const [deliveryPoints, setDeliveryPoints] = useState([]);

  const categoryTree = createTree(categories);

  const { fetchProducts, fetchCategoriesForSelect, fetchProducers } =
    useActions();

  const producerOptions = SelectOptionHelper(producers);

  const [form] = Form.useForm();

  const fetchDeliveryPoints = async () => {
    const response = await $api.get(`${ApiUrl.SHOP}/select`);
    setDeliveryPoints(response.data);
  };

  useEffect(() => {
    let categories;
    if (Array.isArray(form.getFieldValue('category'))) {
      categories = form.getFieldValue('category').join(',');
    }
    fetchProducts({
      limit: limit,
      page: currentPage,
      sortId: sort,
      id: form.getFieldValue('id'),
      title: form.getFieldValue('title'),
      sku: form.getFieldValue('sku'),
      producer: form.getFieldValue('producer'),
      categories: categories,
      condition: form.getFieldValue('condition'),
      price: form.getFieldValue('price'),
      pointId: form.getFieldValue('pointId'),
    });
    fetchCategoriesForSelect();
    fetchProducers({ limit: 0 });
    fetchDeliveryPoints();
  }, [edit]);

  const onShowSizeChange = (currentPage: number, limit: number) => {
    let categories;
    if (Array.isArray(form.getFieldValue('category'))) {
      categories = form.getFieldValue('category').join(',');
    }
    fetchProducts({
      limit: limit,
      page: currentPage,
      sortId: sort,
      id: form.getFieldValue('id'),
      title: form.getFieldValue('title'),
      sku: form.getFieldValue('sku'),
      producer: form.getFieldValue('producer'),
      categories: categories,
      condition: form.getFieldValue('condition'),
      price: form.getFieldValue('price'),
      pointId: form.getFieldValue('pointId'),
    });
  };

  const handlePagination = (currentPage: number, limit: number) => {
    let categories;
    if (Array.isArray(form.getFieldValue('category'))) {
      categories = form.getFieldValue('category').join(',');
    }
    fetchProducts({
      limit: limit,
      page: currentPage,
      sortId: sort,
      id: form.getFieldValue('id'),
      title: form.getFieldValue('title'),
      sku: form.getFieldValue('sku'),
      producer: form.getFieldValue('producer'),
      categories: categories,
      condition: form.getFieldValue('condition'),
      price: form.getFieldValue('price'),
      pointId: form.getFieldValue('pointId'),
    });
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.field === 'id') {
      if (sorter.order === 'ascend') {
        setSort('ASC');
        fetchProducts({ limit: limit, page: currentPage, sortId: 'ASC' });
      } else if (sorter.order === 'descend') {
        setSort('DESC');
        fetchProducts({ limit: limit, page: currentPage, sortId: 'DESC' });
      } else {
        setSort('nosort');
        fetchProducts({ limit: limit, page: currentPage, sortId: 'nosort' });
      }
    }
  };

  const applyFilters = () => {
    let categories;
    if (Array.isArray(form.getFieldValue('category'))) {
      categories = form.getFieldValue('category').join(',');
    }

    fetchProducts({
      limit: limit,
      page: 1,
      sortId: sort,
      id: form.getFieldValue('id'),
      title: form.getFieldValue('title'),
      sku: form.getFieldValue('sku'),
      producer: form.getFieldValue('producer'),
      categories: categories,
      condition: form.getFieldValue('condition'),
      price: form.getFieldValue('price'),
      pointId: form.getFieldValue('pointId'),
    });
  };

  const columns = [
    {
      title: 'ID товара',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => {
        return (
          <div key={id}>
            <Link to={`/products/update/${id}`}>{id}</Link>
          </div>
        );
      },
      sorter: true,
    },
    {
      title: 'Название товара',
      dataIndex: 'title',
      key: 'title',
      render: (title: string) => {
        if (title) {
          return <>{title}</>;
        } else {
          return 'null';
        }
      },
    },
    {
      title: 'Артикул',
      dataIndex: 'sku',
      key: 'sku',
      render: (sku: number) => {
        if (sku) {
          return <>{sku}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Производитель товара',
      dataIndex: ['producer', 'name'],
      key: 'producer',
      render: (producer: string) => {
        if (producer) {
          return <>{producer}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Категория товара',
      dataIndex: 'categories',
      key: 'categories',
      render: (categories: any) => {
        if (categories.length > 0) {
          return categories.map((item: any, index: any) => {
            return (
              <span key={item.id}>
                {item.name}
                {index === categories.length - 1 ? '' : ', '}
              </span>
            );
          });
        } else {
          return '---';
        }
      },
    },
    {
      title: 'Состояние',
      key: 'condition',
      render: (data: any) => {
        return (
          <StatusEditor
            status={data.condition}
            id={data.id}
            options={productConditionOptions}
            setEdit={setEdit}
            url={ApiUrl.UPDATE_PRODUCT_STATUS}
          />
        );
      },
    },
    {
      title: 'Действия с товаром',
      dataIndex: 'id',
      key: 'edit',
      render: (id: string) => {
        // console.log(process.env.NODE_ENV);
        // const url = process.env.NODE_ENV === 'development' ? :
        return (
          <div className="d-flex justify-content-around">
            <a
              href={`https://set-tehniki.com/store/${id}.html`}
              target="_blank"
            >
              <EyeOutlined />{' '}
            </a>
            <Link to={`/products/update/${id}`}>
              <EditOutlined />
            </Link>
          </div>
        );
      },
    },
  ];

  return (
    <div className={styles.main}>
      <h1>Список товаров</h1>
      <h4 style={{ textAlign: 'left' }}>Фильтры полей</h4>
      <Form form={form}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={6}>
            <FormInput
              name={'id'}
              label={'ID товара'}
              placeholder={'Выберите ID товара'}
              search={true}
              type={'number'}
              onSearch={(value) => {
                fetchProducts({
                  limit: limit,
                  page: currentPage,
                  sortId: sort,
                  id: value,
                });
              }}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={12}>
            <FormInput
              name={'title'}
              label={'Название товара'}
              placeholder={'Выберите название товара'}
              search={true}
              onSearch={(value) => {
                fetchProducts({
                  limit: limit,
                  page: currentPage,
                  sortId: sort,
                  title: value,
                });
              }}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={6}>
            <FormInput
              name={'sku'}
              label={'Артикул'}
              placeholder={'Выберите артикул'}
              search={true}
              type={'number'}
              onSearch={(value) => {
                fetchProducts({
                  limit: limit,
                  page: currentPage,
                  sortId: sort,
                  sku: value,
                });
              }}
              labelCol={{ span: 24 }}
            />
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={8}>
            <FormSelect
              name={'producer'}
              label={'Производитель товара'}
              placeholder={'Выберите производителя'}
              options={producerOptions}
              showSearch={true}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={8}>
            <FormTreeSelect
              name={'category'}
              label={'Категория товара'}
              placeholder={'Выберите категорию'}
              multiple={true}
              treeData={categoryTree}
              showSearch={true}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={8}>
            <FormSelect
              name={'condition'}
              label={'Состояние'}
              placeholder={'Выберите состояние'}
              options={productConditionOptions}
              showSearch={true}
              labelCol={{ span: 24 }}
            />
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={8}>
            <FormInput
              name={'price'}
              label={'Цена'}
              placeholder={'Введите цену'}
              type={'number'}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={8}>
            <FormSelect
              showSearch={true}
              name={'pointId'}
              label={'Наличие в магазине'}
              placeholder={'Выберите магазин'}
              options={deliveryPoints.map((point: any) => ({
                value: point.id,
                label: point.title,
              }))}
              labelCol={{ span: 24 }}
            />
          </Col>
        </Row>
        <Button
          style={{ marginBottom: 20 }}
          type="primary"
          htmlType="submit"
          onClick={applyFilters}
        >
          Применить фильтры
        </Button>
        <Button
          style={{ marginLeft: 20 }}
          type="ghost"
          htmlType="reset"
          onClick={() => {
            return fetchProducts({
              limit: limit,
              page: 1,
              sortId: sort,
            });
          }}
        >
          Очистить фильтры
        </Button>
      </Form>
      <Pagination
        style={{ marginBottom: 20 }}
        total={totalProducts}
        current={currentPage}
        showQuickJumper={true}
        onChange={handlePagination}
        onShowSizeChange={onShowSizeChange}
        pageSizeOptions={[10, 25, 50, 100]}
        defaultPageSize={25}
      />
      <Table
        bordered
        columns={columns}
        dataSource={products}
        pagination={false}
        onChange={handleChange}
        rowKey="id"
      />
    </div>
  );
};
