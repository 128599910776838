import { $api } from '../http';
import { useCallback } from 'react';

/**
 * Хук для Upload antd*/
export const useHttpUploadAntd = () => {
  const requestUpload = useCallback(
    async ({
      action,
      data,
      file,
      filename,
      headers,
      onError,
      onProgress,
      onSuccess,
      withCredentials,
    }: any) => {
      const formData = new FormData();
      if (data) {
        Object.keys(data).forEach((key) => {
          formData.append(key, data[key]);
        });
      }
      formData.append(filename, file);

      $api
        .post(action, formData, {
          withCredentials,
          headers,
          onUploadProgress: ({ total = 1, loaded }) => {
            onProgress(
              { percent: Math.round((loaded / total) * 100).toFixed(2) },
              file,
            );
          },
        })
        .then(({ data: response }) => {
          onSuccess(response, file);
        })
        .catch(onError);

      return {
        abort() {
          console.log('Процесс загрузки прерван!');
        },
      };
    },
    [],
  );
  return { requestUpload };
};
