import {
  OrderHistoryAction,
  OrderHistoryActionTypes,
  OrderHistoryState,
} from '../../../types/entities/Order/OrderHistory';

const initialState: OrderHistoryState = {
  orderHistories: [],
  error: null,
  loading: false,
  currentPage: 1,
  limit: 25,
  totalOrderHistories: 0,
};

export const orderHistoryReducer = (
  state = initialState,
  action: OrderHistoryAction,
): OrderHistoryState => {
  switch (action.type) {
    case OrderHistoryActionTypes.FETCH_ORDER_HISTORY:
      return { ...state, loading: true };
    case OrderHistoryActionTypes.FETCH_ORDER_HISTORIES_ERROR:
      return { ...state, error: action.payload, loading: false };
    case OrderHistoryActionTypes.FETCH_ORDER_HISTORIES:
      return {
        ...state,
        error: null,
        orderHistories: action.payload,
        loading: false,
      };
    case OrderHistoryActionTypes.SET_ORDER_HISTORY_PAGE:
      return {
        ...state,
        error: null,
        currentPage: action.payload,
        loading: false,
      };
    case OrderHistoryActionTypes.SET_TOTAL_ORDER_HISTORY_PAGE:
      return {
        ...state,
        error: null,
        totalOrderHistories: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
