import { Link, Route } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import { ComponentsLayout } from '../../layout/ComponentsLayout/ComponentsLayout';
import { SEOFiles } from '../../Pages/SEOFiles/SEOFiles';

export const SEOFilesRoutes = (
  <>
    <Route
      path="/seo-files"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Файлы SEO</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <SEOFiles />
          </ComponentsLayout>
        </>
      }
    />
  </>
);
