import { IImage } from '../Image';
import { IProductSlider } from '../ProductSlider';
import { IProductSliderGroup } from '../ProductSliderGroup';
import { IGeoCityToZone } from './GeoCityToZone';
import { IDelivery } from '../Delivery';

export interface IGeoZone {
  id: number;
  name: string;
  description: string;
  createdAt: Date;
  updatedAt: Date;
  geoImages: IImage[];
  geoProductSlider: IProductSlider[];
  geoProductSliderGroup: IProductSliderGroup[];
  geoCityToZone: IGeoCityToZone[];
  deliveries: IDelivery[];
}

export interface GeoZoneState {
  geoZones: IGeoZone[];
  geoZonesList: IGeoZone[];
  error: string | null;
  loading: boolean;
  currentPage: number;
  limit: number;
  totalGeoZones: number;
}

export enum GeoZoneActionTypes {
  FETCH_GEO_ZONE = 'FETCH_GEO_ZONE',
  FETCH_GEO_ZONES = 'FETCH_GEO_ZONES',
  FETCH_GEO_ZONES_ERROR = 'FETCH_GEO_ZONES_ERROR',
  SET_GEO_ZONE_PAGE = 'SET_GEO_ZONE_PAGE',
  SET_TOTAL_GEO_ZONE_PAGE = 'SET_TOTAL_GEO_ZONE_PAGE',
  FETCH_GEO_ZONES_SELECT = 'FETCH_GEO_ZONES_SELECT',
}

interface FetchGeoZoneAction {
  type: GeoZoneActionTypes.FETCH_GEO_ZONE;
}

interface FetchGeoZonesAction {
  type: GeoZoneActionTypes.FETCH_GEO_ZONES;
  payload: IGeoZone[];
}

interface FetchGeoZonesErrorAction {
  type: GeoZoneActionTypes.FETCH_GEO_ZONES_ERROR;
  payload: string;
}

interface SetGeoZonePageAction {
  type: GeoZoneActionTypes.SET_GEO_ZONE_PAGE;
  payload: number;
}

interface SetTotalGeoZonePageAction {
  type: GeoZoneActionTypes.SET_TOTAL_GEO_ZONE_PAGE;
  payload: number;
}

interface FetchGeoZonesSelectAction {
  type: GeoZoneActionTypes.FETCH_GEO_ZONES_SELECT;
  payload: IGeoZone[];
}

export type GeoZoneAction =
  | FetchGeoZoneAction
  | FetchGeoZonesAction
  | FetchGeoZonesErrorAction
  | SetGeoZonePageAction
  | SetTotalGeoZonePageAction
  | FetchGeoZonesSelectAction;
