export const statusPaymentOnlineOptions = [
  { value: '0', label: 'В работе' },
  { value: '1', label: 'Успешно' },
  { value: '2', label: 'Отказ' },
];

export const entityPaymentOnlineOptions = [
  { value: 'Order', label: 'Order' },
  { value: 'Credit', label: 'Credit' },
];
