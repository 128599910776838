import {
  GeoCityAction,
  GeoCityActionTypes,
  GeoCityState,
} from '../../../types/entities/Geo/GeoCity';

const initialState: GeoCityState = {
  geoCities: [],
  geoCitiesList: [],
  error: null,
  loading: false,
  currentPage: 1,
  limit: 25,
  totalGeoCities: 0,
};

export const geoCityReducer = (
  state = initialState,
  action: GeoCityAction,
): GeoCityState => {
  switch (action.type) {
    case GeoCityActionTypes.FETCH_GEO_CITY:
      return { ...state, loading: true };
    case GeoCityActionTypes.FETCH_GEO_CITIES_ERROR:
      return { ...state, error: action.payload, loading: false };
    case GeoCityActionTypes.FETCH_GEO_CITIES:
      return {
        ...state,
        error: null,
        geoCitiesList: action.payload,
        loading: false,
      };
    case GeoCityActionTypes.SET_GEO_CITY_PAGE:
      return {
        ...state,
        error: null,
        currentPage: action.payload,
        loading: false,
      };
    case GeoCityActionTypes.SET_TOTAL_GEO_CITY_PAGE:
      return {
        ...state,
        error: null,
        totalGeoCities: action.payload,
        loading: false,
      };
    case GeoCityActionTypes.FETCH_GEO_CITIES_SELECT:
      return {
        ...state,
        error: null,
        geoCities: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
