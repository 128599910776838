import { IUser } from './User';
import { ISliderGalleryImage } from './SliderGalleryImage';
import { IGeoZone } from './Geo/GeoZone';

export interface IImage {
  id: number;
  name: string;
  description: string | null;
  filePath: string;
  createdAt: Date;
  updateUserId: number;
  updateUser: IUser | undefined;
  alt: string;
  status: number;
  link: string;
  slides: ISliderGalleryImage[];
  geoZoneId: number;
  geoZone: IGeoZone | undefined;
}

export interface ImageState {
  images: IImage[];
  error: string | null;
  loading: boolean;
  currentPage: number;
  limit: number;
  totalImages: number;
}

export enum ImageActionTypes {
  FETCH_IMAGE = 'FETCH_IMAGE',
  FETCH_IMAGES = 'FETCH_IMAGES',
  FETCH_IMAGES_ERROR = 'FETCH_IMAGES_ERROR',
  SET_IMAGE_PAGE = 'SET_IMAGE_PAGE',
  SET_TOTAL_IMAGE_PAGE = 'SET_TOTAL_IMAGE_PAGE',
}

interface FetchImageAction {
  type: ImageActionTypes.FETCH_IMAGE;
}

interface FetchImagesAction {
  type: ImageActionTypes.FETCH_IMAGES;
  payload: IImage[];
}

interface FetchImagesErrorAction {
  type: ImageActionTypes.FETCH_IMAGES_ERROR;
  payload: string;
}

interface SetImagePageAction {
  type: ImageActionTypes.SET_IMAGE_PAGE;
  payload: number;
}

interface SetTotalImagePageAction {
  type: ImageActionTypes.SET_TOTAL_IMAGE_PAGE;
  payload: number;
}

export type ImageAction =
  | FetchImageAction
  | FetchImagesAction
  | FetchImagesErrorAction
  | SetImagePageAction
  | SetTotalImagePageAction;
