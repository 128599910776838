import {
  CategoryAction,
  CategoryActionTypes,
  CategoryState,
} from '../../types/entities/Category';

const initialState: CategoryState = {
  categories: [],
  categoriesList: [],
  error: null,
  loading: false,
  currentPage: 1,
  limit: 25,
  totalCategories: 0,
};

export const categoryReducer = (
  state = initialState,
  action: CategoryAction,
): CategoryState => {
  switch (action.type) {
    case CategoryActionTypes.FETCH_CATEGORY:
      return { ...state, loading: true };
    case CategoryActionTypes.FETCH_CATEGORIES_ERROR:
      return { ...state, error: action.payload, loading: false };
    case CategoryActionTypes.FETCH_CATEGORIES:
      return {
        ...state,
        error: null,
        categoriesList: action.payload,
        loading: false,
      };
    case CategoryActionTypes.SET_CATEGORY_PAGE:
      return {
        ...state,
        error: null,
        currentPage: action.payload,
        loading: false,
      };
    case CategoryActionTypes.SET_TOTAL_CATEGORY_PAGE:
      return {
        ...state,
        error: null,
        totalCategories: action.payload,
        loading: false,
      };
    case CategoryActionTypes.FETCH_CATEGORIES_SELECT:
      return {
        ...state,
        error: null,
        categories: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
