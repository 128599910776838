import React from 'react';

import { Button, Form, Input } from 'antd';
import { useCountdown } from '../../../hooks/useCountdown';

interface LoginByCodeI {
  loginData: any;
  codePass: any;
  flag: boolean;
  targetDate: number;

  loginHandler(): void;

  getCodeHandler(): void;
}

export const LoginByCode: React.FC<LoginByCodeI> = ({
  loginData,
  codePass,
  flag,
  loginHandler,
  getCodeHandler,
  targetDate,
}) => {
  const [days, hours, minutes, seconds, totalSeconds] =
    useCountdown(targetDate);

  return (
    <>
      <Form labelCol={{ span: 9 }}>
        <Form.Item
          name="name"
          label={'Номер телефона или email'}
          valuePropName="option"
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Пожалуйста, заполните поле!',
            },
          ]}
        >
          <Input
            placeholder="Введите номер телефона или email"
            {...loginData.forInput}
          />
        </Form.Item>

        {flag && (
          <Form.Item
            name="name"
            label={'Введите код'}
            valuePropName="option"
            hasFeedback
            rules={[
              {
                required: true,
                message: 'Пожалуйста, заполните поле!',
              },
            ]}
          >
            <Input
              type="number"
              placeholder="Введите код"
              {...codePass.forInput}
            />
          </Form.Item>
        )}
      </Form>
      <Button
        disabled={!loginData.value}
        onClick={() => (flag ? loginHandler() : getCodeHandler())}
      >
        Логин
      </Button>
      <br />
      {flag && (
        <Button
          type="link"
          style={{ margin: '10px' }}
          htmlType="button"
          disabled={!loginData.value || totalSeconds > 0}
          onClick={getCodeHandler}
        >
          Отправить код повторно{' '}
          {totalSeconds > 0 && `можно через ${totalSeconds} секунд`}
        </Button>
      )}
    </>
  );
};
