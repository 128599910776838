import React, { useEffect } from 'react';

import { useCheckbox } from '../../hooks/useCheckbox';
import FormCheckbox from '../FormItems/FormCheckbox';

interface CheckboxProps {
  value: string;
  form: any;
  name: number;
  fieldName?: string;
}

export const CheckboxCustom: React.FC<CheckboxProps> = ({
  value,
  name,
  form,
  fieldName = 'attributeTypeProductCheckbox',
}) => {
  const checkbox: any = useCheckbox(value);
  useEffect(() => {
    form.setFieldsValue({
      [fieldName + name]: checkbox.value,
    });
  }, [checkbox.value]);

  return (
    <FormCheckbox
      name={fieldName + name}
      checked={checkbox.value === 1}
      onChange={checkbox.onChange}
      labelCol={{ span: 24 }}
    />
  );
};
