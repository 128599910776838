import { Form, Select } from 'antd';
import React from 'react';

interface ISelect {
  name: string;
  label?: string;
  required?: boolean;
  placeholder?: string;
  options: any;
  labelCol?: { span: number };
  showSearch?: boolean;
  mode?: 'multiple';
  onChange?: (value: number) => void;
  disabled?: boolean;
}

const FormSelect: React.FC<ISelect> = ({
  name,
  label,
  required,
  placeholder,
  options,
  labelCol,
  showSearch,
  mode,
  onChange,
  disabled,
}) => {
  const settings = {
    name,
    label,
    valuePropName: 'value',
    hasFeedback: required ? true : undefined,
    rules: required
      ? [
          {
            required: true,
            message: 'Пожалуйста, заполните поле!',
          },
        ]
      : undefined,
    labelCol,
  };

  return (
    <Form.Item {...settings}>
      <Select
        mode={mode}
        showSearch={showSearch}
        placeholder={placeholder}
        options={options}
        allowClear
        filterOption={(inputValue: any, option: any) => {
          if (option.label.toLowerCase().includes(inputValue.toLowerCase())) {
            return option;
          }
        }}
        onChange={onChange}
        disabled={disabled}
      />
    </Form.Item>
  );
};

export default FormSelect;
