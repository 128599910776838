import React, { useEffect, useState } from 'react';
import { Button, Form, Row, Select } from 'antd';
import { CloseOutlined, SaveOutlined } from '@ant-design/icons';

import { useHttp, UseHttpI } from '../../hooks/useHttp';

interface GeoZoneEditorProps {
  options: {
    value: string;
    label: string;
  }[];
  geoZoneId: number;
  id: number;
  setEdit: any;
  url: string;
}

export const GeoZoneEditor: React.FC<GeoZoneEditorProps> = ({
  options,
  geoZoneId,
  id,
  setEdit,
  url,
}): JSX.Element => {
  const [editGeoZone, setEditGeoZone] = useState(false);
  const [form] = Form.useForm();
  const { request } = useHttp();

  const option = options.find((el) => Number(el.value) === geoZoneId);

  useEffect(() => {
    form.setFieldsValue({
      geoZone: option?.value,
    });
  }, []);

  const onFinish = async (e: any) => {
    try {
      const requestObject: UseHttpI = {
        url: `${url}/${id}`,
        method: 'put',
        body: e,
      };

      await request(requestObject);
      setEditGeoZone(false);
      setEdit((edit: any) => !edit);
    } catch (e) {}
  };

  const renderSwitch = () => {
    switch (editGeoZone) {
      case true:
        return (
          <>
            <Row>
              <Form.Item name="geoZone" valuePropName="value">
                <Select
                  style={{ width: '100%' }}
                  showSearch
                  allowClear
                  placeholder="Выберите геозону"
                  options={options}
                  filterOption={(inputValue: any, option: any) => {
                    if (
                      option.label
                        .toLowerCase()
                        .includes(inputValue.toLowerCase())
                    ) {
                      return option;
                    }
                  }}
                />
              </Form.Item>
            </Row>
            <Row style={{ justifyContent: 'space-between', marginTop: 10 }}>
              <Form.Item>
                <Button
                  type="primary"
                  htmlType="submit"
                  icon={<SaveOutlined />}
                />
              </Form.Item>
              <Button
                type="primary"
                danger
                icon={<CloseOutlined />}
                onClick={() => setEditGeoZone((editGeoZone) => !editGeoZone)}
              />
            </Row>
          </>
        );
      //
      case false:
        return (
          <Button
            type={geoZoneId === null ? 'dashed' : 'primary'}
            onClick={() => setEditGeoZone((editGeoZone) => !editGeoZone)}
            danger={true}
            style={
              geoZoneId !== null
                ? {
                    backgroundColor: '#5fcb5f',
                    borderColor: '#5fcb5f',
                    width: '100%',
                  }
                : {
                    width: '100%',
                  }
            }
          >
            {option?.label ? option?.label : 'Не указано'}
          </Button>
        );
    }
  };

  return (
    <Form form={form} onFinish={onFinish}>
      {renderSwitch()}
    </Form>
  );
};
