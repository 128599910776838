import { Dispatch } from 'redux';

import { ApiUrl } from '../../types/ApiUrl';
import { UserAction, UserActionTypes } from '../../types/entities/User';
import { $api } from '../../http';

interface queryUserParamsInterface {
  limit?: any;
  page?: any;
  sortId?: string;
  email?: string;
  firstName?: string;
  lastName?: string;
  phone?: string;
  status?: string;
  roles?: any;
}

export const fetchUsersForSelect = () => {
  return async (dispatch: Dispatch<UserAction>) => {
    try {
      dispatch({ type: UserActionTypes.FETCH_USER });
      const response = await $api.get(`${ApiUrl.USER_SELECT}`);
      dispatch({
        type: UserActionTypes.FETCH_USERS_SELECT,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: UserActionTypes.FETCH_USERS_ERROR,
        payload: 'Произошла ошибка при загрузке пользователей',
      });
    }
  };
};

export const fetchUsersList = (queryParams: queryUserParamsInterface) => {
  const {
    limit = '25',
    page = 1,
    sortId = 'DESC',
    email,
    firstName,
    lastName,
    phone,
    status,
    roles,
  } = queryParams;

  const urlUserParams = new URLSearchParams();
  urlUserParams.append('limit', limit);
  urlUserParams.append('page', page);
  urlUserParams.append('sortId', sortId);

  if (email !== undefined) urlUserParams.append('email', email);
  if (firstName !== undefined) urlUserParams.append('firstName', firstName);
  if (lastName !== undefined) urlUserParams.append('lastName', lastName);
  if (phone !== undefined) urlUserParams.append('phone', phone);
  if (status !== undefined) urlUserParams.append('status', status);
  if (roles !== undefined) urlUserParams.append('roles', roles);

  return async (dispatch: Dispatch<UserAction>) => {
    try {
      dispatch({ type: UserActionTypes.FETCH_USER });
      const response = await $api.get(
        `${ApiUrl.USER}?${urlUserParams.toString()}`,
      );
      dispatch({
        type: UserActionTypes.FETCH_USERS,
        payload: response.data.items,
      });
      dispatch({
        type: UserActionTypes.SET_USER_TOTAL_PAGE,
        payload: response.data.total,
      });
      dispatch({ type: UserActionTypes.SET_USER_PAGE, payload: page });
    } catch (e) {
      dispatch({
        type: UserActionTypes.FETCH_USERS_ERROR,
        payload: 'Произошла ошибка при загрузке пользователей',
      });
    }
  };
};
