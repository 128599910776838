import React from 'react';
import { Link, Route } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import { ComponentsLayout } from '../../layout/ComponentsLayout/ComponentsLayout';
import { AddGeoRegion } from '../../Pages/GeoRegions/GeoRegion/AddGeoRegion/AddGeoRegion';
import { UpdateGeoRegion } from '../../Pages/GeoRegions/GeoRegion/UpdateGeoRegion/UpdateGeoRegion';
import { GeoRegionsList } from '../../Pages/GeoRegions/GeoRegionsList/GeoRegionsList';

export const GeoRegionRoutes = (
  <>
    <Route
      path="/content/geo/geo-region/add"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Добавить регион</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <AddGeoRegion />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/content/geo/geo-region/update/:id"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/content/geo/geo-region/list">Список регионов</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Обновление региона</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <UpdateGeoRegion />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/content/geo/geo-region/list"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Список регионов</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <GeoRegionsList />
          </ComponentsLayout>
        </>
      }
    />
  </>
);
