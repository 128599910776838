export interface IProductLog {
  id: number;
  productId: number;
  userId: number;
  createdAt: Date;
}

export interface ProductLogState {
  productLogs: IProductLog[];
  error: string | null;
  loading: boolean;
  currentPage: number;
  limit: number;
  totalProductLogs: number;
}

export enum ProductLogActionTypes {
  FETCH_PRODUCT_LOG = 'FETCH_PRODUCT_LOG',
  FETCH_PRODUCT_LOGS = 'FETCH_PRODUCT_LOGS',
  FETCH_PRODUCT_LOGS_ERROR = 'FETCH_PRODUCT_LOGS_ERROR',
  SET_PRODUCT_LOG_PAGE = 'SET_PRODUCT_LOG_PAGE',
  SET_TOTAL_PRODUCT_LOG_PAGE = 'SET_TOTAL_PRODUCT_LOG_PAGE',
}

interface FetchProductLogAction {
  type: ProductLogActionTypes.FETCH_PRODUCT_LOG;
}

interface FetchProductLogsAction {
  type: ProductLogActionTypes.FETCH_PRODUCT_LOGS;
  payload: IProductLog[];
}

interface FetchProductLogsErrorAction {
  type: ProductLogActionTypes.FETCH_PRODUCT_LOGS_ERROR;
  payload: string;
}

interface SetProductLogPageAction {
  type: ProductLogActionTypes.SET_PRODUCT_LOG_PAGE;
  payload: number;
}

interface SetTotalProductLogPageAction {
  type: ProductLogActionTypes.SET_TOTAL_PRODUCT_LOG_PAGE;
  payload: number;
}

export type ProductLogAction =
  | FetchProductLogAction
  | FetchProductLogsAction
  | FetchProductLogsErrorAction
  | SetProductLogPageAction
  | SetTotalProductLogPageAction;
