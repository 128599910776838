import { Dispatch } from 'redux';

import { ApiUrl } from '../../../types/ApiUrl';
import { $api } from '../../../http';
import {
  PaymentReferenceAction,
  PaymentReferenceActionTypes,
} from '../../../types/entities/Payment/PaymentReference';

interface queryPaymentReferenceParamsInterface {
  limit?: any;
  page?: any;
  sortId?: string;
  id?: string;
  paymentSystemId?: string;
  entityId?: string;
  status?: string;
  sum?: string;
}

export const fetchPaymentsReference = (
  queryParams: queryPaymentReferenceParamsInterface,
) => {
  const {
    limit = '25',
    page = 1,
    sortId = 'DESC',
    id,
    paymentSystemId,
    entityId,
    status,
    sum,
  } = queryParams;

  const urlPaymentReferenceParams = new URLSearchParams();
  urlPaymentReferenceParams.append('limit', limit);
  urlPaymentReferenceParams.append('page', page);
  urlPaymentReferenceParams.append('sortId', sortId);
  if (id !== undefined) urlPaymentReferenceParams.append('id', id);
  if (paymentSystemId !== undefined)
    urlPaymentReferenceParams.append('paymentSystemId', paymentSystemId);
  if (entityId !== undefined)
    urlPaymentReferenceParams.append('entityId', entityId);
  if (status !== undefined) urlPaymentReferenceParams.append('status', status);
  if (sum !== undefined) urlPaymentReferenceParams.append('sum', sum);

  return async (dispatch: Dispatch<PaymentReferenceAction>) => {
    try {
      dispatch({ type: PaymentReferenceActionTypes.FETCH_PAYMENT_REFERENCE });
      const response = await $api.get(
        `${
          ApiUrl.GET_ALL_PAYMENT_REFERENCE
        }?${urlPaymentReferenceParams.toString()}`,
      );
      dispatch({
        type: PaymentReferenceActionTypes.FETCH_PAYMENTS_REFERENCE,
        payload: response.data.items,
      });
      dispatch({
        type: PaymentReferenceActionTypes.SET_TOTAL_PAYMENT_REFERENCE_PAGE,
        payload: response.data.total,
      });
      dispatch({
        type: PaymentReferenceActionTypes.SET_PAYMENT_REFERENCE_PAGE,
        payload: page,
      });
    } catch (e) {
      dispatch({
        type: PaymentReferenceActionTypes.FETCH_PAYMENTS_REFERENCE_ERROR,
        payload: 'Произошла ошибка при загрузке платежей по ссылке',
      });
    }
  };
};
