export interface IType {
  id: number;
  name: string | null;
  categoryId: number;
  parentId: number;
}

export interface TypeState {
  types: IType[];
  error: string | null;
  loading: boolean;
}

export enum TypeActionTypes {
  FETCH_TYPE = 'FETCH_TYPE',
  FETCH_TYPES = 'FETCH_TYPES',
  FETCH_TYPES_ERROR = 'FETCH_TYPES_ERROR',
}

interface FetchTypeAction {
  type: TypeActionTypes.FETCH_TYPE;
}

interface FetchTypesAction {
  type: TypeActionTypes.FETCH_TYPES;
  payload: IType[];
}

interface FetchTypesErrorAction {
  type: TypeActionTypes.FETCH_TYPES_ERROR;
  payload: string;
}

export type TypeAction =
  | FetchTypeAction
  | FetchTypesAction
  | FetchTypesErrorAction;
