import { notification } from 'antd';

const NotificationAlert = (type: 'success' | 'error', message: string) => {
  notification[type]({
    message: message,
    duration: 2,
    placement: 'bottomRight',
  });
};

export default NotificationAlert;
