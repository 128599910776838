import { Dispatch } from 'redux';

import { $api } from '../../http';
import { ApiUrl } from '../../types/ApiUrl';
import { RoleAction, RoleActionTypes } from '../../types/entities/Role';

export const fetchRoles = () => {
  return async (dispatch: Dispatch<RoleAction>) => {
    try {
      dispatch({ type: RoleActionTypes.FETCH_ROLE });
      const response = await $api.get(`${ApiUrl.ROLE}`);
      dispatch({
        type: RoleActionTypes.FETCH_ROLES,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: RoleActionTypes.FETCH_ROLES_ERROR,
        payload: 'Произошла ошибка при загрузке ролей',
      });
    }
  };
};
