import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Row, Table } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import styles from './SlidersList.module.scss';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useActions } from '../../../hooks/useActions';
import { StatusEditor } from '../../../components/Editors/StatusEditor';
import { ApiUrl } from '../../../types/ApiUrl';
import { statusSliderOptions } from '../../../helpers/sliderHelper';
import FormInput from '../../../components/FormItems/FormInput';
import FormSelect from '../../../components/FormItems/FormSelect';

export const SlidersList: React.FC = () => {
  const { sliderGalleries, limit, currentPage } = useTypedSelector(
    (state) => state.sliderGallery,
  );

  const [sort, setSort] = useState('DESC');
  const [edit, setEdit] = useState(false);

  const { fetchSliderGalleries } = useActions();

  const [form] = Form.useForm();

  // const name = useInput('');
  // const description: any = useInput('');
  // const status = useSelect('');

  useEffect(() => {
    fetchSliderGalleries({
      limit: limit,
      page: currentPage,
      sortId: sort,
      name: form.getFieldValue('name'),
      description: form.getFieldValue('description'),
      status: form.getFieldValue('status'),
    });
  }, [edit]);

  // const onShowSizeChange = (currentPage: number, limit: number) => {
  //   fetchSliderGalleries({
  //     limit: limit,
  //     page: currentPage,
  //     sortId: sort,
  //     name: form.getFieldValue('name'),
  //     description: form.getFieldValue('description'),
  //     status: form.getFieldValue('status'),
  //   });
  // };
  //
  // const handlePagination = (currentPage: number, limit: number) => {
  //   fetchSliderGalleries({
  //     limit: limit,
  //     page: currentPage,
  //     sortId: sort,
  //     name: form.getFieldValue('name'),
  //     description: form.getFieldValue('description'),
  //     status: form.getFieldValue('status'),
  //   });
  // };

  const applyFilters = () => {
    fetchSliderGalleries({
      limit: limit,
      page: 1,
      sortId: sort,
      name: form.getFieldValue('name'),
      description: form.getFieldValue('description'),
      status: form.getFieldValue('status'),
    });
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.field === 'id') {
      if (sorter.order === 'ascend') {
        setSort('ASC');
        fetchSliderGalleries({
          limit: limit,
          page: currentPage,
          sortId: 'ASC',
        });
      } else {
        setSort('DESC');
        fetchSliderGalleries({
          limit: limit,
          page: currentPage,
          sortId: 'DESC',
        });
      }
    }
  };

  const columns = [
    {
      title: 'ID галереи',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => {
        return (
          <div key={id}>
            <Link to={`/content/sliders/update/${id}`}>{id}</Link>
          </div>
        );
      },
      sorter: true,
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      render: (name: string) => {
        if (name) {
          return <>{name}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
      render: (description: string) => {
        if (description) {
          return <>{description}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Статус',
      key: 'status',
      render: (data: any) => {
        return (
          <StatusEditor
            status={data.status}
            id={data.id}
            options={statusSliderOptions}
            setEdit={setEdit}
            url={ApiUrl.UPDATE_SLIDER_GALLERY_STATUS}
          />
        );
      },
    },
    {
      title: 'Кол-во изображений',
      key: 'count',
      render: (data: any) => {
        if (data.slides) {
          return <>{data.slides.length}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Действия с галереей',
      dataIndex: 'id',
      key: 'edit',
      render: (id: string) => {
        return (
          <>
            <Link to={`/content/sliders/update/${id}`}>
              <EditOutlined style={{ marginLeft: 50 }} />
            </Link>
          </>
        );
      },
    },
  ];

  return (
    <div className={styles.main}>
      <h1>Галереи</h1>
      <h4 style={{ textAlign: 'left' }}>Фильтры полей</h4>
      <Form form={form}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <FormInput
              name={'name'}
              label={'Название'}
              placeholder={'Выберите название галереи'}
              search={true}
              onSearch={(value) => {
                fetchSliderGalleries({
                  limit: limit,
                  page: currentPage,
                  sortId: sort,
                  name: value,
                });
              }}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={12}>
            <FormInput
              name={'description'}
              label={'Описание'}
              placeholder={'Введите описание галереи'}
              search={true}
              onSearch={(value) => {
                fetchSliderGalleries({
                  limit: limit,
                  page: currentPage,
                  sortId: sort,
                  description: value,
                });
              }}
              labelCol={{ span: 24 }}
            />
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <FormSelect
              name={'status'}
              label={'Статус'}
              placeholder={'Выберите статус'}
              options={statusSliderOptions}
              showSearch={true}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={12}>
            <Button
              style={{ marginTop: 40 }}
              type="primary"
              htmlType="submit"
              onClick={applyFilters}
            >
              Применить фильтры
            </Button>
            <Button
              style={{ marginLeft: 20 }}
              type="ghost"
              htmlType="reset"
              onClick={() => {
                return fetchSliderGalleries({
                  limit: limit,
                  page: 1,
                  sortId: sort,
                });
              }}
            >
              Очистить фильтры
            </Button>
          </Col>
        </Row>
      </Form>
      {/*<Pagination*/}
      {/*    style={{ marginBottom: 20 }}*/}
      {/*    total={totalSliderGalleries}*/}
      {/*    current={currentPage}*/}
      {/*    showQuickJumper={true}*/}
      {/*    onChange={handlePagination}*/}
      {/*    onShowSizeChange={onShowSizeChange}*/}
      {/*    pageSizeOptions={[10, 25, 50, 100]}*/}
      {/*    defaultPageSize={25}*/}
      {/*/>*/}
      <Table
        bordered
        columns={columns}
        dataSource={sliderGalleries}
        pagination={false}
        onChange={handleChange}
        rowKey="id"
      />
    </div>
  );
};
