import { Dispatch } from 'redux';

import { $api } from '../../http';
import { ApiUrl } from '../../types/ApiUrl';
import {
  ProductSliderGroupAction,
  ProductSliderGroupActionTypes,
} from '../../types/entities/ProductSliderGroup';

interface queryProductSliderGroupInterface {
  limit?: any;
  page?: any;
  sortId?: string;
  name?: string;
  description?: string;
  code?: string;
}

export const fetchProductSliderGroups = (
  queryParams: queryProductSliderGroupInterface,
) => {
  const {
    limit = '25',
    page = 1,
    sortId = 'DESC',
    name,
    description,
    code,
  } = queryParams;

  const urlProductSliderGroupParams = new URLSearchParams();
  urlProductSliderGroupParams.append('limit', limit);
  urlProductSliderGroupParams.append('page', page);
  urlProductSliderGroupParams.append('sortId', sortId);
  if (name !== undefined) urlProductSliderGroupParams.append('name', name);
  if (description !== undefined)
    urlProductSliderGroupParams.append('description', description);
  if (code !== undefined) urlProductSliderGroupParams.append('code', code);

  return async (dispatch: Dispatch<ProductSliderGroupAction>) => {
    try {
      dispatch({
        type: ProductSliderGroupActionTypes.FETCH_PRODUCT_SLIDER_GROUP,
      });
      const response = await $api.get(
        `${
          ApiUrl.PRODUCT_SLIDER_GROUP
        }?${urlProductSliderGroupParams.toString()}`,
      );
      dispatch({
        type: ProductSliderGroupActionTypes.FETCH_PRODUCT_SLIDER_GROUPS,
        payload: response.data.items,
      });
      dispatch({
        type: ProductSliderGroupActionTypes.SET_TOTAL_PRODUCT_SLIDER_GROUP_PAGE,
        payload: response.data.total,
      });
      dispatch({
        type: ProductSliderGroupActionTypes.SET_PRODUCT_SLIDER_GROUP_PAGE,
        payload: page,
      });
    } catch (e) {
      dispatch({
        type: ProductSliderGroupActionTypes.FETCH_PRODUCT_SLIDER_GROUPS_ERROR,
        payload: 'Произошла ошибка при загрузке лент продуктов',
      });
    }
  };
};
