import React from 'react';

import { ComponentsLayout } from '../../layout/ComponentsLayout/ComponentsLayout';
import { Login } from './Login/Login';

export const Auth: React.FC = () => {
  return (
    <ComponentsLayout>
      <Login />
    </ComponentsLayout>
  );
};
