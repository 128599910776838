import React from 'react';
import { Link, Route } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import { ComponentsLayout } from '../../layout/ComponentsLayout/ComponentsLayout';
import { DeliveriesList } from '../../Pages/Deliveries/DeliveriesList/DeliveriesList';
import { AddDelivery } from '../../Pages/Deliveries/Delivery/AddDelivery/AddDelivery';
import { UpdateDelivery } from '../../Pages/Deliveries/Delivery/UpdateDelivery/UpdateDelivery';

export const DeliveryRoutes = (
  <>
    <Route
      path="/services/deliveries/add"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Добавить способ</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <AddDelivery />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/services/deliveries/update/:id"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/services/deliveries/list">Способы доставки</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Обновление способа</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <UpdateDelivery />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/services/deliveries/list"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Способы доставки</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <DeliveriesList />
          </ComponentsLayout>
        </>
      }
    />
  </>
);
