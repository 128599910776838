import { Dispatch } from 'redux';

import { ApiUrl } from '../../../types/ApiUrl';
import { $api } from '../../../http';
import {
  PaymentOnlineAction,
  PaymentOnlineActionTypes,
} from '../../../types/entities/Payment/PaymentOnline';

interface queryPaymentOnlineParamsInterface {
  limit?: any;
  page?: any;
  sortId?: string;
  id?: string;
  paymentSystemId?: string;
  entityId?: string;
  status?: string;
  sum?: string;
}

export const fetchPaymentsOnline = (
  queryParams: queryPaymentOnlineParamsInterface,
) => {
  const {
    limit = '25',
    page = 1,
    sortId = 'DESC',
    id,
    paymentSystemId,
    entityId,
    status,
    sum,
  } = queryParams;

  const urlPaymentOnlineParams = new URLSearchParams();
  urlPaymentOnlineParams.append('limit', limit);
  urlPaymentOnlineParams.append('page', page);
  urlPaymentOnlineParams.append('sortId', sortId);
  if (id !== undefined) urlPaymentOnlineParams.append('id', id);
  if (paymentSystemId !== undefined)
    urlPaymentOnlineParams.append('paymentSystemId', paymentSystemId);
  if (entityId !== undefined)
    urlPaymentOnlineParams.append('entityId', entityId);
  if (status !== undefined) urlPaymentOnlineParams.append('status', status);
  if (sum !== undefined) urlPaymentOnlineParams.append('sum', sum);

  return async (dispatch: Dispatch<PaymentOnlineAction>) => {
    try {
      dispatch({ type: PaymentOnlineActionTypes.FETCH_PAYMENT_ONLINE });
      const response = await $api.get(
        `${ApiUrl.GET_ALL_PAYMENT_ONLINE}?${urlPaymentOnlineParams.toString()}`,
      );
      dispatch({
        type: PaymentOnlineActionTypes.FETCH_PAYMENTS_ONLINE,
        payload: response.data.items,
      });
      dispatch({
        type: PaymentOnlineActionTypes.SET_TOTAL_PAYMENT_ONLINE_PAGE,
        payload: response.data.total,
      });
      dispatch({
        type: PaymentOnlineActionTypes.SET_PAYMENT_ONLINE_PAGE,
        payload: page,
      });
    } catch (e) {
      dispatch({
        type: PaymentOnlineActionTypes.FETCH_PAYMENTS_ONLINE_ERROR,
        payload: 'Произошла ошибка при загрузке онлайн платежей',
      });
    }
  };
};
