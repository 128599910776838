import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Pagination, Row, Table } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import styles from './GeoZonesList.module.scss';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useActions } from '../../../hooks/useActions';
import FormInput from '../../../components/FormItems/FormInput';

export const GeoZonesList: React.FC = () => {
  const { geoZonesList, limit, currentPage, totalGeoZones } = useTypedSelector(
    (state) => state.geoZone,
  );

  const [sort, setSort] = useState('DESC');

  const { fetchGeoZonesList } = useActions();

  const [form] = Form.useForm();

  useEffect(() => {
    fetchGeoZonesList({
      limit: limit,
      page: currentPage,
      sortId: sort,
      id: form.getFieldValue('id'),
      name: form.getFieldValue('name'),
      description: form.getFieldValue('description'),
    });
  }, []);

  const onShowSizeChange = (currentPage: number, limit: number) => {
    fetchGeoZonesList({
      limit: limit,
      page: currentPage,
      sortId: sort,
      id: form.getFieldValue('id'),
      name: form.getFieldValue('name'),
      description: form.getFieldValue('description'),
    });
  };

  const handlePagination = (currentPage: number, limit: number) => {
    fetchGeoZonesList({
      limit: limit,
      page: currentPage,
      sortId: sort,
      id: form.getFieldValue('id'),
      name: form.getFieldValue('name'),
      description: form.getFieldValue('description'),
    });
  };

  const applyFilters = () => {
    fetchGeoZonesList({
      limit: limit,
      page: 1,
      sortId: sort,
      id: form.getFieldValue('id'),
      name: form.getFieldValue('name'),
      description: form.getFieldValue('description'),
    });
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.field === 'id') {
      if (sorter.order === 'ascend') {
        setSort('ASC');
        fetchGeoZonesList({
          limit: limit,
          page: currentPage,
          sortId: 'ASC',
        });
      } else {
        setSort('DESC');
        fetchGeoZonesList({
          limit: limit,
          page: currentPage,
          sortId: 'DESC',
        });
      }
    }
  };

  const columns = [
    {
      title: 'ID геозоны',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => {
        return (
          <div key={id}>
            <Link to={`/content/geo/geo-zone/update/${id}`}>{id}</Link>
          </div>
        );
      },
      sorter: true,
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      render: (name: string) => {
        if (name) {
          return <>{name}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Описание',
      dataIndex: 'description',
      key: 'description',
      render: (description: string) => {
        if (description) {
          return <>{description}</>;
        } else {
          return '---';
        }
      },
    },
    {
      title: 'Действия с геозоной',
      dataIndex: 'id',
      key: 'edit',
      render: (id: string) => {
        return (
          <>
            <Link to={`/content/geo/geo-zone/update/${id}`}>
              <EditOutlined style={{ marginLeft: 50 }} />
            </Link>
          </>
        );
      },
    },
  ];

  return (
    <div className={styles.main}>
      <h1>Геозоны</h1>
      <h4 style={{ textAlign: 'left' }}>Фильтры полей</h4>
      <Form form={form}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <FormInput
              name={'id'}
              label={'ID геозоны'}
              placeholder={'Выберите геозону'}
              search={true}
              onSearch={(value) => {
                fetchGeoZonesList({
                  limit: limit,
                  page: currentPage,
                  sortId: sort,
                  id: value,
                });
              }}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={12}>
            <FormInput
              name={'name'}
              label={'Название'}
              placeholder={'Выберите название геозоны'}
              search={true}
              onSearch={(value) => {
                fetchGeoZonesList({
                  limit: limit,
                  page: currentPage,
                  sortId: sort,
                  name: value,
                });
              }}
              labelCol={{ span: 24 }}
            />
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <FormInput
              name={'description'}
              label={'Описание'}
              placeholder={'Введите описание геозоны'}
              search={true}
              onSearch={(value) => {
                fetchGeoZonesList({
                  limit: limit,
                  page: currentPage,
                  sortId: sort,
                  description: value,
                });
              }}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={6}>
            <Button
              style={{ marginTop: 40 }}
              type="primary"
              htmlType="submit"
              onClick={applyFilters}
            >
              Применить фильтры
            </Button>
          </Col>
          <Col span={6}>
            <Button
              style={{ marginTop: 40 }}
              type="ghost"
              htmlType="reset"
              onClick={() => {
                return fetchGeoZonesList({
                  limit: limit,
                  page: 1,
                  sortId: sort,
                });
              }}
            >
              Очистить фильтры
            </Button>
          </Col>
        </Row>
      </Form>
      <Pagination
        style={{ marginBottom: 20 }}
        total={totalGeoZones}
        current={currentPage}
        showQuickJumper={true}
        onChange={handlePagination}
        onShowSizeChange={onShowSizeChange}
        pageSizeOptions={[10, 25, 50, 100]}
        defaultPageSize={10}
      />
      <Table
        bordered
        columns={columns}
        dataSource={geoZonesList}
        pagination={false}
        onChange={handleChange}
        rowKey="id"
      />
    </div>
  );
};
