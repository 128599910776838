import { Button, Checkbox, Form, Input, Table } from 'antd';
import styles from './Kkm.module.scss';
import { useEffect, useState } from 'react';
import { useHttp } from '../../hooks/useHttp';
import { ApiUrl } from '../../types/ApiUrl';
import NotificationAlert from '../../components/Notification';
import { Link } from 'react-router-dom';

export interface IKkmData {
  id: number;
  kkmId: string;
  message: string;
  response: any;
}

export const KkmPage = () => {
  const [form] = Form.useForm();
  const [nowDate, setNowDate] = useState(true);
  const [payments, setPayments] = useState<IKkmData[]>([]);

  useEffect(() => {
    if (nowDate) {
      form.setFieldValue('dateEnd', new Date().toISOString().substring(0, 10));
    }
  }, [nowDate]);

  const { request } = useHttp();

  const onFinish = async (e: any) => {
    try {
      const response = await request({
        url: ApiUrl.PAYMENT + '/online/kkm/manual',
        method: 'get',
        params: e,
      });
      if (response.data?.length)
        setPayments(response.data.map((item: any) => item.value));
      NotificationAlert('success', 'Выгрузка чеков началась');
    } catch (error: any) {
      NotificationAlert('error', error?.response?.data?.message || 'Ошибка выгрузки чеков');
    }
  };

  const checkKkmStatus = async (kkmId: string) => {
    if (!kkmId) return NotificationAlert('error', 'Отсутствует id чека');
    const response = await request({
      url: ApiUrl.PAYMENT + '/online/check/' + kkmId,
      method: 'get',
    });
    if (response.data?.operation?.status === 'complete')
      NotificationAlert('success', 'Чек успешно отправлен');
    else NotificationAlert('error', response.data?.operation?.message);
    form.setFieldValue('kkmStatus', response.data?.operation?.status_int);
  };

  const columns = [
    {
      title: 'ID платежа',
      dataIndex: 'id',
      key: 'id',
      render: (id: number) => {
        return (
          <div key={id}>
            <Link
              target="_blank"
              to={`/services/payment/payment-online/update/${id}`}
            >
              {id}
            </Link>
          </div>
        );
      },
      sorter: true,
    },
    {
      title: 'ID чека',
      dataIndex: 'kkmId',
      key: 'kkmId',
      render: (kkmId: string) => {
        return <>{kkmId}</>;
      },
    },
    {
      title: 'Причина ошибки',
      dataIndex: 'message',
      key: 'message',
      render: (message: string) => {
        return <>{message || 'Ошибка не определена'}</>;
      },
    },
    {
      title: 'Ответ kkm',
      dataIndex: 'response',
      key: 'response',
      render: (response: any) => {
        return <>{JSON.stringify(response).replaceAll(',"',', "')}</>;
      },
    },
    {
      title: 'Действия',
      dataIndex: 'kkmId',
      key: 'action',
      render: (kkmId: string) => {
        return (
          <div className={styles.actions}>
            <Button type="primary" onClick={() => checkKkmStatus(kkmId)}>
              Проверить статус
            </Button>
            <Button
              danger
              type="primary"
              onClick={() => {
                setPayments(
                  payments.filter((payment) => payment.kkmId !== kkmId),
                );
              }}
            >
              Удалить
            </Button>
          </div>
        );
      },
    },
  ];

  return (
    <>
      <Form form={form} onFinish={onFinish}>
        <h3>Введите промежуток времени за который нужно выгрузить чеки</h3>
        <div className={styles.dates}>
          <Form.Item
            name="dateStart"
            label="От"
            labelCol={{ span: 24 }}
            rules={[
              {
                required: true,
                message: 'Пожалуйста, заполните поле!',
              },
              {
                validator: (_, e) => {
                  return form.getFieldValue('dateStart') <=
                    form.getFieldValue('dateEnd')
                    ? Promise.resolve()
                    : Promise.reject('');
                },
                message: 'Начальная дата должна быть меньше конечной',
              },
            ]}
          >
            <Input
              type="date"
              placeholder="От"
              onChange={() => form.validateFields(['dateEnd'])}
            ></Input>
          </Form.Item>
          <Form.Item
            name="dateEnd"
            labelCol={{ span: 24 }}
            label={
              <>
                До
                <div className={styles.checkbox}>
                  <Checkbox
                    checked={nowDate}
                    onChange={(e) => setNowDate(e.target.checked)}
                  >
                    Сегодня
                  </Checkbox>
                </div>
              </>
            }
            rules={[
              {
                required: true,
                message: 'Пожалуйста, заполните поле!',
              },
              {
                validator: (_, e) => {
                  return form.getFieldValue('dateStart') <=
                    form.getFieldValue('dateEnd')
                    ? Promise.resolve()
                    : Promise.reject('');
                },
                message: 'Начальная дата должна быть меньше конечной',
              },
            ]}
            initialValue={new Date()}
          >
            <Input
              type="date"
              placeholder="До"
              onChange={() => form.validateFields(['dateStart'])}
              disabled={nowDate}
            ></Input>
          </Form.Item>
        </div>
        <Form.Item>
          <Button type="primary" htmlType="submit">
            Выгрузить
          </Button>
        </Form.Item>
      </Form>
      {payments.length > 0 && (
        <>
          <h5>
            Ниже представлены данные о чеках за выбранный период, которые были
            выгружены с ошибкой. Перед обновлением статуса чека может быть
            задержка в пару минут.
          </h5>
          <Table
            bordered
            columns={columns}
            dataSource={payments}
            pagination={false}
            rowKey="id"
          />
        </>
      )}
    </>
  );
};
