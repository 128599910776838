import { AuthAction, AuthActionTypes, AuthState } from '../../types/Auth/Auth';
import { ROLES } from '../../types/constants';

const initialState: AuthState = {
  user: null,
  isAuth: false,
  error: null,
  loading: false,
  isAdmin: false,
};

export const authReducer = (
  state = initialState,
  action: AuthAction,
): AuthState => {
  switch (action.type) {
    case AuthActionTypes.FETCH:
      return { ...state, loading: true };
    case AuthActionTypes.END_FETCH:
      return { ...state, loading: false };
    case AuthActionTypes.REGISTRATION:
      return {
        ...state,
        error: null,
        loading: false,
        user: action.payload.user,
        isAuth: true,
      };
    case AuthActionTypes.LOGIN:
      let isAdmin = false;
      const user = action.payload.user;
      user.roles?.every((role) => {
        if (role.value === ROLES.admin) {
          isAdmin = true;
          return false;
        }
        return true;
      });
      return {
        ...state,
        error: null,
        loading: false,
        user: action.payload.user,
        isAuth: true,
        isAdmin: isAdmin,
      };
    case AuthActionTypes.LOGOUT:
      return {
        ...state,
        error: null,
        loading: false,
        isAuth: false,
        user: null,
      };
    case AuthActionTypes.ERROR:
      return { ...state, error: action.payload, loading: false, isAuth: false };
    default:
      return state;
  }
};
