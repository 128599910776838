export interface IShop {
  id: number;
  title: string | null;
  address: string | null;
  images: string[];
  map: string | null;
  openHours: string | null;
  description: string | null;
  phone: string | null;
}

export interface ShopState {
  shops: IShop[];
  error: string | null;
  loading: boolean;
  currentPage: number;
  limit: number;
  totalShops: number;
}

export enum ShopActionTypes {
  FETCH_SHOP = 'FETCH_SHOP',
  FETCH_SHOPS = 'FETCH_SHOPS',
  FETCH_SHOPS_ERROR = 'FETCH_SHOPS_ERROR',
  SET_SHOP_PAGE = 'SET_SHOP_PAGE',
  SET_TOTAL_SHOP_PAGE = 'SET_TOTAL_SHOP_PAGE',
}

interface FetchShopAction {
  type: ShopActionTypes.FETCH_SHOP;
}

interface FetchShopsAction {
  type: ShopActionTypes.FETCH_SHOPS;
  payload: IShop[];
}

interface FetchShopsErrorAction {
  type: ShopActionTypes.FETCH_SHOPS_ERROR;
  payload: string;
}

interface SetShopPageAction {
  type: ShopActionTypes.SET_SHOP_PAGE;
  payload: number;
}

interface SetTotalShopPageAction {
  type: ShopActionTypes.SET_TOTAL_SHOP_PAGE;
  payload: number;
}

export type ShopAction =
  | FetchShopAction
  | FetchShopsAction
  | FetchShopsErrorAction
  | SetShopPageAction
  | SetTotalShopPageAction;
