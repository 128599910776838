import { Dispatch } from 'redux';

import { ApiUrl } from '../../types/ApiUrl';
import { $api } from '../../http';
import {
  ProductLogAction,
  ProductLogActionTypes,
} from '../../types/entities/ProductLog';

interface queryProductLogParamsInterface {
  limit?: any;
  page?: any;
  sortId?: string;
}

export const fetchProductLogs = (
  queryParams: queryProductLogParamsInterface,
) => {
  const { limit = '25', page = 1, sortId = 'DESC' } = queryParams;

  const urlProductLogParams = new URLSearchParams();
  urlProductLogParams.append('limit', limit);
  urlProductLogParams.append('page', page);
  urlProductLogParams.append('sortId', sortId);

  return async (dispatch: Dispatch<ProductLogAction>) => {
    try {
      dispatch({ type: ProductLogActionTypes.FETCH_PRODUCT_LOG });
      const response = await $api.get(
        `${ApiUrl.PRODUCT_LOG}?${urlProductLogParams.toString()}`,
      );
      dispatch({
        type: ProductLogActionTypes.FETCH_PRODUCT_LOGS,
        payload: response.data.items,
      });
      dispatch({
        type: ProductLogActionTypes.SET_TOTAL_PRODUCT_LOG_PAGE,
        payload: response.data.total,
      });
      dispatch({
        type: ProductLogActionTypes.SET_PRODUCT_LOG_PAGE,
        payload: page,
      });
    } catch (e) {
      dispatch({
        type: ProductLogActionTypes.FETCH_PRODUCT_LOGS_ERROR,
        payload: 'Произошла ошибка при загрузке истории сохранений',
      });
    }
  };
};
