import { IGeoCityToZone } from './GeoCityToZone';
import { IGeoRegion } from './GeoRegion';

export interface IGeoCity {
  id: number;
  name: string;
  status: number;
  code: string;
  regionId: number;
  region: IGeoRegion | undefined;
  type: string;
  geoCityToZones: IGeoCityToZone[];
}

export interface GeoCityState {
  geoCities: IGeoCity[];
  geoCitiesList: IGeoCity[];
  error: string | null;
  loading: boolean;
  currentPage: number;
  limit: number;
  totalGeoCities: number;
}

export enum GeoCityActionTypes {
  FETCH_GEO_CITY = 'FETCH_GEO_CITY',
  FETCH_GEO_CITIES = 'FETCH_GEO_CITIES',
  FETCH_GEO_CITIES_ERROR = 'FETCH_GEO_CITIES_ERROR',
  SET_GEO_CITY_PAGE = 'SET_GEO_CITY_PAGE',
  SET_TOTAL_GEO_CITY_PAGE = 'SET_TOTAL_GEO_CITY_PAGE',
  FETCH_GEO_CITIES_SELECT = 'FETCH_GEO_CITIES_SELECT',
}

interface FetchGeoCityAction {
  type: GeoCityActionTypes.FETCH_GEO_CITY;
}

interface FetchGeoCitiesAction {
  type: GeoCityActionTypes.FETCH_GEO_CITIES;
  payload: IGeoCity[];
}

interface FetchGeoCitiesErrorAction {
  type: GeoCityActionTypes.FETCH_GEO_CITIES_ERROR;
  payload: string;
}

interface SetGeoCityPageAction {
  type: GeoCityActionTypes.SET_GEO_CITY_PAGE;
  payload: number;
}

interface SetTotalGeoCityPageAction {
  type: GeoCityActionTypes.SET_TOTAL_GEO_CITY_PAGE;
  payload: number;
}

interface FetchGeoCitiesSelectAction {
  type: GeoCityActionTypes.FETCH_GEO_CITIES_SELECT;
  payload: IGeoCity[];
}

export type GeoCityAction =
  | FetchGeoCityAction
  | FetchGeoCitiesAction
  | FetchGeoCitiesErrorAction
  | SetGeoCityPageAction
  | SetTotalGeoCityPageAction
  | FetchGeoCitiesSelectAction;
