import { Dispatch } from 'redux';

import { $api } from '../../http';
import { ApiUrl } from '../../types/ApiUrl';
import { ImageAction, ImageActionTypes } from '../../types/entities/Image';

interface queryImageParamsInterface {
  limit?: any;
  page?: any;
  sortId?: string;
  id?: string;
  name?: string;
  geoZoneId?: string;
  status?: string;
  sliders?: any;
}

export const fetchImages = (queryParams: queryImageParamsInterface) => {
  const {
    limit = '25',
    page = 1,
    sortId = 'DESC',
    id,
    name,
    geoZoneId,
    status,
    sliders,
  } = queryParams;

  const urlImageParams = new URLSearchParams();
  urlImageParams.append('limit', limit);
  urlImageParams.append('page', page);
  urlImageParams.append('sortId', sortId);
  if (id !== undefined) urlImageParams.append('id', id);
  if (name !== undefined) urlImageParams.append('name', name);
  if (geoZoneId !== undefined) urlImageParams.append('geoZoneId', geoZoneId);
  if (status !== undefined) urlImageParams.append('status', status);
  if (sliders !== undefined) urlImageParams.append('sliders', sliders);

  return async (dispatch: Dispatch<ImageAction>) => {
    try {
      dispatch({ type: ImageActionTypes.FETCH_IMAGE });
      const response = await $api.get(
        `${ApiUrl.IMAGE}?${urlImageParams.toString()}`,
      );
      dispatch({
        type: ImageActionTypes.FETCH_IMAGES,
        payload: response.data.items,
      });
      dispatch({
        type: ImageActionTypes.SET_TOTAL_IMAGE_PAGE,
        payload: response.data.total,
      });
      dispatch({ type: ImageActionTypes.SET_IMAGE_PAGE, payload: page });
    } catch (e) {
      dispatch({
        type: ImageActionTypes.FETCH_IMAGES_ERROR,
        payload: 'Произошла ошибка при загрузке изображений',
      });
    }
  };
};
