import { Link, Route } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { ComponentsLayoutBlock } from '../../layout/ComponentLayoutBlock/ComponentLayoutBlock';
import { ComponentsLayout } from '../../layout/ComponentsLayout/ComponentsLayout';
import { AddTypes } from '../../Pages/Types/Types/AddTypes/AddTypes';
import { UpdateTypes } from '../../Pages/Types/Types/UpdateTypes/UpdateTypes';
import { TypesList } from '../../Pages/Types/TypesList/TypesList';

export const TypesRoutes = (
  <>
    <Route
      path="/types/add"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Добавить статью</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayoutBlock>
            <AddTypes />
          </ComponentsLayoutBlock>
        </>
      }
    />
    <Route
      path="/types/update/:id"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/types/list">Список статей</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Обновление статьи</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayoutBlock>
            <UpdateTypes />
          </ComponentsLayoutBlock>
        </>
      }
    />
    <Route
      path="/types/list"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Список статей</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <TypesList />
          </ComponentsLayout>
        </>
      }
    />
  </>
);
