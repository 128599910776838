import React from 'react';
import { Link, Route } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import { UpdateProduct } from '../../Pages/Products/Product/UpdateProduct/UpdateProduct';
import { AddProduct } from '../../Pages/Products/Product/AddProduct/AddProduct';
import { ProductList } from '../../Pages/Products/ProductsList/ProductList';
import { ComponentsLayout } from '../../layout/ComponentsLayout/ComponentsLayout';

export const ProductRoutes = (
  <>
    <Route
      path="/products/add"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Добавить товар</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <AddProduct />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/products/update/:id"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/products/list">Список товаров</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Обновление товара</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout classtext="product-page">
            <UpdateProduct />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/products/list"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Список товаров</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <ProductList />
          </ComponentsLayout>
        </>
      }
    />
  </>
);
