import React from 'react';

import { MenuItem } from '../MenuItem';
import { UPDATE_SUBMIT_METHOD } from '../../../../types/constants';

export const UpdateMenuItem: React.FC = () => {
  const titlePage = 'Обновление пункта меню';
  const submitMethod = UPDATE_SUBMIT_METHOD;

  const props = {
    titlePage: titlePage,
    submitMethod: submitMethod,
  };

  return (
    <>
      <MenuItem {...props} />
    </>
  );
};
