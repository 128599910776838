import { Dispatch } from 'redux';

import { $api } from '../../../http';
import { ApiUrl } from '../../../types/ApiUrl';
import {
  GeoCityAction,
  GeoCityActionTypes,
} from '../../../types/entities/Geo/GeoCity';

interface queryGeoCityParamsInterface {
  limit?: any;
  page?: any;
  sortId?: string;
  id?: string;
  regionId?: string;
  name?: string;
  code?: string;
  status?: string;
}

export const fetchGeoCitiesForSelect = () => {
  return async (dispatch: Dispatch<GeoCityAction>) => {
    try {
      dispatch({ type: GeoCityActionTypes.FETCH_GEO_CITY });
      const response = await $api.get(`${ApiUrl.GEO_CITY_SELECT}`);
      dispatch({
        type: GeoCityActionTypes.FETCH_GEO_CITIES_SELECT,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: GeoCityActionTypes.FETCH_GEO_CITIES_ERROR,
        payload: 'Произошла ошибка при загрузке городов',
      });
    }
  };
};

export const fetchGeoCitiesList = (
  queryParams: queryGeoCityParamsInterface,
) => {
  const {
    limit = '25',
    page = 1,
    sortId = 'DESC',
    id,
    regionId,
    name,
    code,
    status,
  } = queryParams;

  const urlGeoCityParams = new URLSearchParams();
  urlGeoCityParams.append('limit', limit);
  urlGeoCityParams.append('page', page);
  urlGeoCityParams.append('sortId', sortId);

  if (id !== undefined) urlGeoCityParams.append('id', id);
  if (regionId !== undefined) urlGeoCityParams.append('regionId', regionId);
  if (name !== undefined) urlGeoCityParams.append('name', name);
  if (code !== undefined) urlGeoCityParams.append('code', code);
  if (status !== undefined) urlGeoCityParams.append('status', status);

  return async (dispatch: Dispatch<GeoCityAction>) => {
    try {
      dispatch({ type: GeoCityActionTypes.FETCH_GEO_CITY });
      const response = await $api.get(`${ApiUrl.GEO_CITY}?${urlGeoCityParams}`);
      dispatch({
        type: GeoCityActionTypes.FETCH_GEO_CITIES,
        payload: response.data.items,
      });
      dispatch({
        type: GeoCityActionTypes.SET_TOTAL_GEO_CITY_PAGE,
        payload: response.data.total,
      });
      dispatch({
        type: GeoCityActionTypes.SET_GEO_CITY_PAGE,
        payload: page,
      });
    } catch (e) {
      dispatch({
        type: GeoCityActionTypes.FETCH_GEO_CITIES_ERROR,
        payload: 'Произошла ошибка при загрузке городов',
      });
    }
  };
};
