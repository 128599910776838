import { IUser } from '../User';
import { IPayment } from '../Payment/Payment';
import { IDeliveryPoint } from '../DeliveryPoint';
import { IDelivery } from '../Delivery';
import { IOrderStatus } from './OrderStatus';
import { IOrderHistory } from './OrderHistory';
import { IPromoCode } from '../Promocode';
import { IOrderProduct } from './OrderProduct';
import { IOrderProductHistory } from './OrderProductHistory';

export interface IOrder {
  id: number;
  deliveryId: number;
  delivery: IDelivery | undefined;
  deliveryPointId: number;
  deliveryPoint: IDeliveryPoint | undefined;
  paymentMethodId: number;
  paymentMethod: IPayment | undefined;
  userId: number | null;
  user: IUser | undefined;
  statusId: number;
  status: IOrderStatus | undefined;
  managerId: number | null;
  manager: IUser | undefined;
  house: string | null;
  apartment: string | null;
  deliveryPrice: number;
  paid: number;
  paymentTime: string | null;
  paymentDetails: string | null;
  totalPrice: number;
  discount: number;
  userRelations: any;
  promoCode: string | null;
  separateDelivery: number;
  date: string | null;
  name: string | null;
  street: string | null;
  phone: string | null;
  phoneContact: string | null;
  email: string | null;
  comment: string | null;
  ip: string | null;
  postIndex: string | null;
  url: string | null;
  modified: string | null;
  city: string | null;
  address: string | null;
  firstName: string | null;
  lastName: string | null;
  middleName: string | null;
  gender: number;
  userAgent: string | null;
  numberOneC: string | null;
  idOneC: string | null;
  creditId: string | null;
  poscreditAuthStatus: number | null;
  phoneConfirm: number;
  reasonCancel: number | null;
  type: number;
  dateEndReserve: string | null;
  newsletterConsent: number;
  whatsapp: boolean;
  viber: boolean;
  telegram: boolean;
  createdAt: string | null;
  histories: IOrderHistory[];
  coupons: IPromoCode[];
  orderProducts: IOrderProduct[];
  orderProductHistories: IOrderProductHistory[];
}

export interface OrderState {
  orders: IOrder[];
  error: string | null;
  loading: boolean;
  currentPage: number;
  limit: number;
  totalOrders: number;
}

export enum OrderActionTypes {
  FETCH_ORDER = 'FETCH_ORDER',
  FETCH_ORDERS = 'FETCH_ORDERS',
  FETCH_ORDERS_ERROR = 'FETCH_ORDERS_ERROR',
  SET_ORDER_PAGE = 'SET_ORDER_PAGE',
  SET_TOTAL_ORDER_PAGE = 'SET_TOTAL_ORDER_PAGE',
}

interface FetchOrderAction {
  type: OrderActionTypes.FETCH_ORDER;
}

interface FetchOrdersAction {
  type: OrderActionTypes.FETCH_ORDERS;
  payload: IOrder[];
}

interface FetchOrdersErrorAction {
  type: OrderActionTypes.FETCH_ORDERS_ERROR;
  payload: string;
}

interface SetOrderPageAction {
  type: OrderActionTypes.SET_ORDER_PAGE;
  payload: number;
}

interface SetTotalOrderPageAction {
  type: OrderActionTypes.SET_TOTAL_ORDER_PAGE;
  payload: number;
}

export type OrderAction =
  | FetchOrderAction
  | FetchOrdersAction
  | FetchOrdersErrorAction
  | SetOrderPageAction
  | SetTotalOrderPageAction;
