import React, { useEffect, useState } from 'react';
import { Form, Input, Modal, Upload } from 'antd';

import styles from './ImageUpload.module.scss';
import { useHttpUploadAntd } from '../../../../../../hooks/useHttpUploadAntd';
import { RolesInterceptor } from '../../../../../../components/Interceptors/RolesInterceptor';
import { ROLES } from '../../../../../../types/constants';

interface ImageUploadProps {
  fileMainList: any;
  fileOtherList: any;

  onChangeMain: any;
  onChangeOther: any;

  onMainPreview: any;
  onOtherPreview: any;

  previewMainOpen: any;
  previewOtherOpen: any;

  previewMainImage: any;
  previewOtherImage: any;

  previewMainTitle: any;
  previewOtherTitle: any;

  handleMainCancel: any;
  handleOtherCancel: any;

  currentImage: any;
  onChangeAlt: any;
}

export const ImageUpload: React.FC<ImageUploadProps> = ({
  fileMainList,
  fileOtherList,
  onChangeMain,
  onChangeOther,
  onMainPreview,
  onOtherPreview,
  previewMainImage,
  previewOtherImage,
  previewMainOpen,
  previewOtherOpen,
  previewMainTitle,
  previewOtherTitle,
  handleMainCancel,
  handleOtherCancel,
  currentImage,
  onChangeAlt,
}) => {
  const { requestUpload } = useHttpUploadAntd();
  const [currentAlt, setCurrentAlt] = useState<string>();

  useEffect(() => setCurrentAlt(currentImage?.alt || ''), [currentImage?.uid]);

  return (
    <div className={styles.main}>
      <h2>Главное изображение</h2>
      {/*<ImgCrop rotate cropperProps={{ restrictPosition: false }} minZoom={0.5}>*/}
      <Upload
        action="/api/product/file"
        listType="picture-card"
        fileList={fileMainList}
        customRequest={requestUpload}
        onChange={onChangeMain}
        onPreview={onMainPreview}
        accept=".jpg,.jpeg,.png,.gif"
      >
        {fileMainList && fileMainList.length < 1 && '+ Загрузить изображение'}
      </Upload>
      {/*</ImgCrop>*/}
      <Modal
        open={previewMainOpen}
        title={previewMainTitle}
        footer={null}
        onCancel={handleMainCancel}
      >
        <img
          alt={currentImage?.alt}
          style={{ width: '100%' }}
          src={previewMainImage}
        />
        <RolesInterceptor roles={[ROLES.seo, ROLES.content]}>
          <Form.Item
            label="alt"
            labelCol={{ span: 2 }}
            style={{ margin: '10px' }}
          >
            <Input
              placeholder="alt"
              value={currentImage?.alt}
              onChange={(e) => {
                setCurrentAlt(e.target.value);
                onChangeAlt(e, currentImage.uid, true);
              }}
            ></Input>
          </Form.Item>
        </RolesInterceptor>
        {/* <input id="" value={previewMainImage} /> */}
      </Modal>
      <h2>Дополнительные изображения</h2>
      {/*<ImgCrop rotate cropperProps={{ restrictPosition: false }} minZoom={0.5}>*/}
      <Upload
        action="/api/product/file"
        listType="picture-card"
        fileList={fileOtherList}
        customRequest={requestUpload}
        onChange={onChangeOther}
        onPreview={onOtherPreview}
        accept=".jpg,.jpeg,.png,.gif"
      >
        {fileOtherList &&
          fileOtherList.length < 10 &&
          '+ Загрузить изображение'}
      </Upload>
      {/*</ImgCrop>*/}
      <Modal
        open={previewOtherOpen}
        title={previewOtherTitle}
        footer={null}
        onCancel={handleOtherCancel}
      >
        <img
          alt={currentAlt}
          style={{ width: '100%' }}
          src={previewOtherImage}
        />
        <RolesInterceptor roles={[ROLES.seo, ROLES.content]}>
          <Form.Item
            label="alt"
            labelCol={{ span: 2 }}
            style={{ margin: '10px' }}
          >
            <Input
              value={currentAlt}
              onChange={(e) => {
                setCurrentAlt(e.target.value);
                onChangeAlt(e, currentImage.uid, false);
              }}
            ></Input>
          </Form.Item>
        </RolesInterceptor>
      </Modal>
    </div>
  );
};
