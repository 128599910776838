import {
  AttributeAction,
  AttributeActionTypes,
  AttributeState,
} from '../../../types/entities/Attribute/Attribute';

const initialState: AttributeState = {
  attributes: [],
  error: null,
  loading: false,
  currentPage: 1,
  limit: 25,
  totalAttributes: 0,
};

export const attributeReducer = (
  state = initialState,
  action: AttributeAction,
): AttributeState => {
  switch (action.type) {
    case AttributeActionTypes.FETCH_ATTRIBUTE:
      return { ...state, loading: true };
    case AttributeActionTypes.FETCH_ATTRIBUTES_ERROR:
      return { ...state, error: action.payload, loading: false };
    case AttributeActionTypes.FETCH_ATTRIBUTES:
      return {
        ...state,
        error: null,
        attributes: action.payload,
        loading: false,
      };
    case AttributeActionTypes.SET_ATTRIBUTE_PAGE:
      return {
        ...state,
        error: null,
        currentPage: action.payload,
        loading: false,
      };
    case AttributeActionTypes.SET_TOTAL_ATTRIBUTE_PAGE:
      return {
        ...state,
        error: null,
        totalAttributes: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
