import {
  SliderGalleryAction,
  SliderGalleryActionTypes,
  SliderGalleryState,
} from '../../types/entities/SliderGallery';

const initialState: SliderGalleryState = {
  sliderGalleries: [],
  error: null,
  loading: false,
  currentPage: 1,
  limit: 25,
  totalSliderGalleries: 0,
};

export const sliderGalleryReducer = (
  state = initialState,
  action: SliderGalleryAction,
): SliderGalleryState => {
  switch (action.type) {
    case SliderGalleryActionTypes.FETCH_SLIDER_GALLERY:
      return { ...state, loading: true };
    case SliderGalleryActionTypes.FETCH_SLIDER_GALLERIES_ERROR:
      return { ...state, error: action.payload, loading: false };
    case SliderGalleryActionTypes.FETCH_SLIDER_GALLERIES:
      return {
        ...state,
        error: null,
        sliderGalleries: action.payload,
        loading: false,
      };
    case SliderGalleryActionTypes.SET_SLIDER_GALLERY_PAGE:
      return {
        ...state,
        error: null,
        currentPage: action.payload,
        loading: false,
      };
    case SliderGalleryActionTypes.SET_TOTAL_SLIDER_GALLERY_PAGE:
      return {
        ...state,
        error: null,
        totalSliderGalleries: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
