import React from 'react';
import { Link, Route } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import { ComponentsLayout } from '../../layout/ComponentsLayout/ComponentsLayout';
import { PaymentsList } from '../../Pages/Payments/PaymentsList/PaymentsList';
import { AddPayment } from '../../Pages/Payments/Payment/AddPayment/AddPayment';
import { UpdatePayment } from '../../Pages/Payments/Payment/UpdatePayment/UpdatePayment';

export const PaymentRoutes = (
  <>
    <Route
      path="/services/payment/payment/add"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Добавить способ</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <AddPayment />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/services/payment/payment/update/:id"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/services/payment/payment/list">Способы оплаты</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Обновление способа</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <UpdatePayment />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/services/payment/payment/list"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Способы оплаты</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <PaymentsList />
          </ComponentsLayout>
        </>
      }
    />
  </>
);
