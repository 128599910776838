import React from 'react';

import { Button, Form, Input } from 'antd';

interface LoginByPassI {
  loginData: any;
  password: any;

  loginHandler(): void;
}

export const LoginByPass: React.FC<LoginByPassI> = ({
  loginData,
  password,
  loginHandler,
}) => {
  return (
    <>
      <Form labelCol={{ span: 9 }}>
        <Form.Item
          name="name"
          label={'Номер телефона или email'}
          valuePropName="option"
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Пожалуйста, заполните поле!',
            },
          ]}
        >
          <Input
            placeholder="Номер телефона или email"
            {...loginData.forInput}
          />
        </Form.Item>

        <Form.Item
          name="password"
          label="Пароль"
          valuePropName="option"
          hasFeedback
          rules={[
            {
              required: true,
              message: 'Пожалуйста, заполните поле!',
            },
          ]}
        >
          <Input placeholder="Пароль" type="password" {...password.forInput} />
        </Form.Item>
      </Form>
      <Button disabled={!loginData.value} onClick={() => loginHandler()}>
        Логин
      </Button>
    </>
  );
};
