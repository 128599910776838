import React, { useEffect } from 'react';
import FormSelect from '../FormItems/FormSelect';

interface SelectProps {
  value: string;
  form: any;
  name: number;
  options: { label: string; value: number }[];
}

export const SelectCustom: React.FC<SelectProps> = ({
  value,
  form,
  name,
  options,
}) => {
  useEffect(() => {
    form.setFieldsValue({
      ['attributeTypeProductSelect' + name]: value,
    });
  }, []);

  return (
    <FormSelect
      name={'attributeTypeProductSelect' + name}
      options={options}
      showSearch={true}
      labelCol={{ span: 24 }}
    />
  );
};
