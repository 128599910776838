import {
  ImageAction,
  ImageActionTypes,
  ImageState,
} from '../../types/entities/Image';

const initialState: ImageState = {
  images: [],
  error: null,
  loading: false,
  currentPage: 1,
  limit: 25,
  totalImages: 0,
};

export const imageReducer = (
  state = initialState,
  action: ImageAction,
): ImageState => {
  switch (action.type) {
    case ImageActionTypes.FETCH_IMAGE:
      return { ...state, loading: true };
    case ImageActionTypes.FETCH_IMAGES_ERROR:
      return { ...state, error: action.payload, loading: false };
    case ImageActionTypes.FETCH_IMAGES:
      return { ...state, error: null, images: action.payload, loading: false };
    case ImageActionTypes.SET_IMAGE_PAGE:
      return {
        ...state,
        error: null,
        currentPage: action.payload,
        loading: false,
      };
    case ImageActionTypes.SET_TOTAL_IMAGE_PAGE:
      return {
        ...state,
        error: null,
        totalImages: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
