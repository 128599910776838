import React from 'react';

import {
  productConditionOptions,
  transliterate,
} from '../../../../../../helpers/productHelper';
import styles from './MainAttributes.module.scss';
import FormInput from '../../../../../../components/FormItems/FormInput';
import FormSelect from '../../../../../../components/FormItems/FormSelect';
import { FormInstance } from 'antd';

interface MainAttributesProps {
  producerOptions: any;
  form: FormInstance;
}

export const MainAttributes: React.FC<MainAttributesProps> = ({
  producerOptions,
  form,
}) => {
  const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    form.setFieldsValue({
      productSlug: transliterate(event.target.value.toLowerCase()),
    });
  };

  return (
    <div className={styles.main}>
      <FormInput
        name={'productInputTitle'}
        label={'Название'}
        placeholder={'Название'}
        required={true}
        onInput={handleTitleChange}
      />
      <FormInput
        name={'productSku'}
        label={'Sku (number)'}
        placeholder={'Sku'}
        type={'number'}
        maxLength={25}
      />
      <FormInput name={'productSlug'} label={'Slug'} placeholder={'Slug'} />
      <FormInput
        name={'productAveragePrice'}
        label={'Цена (number)'}
        placeholder={'Средняя розничная цена'}
        type={'number'}
        maxLength={200}
      />
      <FormInput
        name={'productDescription'}
        label={'Описание'}
        placeholder={'Описание'}
        textArea={true}
      />
      <FormInput
        name={'productShortDescription'}
        label={'Короткое описание'}
        placeholder={'Короткое описание'}
        textArea={true}
      />
      <FormSelect
        name={'productCondition'}
        label={'Состояние'}
        placeholder={'Выберите состояние'}
        showSearch={true}
        options={productConditionOptions}
      />
      <FormSelect
        name={'productProducer'}
        label={'Производитель'}
        placeholder={'Выберите производителя'}
        showSearch={true}
        options={producerOptions}
      />
    </div>
  );
};
