import React from 'react';
import { Breadcrumb } from 'antd';
import { Link, Route } from 'react-router-dom';

import { ComponentsLayout } from '../../layout/ComponentsLayout/ComponentsLayout';
import { User } from '../../Pages/Users/User/User';
import { UsersList } from '../../Pages/Users/UsersList/UsersList';

export const UserRoutes = (
  <>
    <Route
      path="/users/update/:id"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/users/list">Список пользователей</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Редактирование пользователя</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <User />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/users/list"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Список пользователей</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <UsersList />
          </ComponentsLayout>
        </>
      }
    />
  </>
);
