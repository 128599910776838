import React from 'react';

import { Category } from '../Category';
import { UPDATE_SUBMIT_METHOD } from '../../../../types/constants';

export const UpdateCategory: React.FC = () => {
  const titlePage = 'Обновление категории';
  const submitMethod = UPDATE_SUBMIT_METHOD;

  const props = {
    titlePage: titlePage,
    submitMethod: submitMethod,
  };

  return (
    <>
      <Category {...props} />
    </>
  );
};
