import { IUser } from './User';
import { IProductSlider } from './ProductSlider';

export interface IProductSliderGroup {
  id: number;
  code: number;
  name: string;
  description: string | null;
  createdAt: Date;
  updatedAt: Date;
  createUserId: number;
  createUser: IUser | undefined;
  productSliders: IProductSlider[];
}

export interface ProductSliderGroupState {
  productSliderGroups: IProductSliderGroup[];
  error: string | null;
  loading: boolean;
  currentPage: number;
  limit: number;
  totalProductSliderGroups: number;
}

export enum ProductSliderGroupActionTypes {
  FETCH_PRODUCT_SLIDER_GROUP = 'FETCH_PRODUCT_SLIDER_GROUP',
  FETCH_PRODUCT_SLIDER_GROUPS = 'FETCH_PRODUCT_SLIDER_GROUPS',
  FETCH_PRODUCT_SLIDER_GROUPS_ERROR = 'FETCH_PRODUCT_SLIDER_GROUPS_ERROR',
  SET_PRODUCT_SLIDER_GROUP_PAGE = 'SET_PRODUCT_SLIDER_GROUP_PAGE',
  SET_TOTAL_PRODUCT_SLIDER_GROUP_PAGE = 'SET_TOTAL_PRODUCT_SLIDER_GROUP_PAGE',
}

interface FetchProductSliderGroupAction {
  type: ProductSliderGroupActionTypes.FETCH_PRODUCT_SLIDER_GROUP;
}

interface FetchProductSliderGroupsAction {
  type: ProductSliderGroupActionTypes.FETCH_PRODUCT_SLIDER_GROUPS;
  payload: IProductSliderGroup[];
}

interface FetchProductSliderGroupsErrorAction {
  type: ProductSliderGroupActionTypes.FETCH_PRODUCT_SLIDER_GROUPS_ERROR;
  payload: string;
}

interface SetProductSliderGroupPageAction {
  type: ProductSliderGroupActionTypes.SET_PRODUCT_SLIDER_GROUP_PAGE;
  payload: number;
}

interface SetTotalProductSliderGroupPageAction {
  type: ProductSliderGroupActionTypes.SET_TOTAL_PRODUCT_SLIDER_GROUP_PAGE;
  payload: number;
}

export type ProductSliderGroupAction =
  | FetchProductSliderGroupAction
  | FetchProductSliderGroupsAction
  | FetchProductSliderGroupsErrorAction
  | SetProductSliderGroupPageAction
  | SetTotalProductSliderGroupPageAction;
