import { Dispatch } from 'redux';

import { ApiUrl } from '../../types/ApiUrl';
import {
  CategoryAction,
  CategoryActionTypes,
} from '../../types/entities/Category';
import { $api } from '../../http';

interface queryCategoryParamsInterface {
  limit?: any;
  page?: any;
  sortId?: string;
  id?: string;
  parentId?: string;
  status?: string;
}

export const fetchCategoriesForSelect = () => {
  return async (dispatch: Dispatch<CategoryAction>) => {
    try {
      dispatch({ type: CategoryActionTypes.FETCH_CATEGORY });
      const response = await $api.get(`${ApiUrl.CATEGORY_SELECT}`);
      console.log(response);
      dispatch({
        type: CategoryActionTypes.FETCH_CATEGORIES_SELECT,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: CategoryActionTypes.FETCH_CATEGORIES_ERROR,
        payload: 'Произошла ошибка при загрузке категорий',
      });
    }
  };
};

export const fetchCategoriesList = (
  queryParams: queryCategoryParamsInterface,
) => {
  const {
    limit = '25',
    page = 1,
    sortId = 'DESC',
    id,
    parentId,
    status,
  } = queryParams;

  const urlCategoryParams = new URLSearchParams();
  urlCategoryParams.append('limit', limit);
  urlCategoryParams.append('page', page);
  urlCategoryParams.append('sortId', sortId);

  if (id !== undefined) urlCategoryParams.append('id', id);
  if (parentId !== undefined) urlCategoryParams.append('parentId', parentId);
  if (status !== undefined) urlCategoryParams.append('status', status);

  return async (dispatch: Dispatch<CategoryAction>) => {
    try {
      dispatch({ type: CategoryActionTypes.FETCH_CATEGORY });
      const response = await $api.get(
        `${ApiUrl.CATEGORY}?${urlCategoryParams.toString()}`,
      );
      dispatch({
        type: CategoryActionTypes.FETCH_CATEGORIES,
        payload: response.data.items,
      });
      dispatch({
        type: CategoryActionTypes.SET_TOTAL_CATEGORY_PAGE,
        payload: response.data.total,
      });
      dispatch({ type: CategoryActionTypes.SET_CATEGORY_PAGE, payload: page });
    } catch (e) {
      dispatch({
        type: CategoryActionTypes.FETCH_CATEGORIES_ERROR,
        payload: 'Произошла ошибка при загрузке категорий',
      });
    }
  };
};
