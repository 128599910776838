import { Link, Route } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { ComponentsLayoutBlock } from '../../layout/ComponentLayoutBlock/ComponentLayoutBlock';
import { KkmPage } from '../../Pages/Kkm/Kkm';


export const KkmRoutes = (
  <>
    <Route
      path="/kkm"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Чеки ОФД</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayoutBlock>
            <KkmPage />
          </ComponentsLayoutBlock>
        </>
      }
    />
  </>
);
