import React, { useEffect, useState } from 'react';
import { Button, Checkbox, Col, Form, Pagination, Row, Table } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import styles from './ServicesList.module.scss';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useActions } from '../../../hooks/useActions';
import { statusCategoryOptions } from '../../../helpers/categoryHelper';
import { StatusEditor } from '../../../components/Editors/StatusEditor';
import { ApiUrl } from '../../../types/ApiUrl';
import FormTreeSelect from '../../../components/FormItems/FormTreeSelect';
import FormSelect from '../../../components/FormItems/FormSelect';
import { IService } from '../../../types/entities/Service';
import { $api } from '../../../http';
import FormCheckbox from '../../../components/FormItems/FormCheckbox';
import { ICategory } from '../../../types/entities/Category';

export const ServicesList: React.FC = () => {
  const [services, setServices] = useState<IService[]>([]);

  const [sort, setSort] = useState('DESC');
  const [edit, setEdit] = useState(false);

  const { fetchCategoriesList } = useActions();

  const [form] = Form.useForm();

  const fetchData = async () => {
    console.log(ApiUrl.SERVICES);
    const response = await $api.get(`${ApiUrl.SERVICES}/all`);
    setServices(response.data);
  };

  useEffect(() => {
    fetchData();
  }, [edit]);

  const onShowSizeChange = (currentPage: number, limit: number) => {
    let parents;
    if (Array.isArray(form.getFieldValue('parent'))) {
      parents = form.getFieldValue('parent').join(',');
    }
    let categoryIds;
    if (Array.isArray(form.getFieldValue('id'))) {
      categoryIds = form.getFieldValue('id').join(',');
    }
    fetchCategoriesList({
      limit: limit,
      page: currentPage,
      sortId: sort,
      id: categoryIds,
      parentId: parents,
      status: form.getFieldValue('status'),
    });
  };

  const handlePagination = (currentPage: number, limit: number) => {
    let parents;
    if (Array.isArray(form.getFieldValue('parent'))) {
      parents = form.getFieldValue('parent').join(',');
    }
    let categoryIds;
    if (Array.isArray(form.getFieldValue('id'))) {
      categoryIds = form.getFieldValue('id').join(',');
    }
    fetchCategoriesList({
      limit: limit,
      page: currentPage,
      sortId: sort,
      id: categoryIds,
      parentId: parents,
      status: form.getFieldValue('status'),
    });
  };

  const applyFilters = () => {
    let categoryIds;
    if (Array.isArray(form.getFieldValue('id'))) {
      categoryIds = form.getFieldValue('id').join(',');
    }
    let parents;
    if (Array.isArray(form.getFieldValue('parent'))) {
      parents = form.getFieldValue('parent').join(',');
    }
    fetchData();
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.field === 'id') {
    }
  };

  const columns = [
    {
      title: 'ID услуги',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => {
        return (
          <div key={id}>
            <Link to={`/services/update/${id}`}>{id}</Link>
          </div>
        );
      },
      sorter: true,
    },
    {
      title: 'Наименование услуги',
      dataIndex: 'title',
      key: 'title',
      render: (title: string) => {
        if (title) {
          return <>{title}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Код',
      dataIndex: 'code',
      key: 'code',
      render: (code: string) => {
        if (code) {
          return <>{code}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Стандартная цена',
      dataIndex: 'defaultPrice',
      key: 'defaultPrice',
      render: (defaultPrice: number) => {
        if (defaultPrice) {
          return <>{defaultPrice}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Цена за этаж',
      dataIndex: 'pricePerFloor',
      key: 'pricePerFloor',
      render: (pricePerFloor: number) => {
        if (pricePerFloor) {
          return <>{pricePerFloor}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Для всех категорий',
      dataIndex: 'forAll',
      key: 'forAll',
      render: (forAll: boolean) => {
        return <Checkbox checked={forAll} />;
      },
    },
    {
      title: 'Категории',
      dataIndex: 'categories',
      key: 'categories',
      render: (categories: ICategory[]) => {
        if (categories.length === 0) return 'Пусто';
        return categories.map((category) => category.name).toString();
      },
    },
  ];

  return (
    <div className={styles.main}>
      <h1>Список услуг</h1>
      <h4 style={{ textAlign: 'left' }}>Фильтры полей</h4>
      <Table
        bordered
        columns={columns}
        dataSource={services}
        pagination={false}
        onChange={handleChange}
        rowKey="id"
      />
    </div>
  );
};
