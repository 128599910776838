import { Dispatch } from 'redux';

import { ApiUrl } from '../../types/ApiUrl';
import {
  DeliveryAction,
  DeliveryActionTypes,
} from '../../types/entities/Delivery';
import { $api } from '../../http';

interface queryDeliveryParamsInterface {
  limit?: any;
  page?: any;
  sortId?: string;
}

export const fetchDeliveriesForSelect = () => {
  return async (dispatch: Dispatch<DeliveryAction>) => {
    try {
      dispatch({ type: DeliveryActionTypes.FETCH_DELIVERY });
      const response = await $api.get(`${ApiUrl.DELIVERY_SELECT}`);
      dispatch({
        type: DeliveryActionTypes.FETCH_DELIVERIES_SELECT,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: DeliveryActionTypes.FETCH_DELIVERIES_ERROR,
        payload: 'Произошла ошибка при загрузке способов доставки',
      });
    }
  };
};

export const fetchDeliveriesList = (
  queryParams: queryDeliveryParamsInterface,
) => {
  const { limit = '25', page = 1, sortId = 'DESC' } = queryParams;

  const urlDeliveryParams = new URLSearchParams();
  urlDeliveryParams.append('limit', limit);
  urlDeliveryParams.append('page', page);
  urlDeliveryParams.append('sortId', sortId);

  return async (dispatch: Dispatch<DeliveryAction>) => {
    try {
      dispatch({ type: DeliveryActionTypes.FETCH_DELIVERY });
      const response = await $api.get(
        `${ApiUrl.DELIVERY}?${urlDeliveryParams.toString()}`,
      );
      dispatch({
        type: DeliveryActionTypes.FETCH_DELIVERIES,
        payload: response.data.items,
      });
      dispatch({
        type: DeliveryActionTypes.SET_TOTAL_DELIVERY_PAGE,
        payload: response.data.total,
      });
      dispatch({ type: DeliveryActionTypes.SET_DELIVERY_PAGE, payload: page });
    } catch (e) {
      dispatch({
        type: DeliveryActionTypes.FETCH_DELIVERIES_ERROR,
        payload: 'Произошла ошибка при загрузке способов доставки',
      });
    }
  };
};
