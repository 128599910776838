import React from 'react';
import { Link, Route } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import { ComponentsLayout } from '../../layout/ComponentsLayout/ComponentsLayout';
import { OrderHistoriesList } from '../../Pages/OrderHistories/OrderHistoriesList';

export const OrderHistoryRoutes = (
  <>
    <Route
      path="/order-history/list"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>История заказов</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <OrderHistoriesList />
          </ComponentsLayout>
        </>
      }
    />
  </>
);
