import { Checkbox, Form } from "antd";
import React from "react";

interface ICheckbox {
  name: string;
  checked: boolean;
  text?: string | React.ReactNode;
  labelCol?: { span: number };
  onChange?: any;
}

const FormCheckbox: React.FC<ICheckbox> = ({
                                             name,
                                             checked,
                                             text,
                                             labelCol,
                                             onChange
                                           }) => {
  return (
    <Form.Item name={name} valuePropName={"checked"} labelCol={labelCol}>
      {text ? (
        <Checkbox checked={checked}>{text}</Checkbox>
      ) : (
        <Checkbox checked={checked} onChange={onChange} />
      )}
    </Form.Item>
  );
};

export default FormCheckbox;
