import {
  MenuItemAction,
  MenuItemActionTypes,
  MenuItemState,
} from '../../../types/entities/Menu/MenuItem';

const initialState: MenuItemState = {
  menuItems: [],
  menuItemsList: [],
  error: null,
  loading: false,
  currentPage: 1,
  limit: 25,
  totalMenuItems: 0,
};

export const menuItemReducer = (
  state = initialState,
  action: MenuItemAction,
): MenuItemState => {
  switch (action.type) {
    case MenuItemActionTypes.FETCH_MENU_ITEM:
      return { ...state, loading: true };
    case MenuItemActionTypes.FETCH_MENU_ITEMS_ERROR:
      return { ...state, error: action.payload, loading: false };
    case MenuItemActionTypes.FETCH_MENU_ITEMS:
      return {
        ...state,
        error: null,
        menuItemsList: action.payload,
        loading: false,
      };
    case MenuItemActionTypes.SET_MENU_ITEM_PAGE:
      return {
        ...state,
        error: null,
        currentPage: action.payload,
        loading: false,
      };
    case MenuItemActionTypes.SET_TOTAL_MENU_ITEM_PAGE:
      return {
        ...state,
        error: null,
        totalMenuItems: action.payload,
        loading: false,
      };
    case MenuItemActionTypes.FETCH_MENU_ITEMS_SELECT:
      return {
        ...state,
        error: null,
        menuItems: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
