import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Pagination, Row, Table } from 'antd';

import styles from './OrderHistoriesList.module.scss';
import { useTypedSelector } from '../../hooks/useTypedSelector';
import { useActions } from '../../hooks/useActions';
import FormInput from '../../components/FormItems/FormInput';

export const OrderHistoriesList: React.FC = () => {
  const { orderHistories, limit, currentPage, totalOrderHistories } =
    useTypedSelector((state) => state.orderHistory);

  const [sort, setSort] = useState('DESC');

  const { fetchOrderHistories } = useActions();

  const [form] = Form.useForm();

  useEffect(() => {
    fetchOrderHistories({
      limit: limit,
      page: currentPage,
      sortId: sort,
      id: form.getFieldValue('id'),
      orderId: form.getFieldValue('orderId'),
      field: form.getFieldValue('field'),
      newValue: form.getFieldValue('newValue'),
      oldValue: form.getFieldValue('oldValue'),
    });
  }, []);

  const onShowSizeChange = (currentPage: number, limit: number) => {
    fetchOrderHistories({
      limit: limit,
      page: currentPage,
      sortId: sort,
      id: form.getFieldValue('id'),
      orderId: form.getFieldValue('orderId'),
      field: form.getFieldValue('field'),
      newValue: form.getFieldValue('newValue'),
      oldValue: form.getFieldValue('oldValue'),
    });
  };
  const handlePagination = (currentPage: number, limit: number) => {
    fetchOrderHistories({
      limit: limit,
      page: currentPage,
      sortId: sort,
      id: form.getFieldValue('id'),
      orderId: form.getFieldValue('orderId'),
      field: form.getFieldValue('field'),
      newValue: form.getFieldValue('newValue'),
      oldValue: form.getFieldValue('oldValue'),
    });
  };

  const applyFilters = () => {
    fetchOrderHistories({
      limit: limit,
      page: 1,
      sortId: sort,
      id: form.getFieldValue('id'),
      orderId: form.getFieldValue('orderId'),
      field: form.getFieldValue('field'),
      newValue: form.getFieldValue('newValue'),
      oldValue: form.getFieldValue('oldValue'),
    });
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.field === 'id') {
      if (sorter.order === 'ascend') {
        setSort('ASC');
        fetchOrderHistories({ limit: limit, page: currentPage, sortId: 'ASC' });
      } else {
        setSort('DESC');
        fetchOrderHistories({
          limit: limit,
          page: currentPage,
          sortId: 'DESC',
        });
      }
    }
  };

  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => {
        return <>{id}</>;
      },
      sorter: true,
    },
    {
      title: 'ID заказа',
      dataIndex: 'orderId',
      key: 'orderId',
      render: (orderId: string) => {
        if (orderId) {
          return <>{orderId}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Поле',
      dataIndex: 'field',
      key: 'field',
      render: (field: string) => {
        if (field) {
          return <>{field}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Новое значение',
      dataIndex: 'newValue',
      key: 'newValue',
      render: (newValue: string) => {
        if (newValue) {
          return <>{newValue}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Старое значение',
      dataIndex: 'oldValue',
      key: 'oldValue',
      render: (oldValue: string) => {
        if (oldValue) {
          return <>{oldValue}</>;
        } else {
          return 'Не указано';
        }
      },
    },
  ];

  return (
    <div className={styles.main}>
      <h1>История заказов</h1>
      <h4 style={{ textAlign: 'left' }}>Фильтры полей</h4>
      <Form form={form}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={8}>
            <FormInput
              name={'id'}
              label={'ID'}
              placeholder={'Введите ID'}
              search={true}
              onSearch={(value) => {
                fetchOrderHistories({
                  limit: limit,
                  page: currentPage,
                  sortId: sort,
                  id: value,
                });
              }}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={8}>
            <FormInput
              name={'orderId'}
              label={'ID заказа'}
              placeholder={'Введите Id заказа'}
              search={true}
              onSearch={(value) => {
                fetchOrderHistories({
                  limit: limit,
                  page: currentPage,
                  sortId: sort,
                  orderId: value,
                });
              }}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={8}>
            <FormInput
              name={'field'}
              label={'Поле'}
              placeholder={'Введите поле'}
              search={true}
              onSearch={(value) => {
                fetchOrderHistories({
                  limit: limit,
                  page: currentPage,
                  sortId: sort,
                  field: value,
                });
              }}
              labelCol={{ span: 24 }}
            />
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={8}>
            <FormInput
              name={'newValue'}
              label={'Новое значение'}
              placeholder={'Введите новое значение'}
              search={true}
              onSearch={(value) => {
                fetchOrderHistories({
                  limit: limit,
                  page: currentPage,
                  sortId: sort,
                  newValue: value,
                });
              }}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={8}>
            <FormInput
              name={'oldValue'}
              label={'Старое значение'}
              placeholder={'Введите старое значение'}
              search={true}
              onSearch={(value) => {
                fetchOrderHistories({
                  limit: limit,
                  page: currentPage,
                  sortId: sort,
                  oldValue: value,
                });
              }}
              labelCol={{ span: 24 }}
            />
          </Col>
        </Row>
        <Button
          style={{ marginBottom: 20 }}
          type="primary"
          htmlType="submit"
          onClick={applyFilters}
        >
          Применить фильтры
        </Button>
        <Button
          style={{ marginLeft: 20 }}
          type="ghost"
          htmlType="reset"
          onClick={() => {
            return fetchOrderHistories({
              limit: limit,
              page: 1,
              sortId: sort,
            });
          }}
        >
          Очистить фильтры
        </Button>
      </Form>
      <Pagination
        style={{ marginBottom: 20 }}
        total={totalOrderHistories}
        current={currentPage}
        showQuickJumper={true}
        onChange={handlePagination}
        onShowSizeChange={onShowSizeChange}
        pageSizeOptions={[10, 25, 50, 100]}
        defaultPageSize={25}
      />
      <Table
        bordered
        columns={columns}
        dataSource={orderHistories}
        pagination={false}
        onChange={handleChange}
        rowKey="id"
      />
    </div>
  );
};
