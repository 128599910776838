import React from 'react';
import { Link, Route } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import { AddShop } from '../../Pages/Shops/Shop/AddShop/AddShop';
import { ComponentsLayout } from '../../layout/ComponentsLayout/ComponentsLayout';
import { UpdateShop } from '../../Pages/Shops/Shop/UpdateShop/UpdateShop';
import { ShopsList } from '../../Pages/Shops/ShopsList/ShopsList';

export const ShopRoutes = (
  <>
    <Route
      path="/shops/add"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Добавить магазин</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <AddShop />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/shops/update/:id"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/shops/list">Список магазинов</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Обновление магазина</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <UpdateShop />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/shops/list"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Список магазинов</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <ShopsList />
          </ComponentsLayout>
        </>
      }
    />
  </>
);
