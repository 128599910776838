import {
  PaymentOnlineAction,
  PaymentOnlineActionTypes,
  PaymentOnlineState,
} from '../../../types/entities/Payment/PaymentOnline';

const initialState: PaymentOnlineState = {
  paymentsOnline: [],
  error: null,
  loading: false,
  currentPage: 1,
  limit: 25,
  totalPaymentsOnline: 0,
};

export const paymentOnlineReducer = (
  state = initialState,
  action: PaymentOnlineAction,
): PaymentOnlineState => {
  switch (action.type) {
    case PaymentOnlineActionTypes.FETCH_PAYMENT_ONLINE:
      return { ...state, loading: true };
    case PaymentOnlineActionTypes.FETCH_PAYMENTS_ONLINE_ERROR:
      return { ...state, error: action.payload, loading: false };
    case PaymentOnlineActionTypes.FETCH_PAYMENTS_ONLINE:
      return {
        ...state,
        error: null,
        paymentsOnline: action.payload,
        loading: false,
      };
    case PaymentOnlineActionTypes.SET_PAYMENT_ONLINE_PAGE:
      return {
        ...state,
        error: null,
        currentPage: action.payload,
        loading: false,
      };
    case PaymentOnlineActionTypes.SET_TOTAL_PAYMENT_ONLINE_PAGE:
      return {
        ...state,
        error: null,
        totalPaymentsOnline: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
