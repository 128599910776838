import {
  TypeAction,
  TypeActionTypes,
  TypeState,
} from '../../types/entities/Type';

const initialState: TypeState = {
  types: [],
  error: null,
  loading: false,
};

export const typeReducer = (
  state = initialState,
  action: TypeAction,
): TypeState => {
  switch (action.type) {
    case TypeActionTypes.FETCH_TYPE:
      return { ...state, loading: true };
    case TypeActionTypes.FETCH_TYPES_ERROR:
      return { ...state, error: action.payload, loading: false };
    case TypeActionTypes.FETCH_TYPES:
      return {
        ...state,
        error: null,
        types: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
