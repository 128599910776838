import React, { useCallback, useEffect, useState } from "react";
import { Checkbox } from "antd";

import { useInput } from "../../../hooks/useInput";
import { useActions } from "../../../hooks/useActions";
import { searchTypeValidator, useValidator, ValidateType } from "../../../hooks/useValidator";
import styles from "../Auth.module.scss";
import { OpenNotification } from "../../../components/AntdCustom/Notification";
import { useHttp } from "../../../hooks/useHttp";
import { ApiUrl } from "../../../types/ApiUrl";
import { LoginByPass } from "./LoginByPass";
import { LoginByCode } from "./LoginByCode";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";

export const Login: React.FC = () => {
  const { request, httpError, clearError } = useHttp();
  const { error } = useTypedSelector((state) => state.auth);
  const loginData = useInput(""); //номер телефона или email для входа/регистрации
  const password = useInput("");
  const codePass = useInput("");
  const [byPassword, setByPassword] = useState(false);
  const { loginByCode, loginByPassword } = useActions();
  const [codeFlag, setCodeFlag] = useState(false);
  const [targetDate, setTargetDate] = useState(new Date().getTime());
  const { executeRecaptcha } = useGoogleReCaptcha();


  useEffect(() => {
    if (error)
      OpenNotification({
        message: error
      });
  }, [error]);

  useEffect(() => {
    if (httpError)
      OpenNotification({
        message: httpError
      });
    clearError();
  }, [httpError, clearError]);

  const changeFlagByPassHandler = () => {
    setByPassword(!byPassword);
  };

  const loginHandler = useCallback(async () => {
    try {
      const useValidatorParams = {
        value: loginData.value,
        listValidateTypes: [ValidateType.email, ValidateType.phone]
      };
      if (byPassword && useValidator(useValidatorParams).includes(true)) {
        await loginByPassword(loginData.value, password.value);
      } else if (useValidator(useValidatorParams).includes(true)) {
        await loginByCode(loginData.value, codePass.value);
      } else {
        OpenNotification({
          message: "Невалидный номер телефона или email"
        });
      }
      setTargetDate(new Date().getTime());
    } catch (e) {
      console.log(e);
    }
  }, [
    loginData.value,
    byPassword,
    loginByPassword,
    password.value,
    loginByCode,
    codePass.value
  ]);

  const getAuthCodeHandler = useCallback(async () => {
    try {
      const useValidatorParams = {
        value: loginData.value,
        listValidateTypes: [ValidateType.email, ValidateType.phone]
      };

      if (useValidator(useValidatorParams).includes(true)) {
        const field = searchTypeValidator(useValidatorParams);
        setCodeFlag(true);

        if (executeRecaptcha) {
          await executeRecaptcha("enquiryFormSubmit").then((gReCaptchaToken) => {
            document.cookie = `gRecaptchaToken=${gReCaptchaToken}`
          });
        }

        await request({
          url: ApiUrl.GET_AUTH_CODE,
          method: "post",
          body: {
            [field]: loginData.value
          }
        });
      } else {
        OpenNotification({
          message: "Невалидный номер телефона или email"
        });
      }
    } catch (e: any) {
      let dateDeathCode =
        new Date().getTime() +
        +(process.env.TIME_DEATH_OF_AUTH_CODE || "8") * 60 * 1000;
      if (e.response) {
        dateDeathCode = new Date(e.response.data.dateDeathCode).getTime();
      }
      setTargetDate(dateDeathCode);
    }
  }, [loginData.value, codeFlag, targetDate]);

  return (
    <div className={styles.main}>
      <h1>Войти в аккаунт</h1>
      {byPassword ? (
        <LoginByPass
          loginData={loginData}
          password={password}
          loginHandler={loginHandler}
        />
      ) : (
        <LoginByCode
          flag={codeFlag}
          loginData={loginData}
          codePass={codePass}
          targetDate={targetDate}
          loginHandler={loginHandler}
          getCodeHandler={getAuthCodeHandler}
        />
      )}
      <br />
      <Checkbox checked={byPassword} onChange={changeFlagByPassHandler}>
        Войти с паролем
      </Checkbox>
    </div>
  );
};
