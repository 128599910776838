import React from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
import { Layout } from 'antd';

import './App.css';
import { useRoutes } from './routes/routes';
import { Sidebar } from './components/Sidebar/Sidebar';

function App() {
  const routes = useRoutes();

  return (
    <div className="App">
      <Router>
        <div className="div-router">
          <Sidebar />
          <Layout className="site-layout">
            <Layout.Content
              style={{
                margin: '0 10px',
                padding: 24,
                minHeight: 360,
              }}
            >
              {routes}
            </Layout.Content>
          </Layout>
        </div>
      </Router>
    </div>
  );
}

export default App;
