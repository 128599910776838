import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Pagination, Popconfirm, Row, Table } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { useActions } from '../../../hooks/useActions';
import styles from '../../Categories/CategoriesList/CategoriesList.module.scss';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import {
  managerOrderOptions,
  statusOrderOptions,
} from '../../../helpers/orderHelper';
import { SelectOptionUserHelper } from '../../../helpers/selectHelper';
import FormInput from '../../../components/FormItems/FormInput';
import FormSelect from '../../../components/FormItems/FormSelect';
import { useHttp } from '../../../hooks/useHttp';
import NotificationAlert from '../../../components/Notification';
import { ApiUrl } from '../../../types/ApiUrl';

export const OrdersList: React.FC = () => {
  const { orders, limit, currentPage, totalOrders } = useTypedSelector(
    (state) => state.order,
  );
  const { users } = useTypedSelector((state) => state.user);
  const { fetchOrders, fetchUsersForSelect } = useActions();
  const [sortId, setSortId] = useState('DESC');
  const [sortPrice, setSortPrice] = useState('');

  const { request } = useHttp();

  const userOptions = SelectOptionUserHelper(users);

  const [form] = Form.useForm();

  useEffect(() => {
    fetchUsersForSelect();
    fetchOrders({
      limit: limit,
      page: currentPage,
      sortId: sortId,
      sortPrice: sortPrice,
      name: form.getFieldValue('name'),
      phone: form.getFieldValue('phone'),
      totalPrice: form.getFieldValue('totalPrice'),
      userId: form.getFieldValue('user'),
      statusId: form.getFieldValue('status'),
      managerId: form.getFieldValue('manager'),
      numberOneC: form.getFieldValue('numberOneC'),
      numberID: form.getFieldValue('numberID'),
    });
  }, []);

  const onShowSizeChange = (currentPage: number, limit: number) => {
    fetchOrders({
      limit: limit,
      page: currentPage,
      sortId: sortId,
      sortPrice: sortPrice,
      name: form.getFieldValue('name'),
      phone: form.getFieldValue('phone'),
      totalPrice: form.getFieldValue('totalPrice'),
      userId: form.getFieldValue('user'),
      statusId: form.getFieldValue('status'),
      managerId: form.getFieldValue('manager'),
      numberOneC: form.getFieldValue('numberOneC'),
      numberID: form.getFieldValue('numberID'),
    });
  };

  const handlePagination = (currentPage: number, limit: number) => {
    fetchOrders({
      limit: limit,
      page: currentPage,
      sortId: sortId,
      sortPrice: sortPrice,
      name: form.getFieldValue('name'),
      phone: form.getFieldValue('phone'),
      totalPrice: form.getFieldValue('totalPrice'),
      userId: form.getFieldValue('user'),
      statusId: form.getFieldValue('status'),
      managerId: form.getFieldValue('manager'),
      numberOneC: form.getFieldValue('numberOneC'),
      numberID: form.getFieldValue('numberID'),
    });
  };

  const applyFilters = () => {
    fetchOrders({
      limit: limit,
      page: 1,
      sortId: sortId,
      sortPrice: sortPrice,
      name: form.getFieldValue('name'),
      phone: form.getFieldValue('phone'),
      totalPrice: form.getFieldValue('totalPrice'),
      userId: form.getFieldValue('user'),
      statusId: form.getFieldValue('status'),
      managerId: form.getFieldValue('manager'),
      numberOneC: form.getFieldValue('numberOneC'),
      numberID: form.getFieldValue('numberID'),
    });
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    let sortById = 'DESC';
    let sortByPrice;

    if (sorter.field === 'id') {
      if (sorter.order === 'ascend') {
        sortById = 'ASC';
        setSortId('ASC');
      } else if (sorter.order === 'descend') {
        setSortId('DESC');
      }
    }
    if (sorter.field === 'totalPrice') {
      if (sorter.order === 'ascend') {
        sortByPrice = 'ASC';
        setSortPrice('ASC');
      } else if (sorter.order === 'descend') {
        sortByPrice = 'DESC';
        setSortPrice('DESC');
      } else setSortPrice('');
    }

    fetchOrders({
      limit: limit,
      page: currentPage,
      sortId: sortById,
      sortPrice: sortByPrice,
      name: form.getFieldValue('name'),
      phone: form.getFieldValue('phone'),
      totalPrice: form.getFieldValue('totalPrice'),
      userId: form.getFieldValue('user'),
      statusId: form.getFieldValue('status'),
      managerId: form.getFieldValue('manager'),
      numberOneC: form.getFieldValue('numberOneC'),
      numberID: form.getFieldValue('numberID'),
    });
  };

  const deleteOrder = async(id: number) => {
    try {
      await request({
        url: `${ApiUrl.ORDER}/${id}`,
        method: 'delete',
      });
      await fetchOrders({
        limit: limit,
        page: currentPage,
        sortId: sortId,
        sortPrice: sortPrice,
        name: form.getFieldValue('name'),
        phone: form.getFieldValue('phone'),
        totalPrice: form.getFieldValue('totalPrice'),
        userId: form.getFieldValue('user'),
        statusId: form.getFieldValue('status'),
        managerId: form.getFieldValue('manager'),
        numberOneC: form.getFieldValue('numberOneC'),
        numberID: form.getFieldValue('numberID'),
      });;
      return NotificationAlert('success', 'Заказ удалён');
    } catch (e) {
      return NotificationAlert('error', 'Не удалось удалить заказ');
    }
  }

  const columns = [
    {
      title: '№',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => {
        return (
          <div key={id}>
            <Link to={`/orders/update/${id}`}>{id}</Link>
          </div>
        );
      },
      sorter: true,
    },
    {
      title: 'Создан',
      dataIndex: 'date',
      key: 'date',
      render: (date: string) => {
        const createDate = new Date(date).toLocaleString('ru', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
        });
        if (date) {
          return <>{createDate}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Клиент',
      dataIndex: 'name',
      key: 'name',
      render: (name: string) => {
        if (name) {
          return <>{name}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Телефон',
      dataIndex: 'phone',
      key: 'phone',
      render: (phone: string) => {
        if (phone) {
          return <>{phone}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Сумма',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      render: (totalPrice: number) => {
        if (totalPrice) {
          return <>{totalPrice} руб.</>;
        } else {
          return 'Не указано';
        }
      },
      sorter: true,
    },
    {
      title: 'Профит',
      dataIndex: 'totalPrice',
      key: 'totalPrice',
      render: (totalPrice: number) => {
        if (totalPrice) {
          return <>{totalPrice} руб.</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Статус',
      dataIndex: 'statusId',
      key: 'statusId',
      render: (statusId: any) => {
        switch (statusId) {
          case 1:
            return <>{statusOrderOptions[0].label}</>;
          case 2:
            return <>{statusOrderOptions[1].label}</>;
          case 3:
            return <>{statusOrderOptions[2].label}</>;
          case 4:
            return <>{statusOrderOptions[3].label}</>;
          case 6:
            return <>{statusOrderOptions[4].label}</>;
          case 7:
            return <>{statusOrderOptions[5].label}</>;
          case 8:
            return <>{statusOrderOptions[6].label}</>;
          case 9:
            return <>{statusOrderOptions[7].label}</>;
          case 10:
            return <>{statusOrderOptions[8].label}</>;
          case 11:
            return <>{statusOrderOptions[9].label}</>;
          case 12:
            return <>{statusOrderOptions[10].label}</>;
          case 13:
            return <>{statusOrderOptions[11].label}</>;
          case 14:
            return <>{statusOrderOptions[12].label}</>;
          default:
            return 'Не указано';
        }
      },
    },
    {
      title: 'Менеджер',
      key: 'managerId',
      render: (data: any) => {
        if (data.managerRelations) {
          return (
            <>
              {data.managerRelations.firstName +
                ' ' +
                data.managerRelations.lastName}
            </>
          );
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Номер 1С',
      dataIndex: 'numberOneC',
      key: 'numberOneC',
      render: (numberOneC: string) => {
        if (numberOneC) {
          return <>{numberOneC}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Канал связи',
      key: 'chanel',
      render: (data: any) => {
        const channels = [];
        if (data.whatsapp) {
          channels.push('whatsApp');
        }
        if (data.viber) {
          channels.push('viber');
        }
        if (data.telegram) {
          channels.push('telegram');
        }
        if (channels.length === 0) return <>Не указано</>;
        return <>{channels.join(', ')}</>;
      },
    },
    {
      title: 'Действия с заказом',
      dataIndex: 'id',
      key: 'edit',
      render: (id: number) => {
        return (
          <>
            <Link to={`/orders/update/${id}`}>
              <EditOutlined style={{ marginLeft: 25 }} />
            </Link>
            <Button type="link" style={{ color: 'inherit' }}>
              <Popconfirm
                title={'Вы точно хотите удалить заказ?'}
                onConfirm={() => deleteOrder(id)}
              >
                <DeleteOutlined style={{ cursor: 'pointer', fontSize: 22 }} />
              </Popconfirm>
            </Button>
          </>
        );
      },
    },
  ];

  return (
    <div className={styles.main}>
      <h1>Список заказов</h1>
      <h4 style={{ textAlign: 'left' }}>Фильтры полей</h4>
      <Form form={form}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={8}>
            <FormInput
              name={'name'}
              label={'Клиент'}
              placeholder={'Выберите клиента'}
              search={true}
              onSearch={(value) => {
                fetchOrders({
                  limit: limit,
                  page: currentPage,
                  sortId: sortId,
                  sortPrice: sortPrice,
                  name: value,
                });
              }}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={8}>
            <FormSelect
              name={'user'}
              label={'Авторизованный клиент'}
              placeholder={'Выберите клиента'}
              options={userOptions}
              showSearch={true}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={8}>
            <FormInput
              name={'phone'}
              label={'Телефон'}
              placeholder={'Поиск по номеру телефона'}
              search={true}
              onSearch={(value) => {
                fetchOrders({
                  limit: limit,
                  page: currentPage,
                  sortId: sortId,
                  sortPrice: sortPrice,
                  phone: value,
                });
              }}
              labelCol={{ span: 24 }}
            />
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={8}>
            <FormInput
              name={'totalPrice'}
              label={'Сумма'}
              placeholder={'Поиск по сумме'}
              search={true}
              type={'number'}
              onSearch={(value) => {
                fetchOrders({
                  limit: limit,
                  page: currentPage,
                  sortId: sortId,
                  sortPrice: sortPrice,
                  totalPrice: value,
                });
              }}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={8}>
            <FormSelect
              name={'status'}
              label={'Статус'}
              placeholder={'Выберите статус'}
              options={statusOrderOptions}
              showSearch={true}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={8}>
            <FormSelect
              name={'manager'}
              label={'Менеджер'}
              placeholder={'Выберите менеджера'}
              options={managerOrderOptions}
              showSearch={true}
              labelCol={{ span: 24 }}
            />
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={8}>
            <FormInput
              name={'numberOneC'}
              label={'Номер 1С'}
              placeholder={'Введите номер 1C'}
              search={true}
              onSearch={(value) => {
                fetchOrders({
                  limit: limit,
                  page: currentPage,
                  sortId: sortId,
                  sortPrice: sortPrice,
                  numberOneC: value,
                });
              }}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={8}>
            <FormInput
              name={'numberID'}
              label={'Номер заказа'}
              placeholder={'Введите номер заказа'}
              search={true}
              onSearch={(value) => {
                fetchOrders({
                  limit: limit,
                  page: currentPage,
                  sortId: sortId,
                  sortPrice: sortPrice,
                  numberID: value,
                });
              }}
              labelCol={{ span: 24 }}
            />
          </Col>
        </Row>
        <Button
          style={{ marginBottom: 20 }}
          type="primary"
          htmlType="submit"
          onClick={applyFilters}
        >
          Применить фильтры
        </Button>
        <Button
          style={{ marginLeft: 20 }}
          type="ghost"
          htmlType="reset"
          onClick={() => {
            return fetchOrders({
              limit: limit,
              page: 1,
              sortId: sortId,
              sortPrice: sortPrice,
            });
          }}
        >
          Очистить фильтры
        </Button>
      </Form>
      <Pagination
        style={{ marginBottom: 20 }}
        total={totalOrders}
        current={currentPage}
        showQuickJumper={true}
        onChange={handlePagination}
        onShowSizeChange={onShowSizeChange}
        pageSizeOptions={[10, 25, 50, 100]}
        defaultPageSize={25}
      />
      <Table
        bordered
        columns={columns}
        dataSource={orders}
        pagination={false}
        onChange={handleChange}
        rowKey="id"
      />
    </div>
  );
};
