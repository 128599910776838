import React from 'react';
import { Link, Route } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import { ComponentsLayout } from '../../layout/ComponentsLayout/ComponentsLayout';
import { AddImage } from '../../Pages/Images/Image/AddImage/AddImage';
import { UpdateImage } from '../../Pages/Images/Image/UpdateImage/UpdateImage';
import { ImagesList } from '../../Pages/Images/ImagesList/ImagesList';

export const ImageRoutes = (
  <>
    <Route
      path="/content/images/add"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Добавить изображение</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <AddImage />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/content/images/update/:id"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/categories/list">Список изображений</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Обновление изображения</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <UpdateImage />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/content/images/list"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Список изображений</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <ImagesList />
          </ComponentsLayout>
        </>
      }
    />
  </>
);
