import React from 'react';

import styles from './ComponentLayoutBlock.module.scss';

interface ComponentsLayoutBlockI {
  children?: any;
}

export const ComponentsLayoutBlock: React.FC<ComponentsLayoutBlockI> = ({
  children,
}) => {
  return <div className={styles.main}>{children}</div>;
};
