import React from 'react';
import { Link, Route } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import { ComponentsLayout } from '../../layout/ComponentsLayout/ComponentsLayout';
import { Constants } from '../../Pages/Constants/Constants';

export const ConstantsRoutes = (
  <>
    <Route
      path="/constants"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <Constants />
          </ComponentsLayout>
        </>
      }
    />
  </>
);
