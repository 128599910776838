import {
  NewsAction,
  NewsActionTypes,
  NewsState,
} from '../../types/entities/News';

const initialState: NewsState = {
  news: [],
  error: null,
  loading: false,
  currentPage: 1,
  limit: 25,
  totalNews: 0,
};

export const newsReducer = (
  state = initialState,
  action: NewsAction,
): NewsState => {
  switch (action.type) {
    case NewsActionTypes.FETCH_ONE_NEWS:
      return { ...state, loading: true };
    case NewsActionTypes.FETCH_NEWS_ERROR:
      return { ...state, error: action.payload, loading: false };
    case NewsActionTypes.FETCH_MANY_NEWS:
      return { ...state, error: null, news: action.payload, loading: false };
    case NewsActionTypes.SET_NEW_PAGE:
      return {
        ...state,
        error: null,
        currentPage: action.payload,
        loading: false,
      };
    case NewsActionTypes.SET_TOTAL_NEW_PAGE:
      return {
        ...state,
        error: null,
        totalNews: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
