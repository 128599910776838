import {
  UserAction,
  UserActionTypes,
  UserState,
} from '../../types/entities/User';

const initialState: UserState = {
  users: [],
  usersList: [],
  error: null,
  loading: false,
  currentPage: 1,
  limit: 25,
  totalUsers: 0,
};

export const userReducer = (
  state = initialState,
  action: UserAction,
): UserState => {
  switch (action.type) {
    case UserActionTypes.FETCH_USER:
      return { ...state, loading: true };
    case UserActionTypes.FETCH_USERS_ERROR:
      return { ...state, error: action.payload, loading: false };
    case UserActionTypes.FETCH_USERS:
      return {
        ...state,
        error: null,
        usersList: action.payload,
        loading: false,
      };
    case UserActionTypes.SET_USER_PAGE:
      return {
        ...state,
        error: null,
        currentPage: action.payload,
        loading: false,
      };
    case UserActionTypes.SET_USER_TOTAL_PAGE:
      return {
        ...state,
        error: null,
        totalUsers: action.payload,
        loading: false,
      };
    case UserActionTypes.FETCH_USERS_SELECT:
      return { ...state, error: null, users: action.payload, loading: false };
    default:
      return state;
  }
};
