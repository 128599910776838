import {
  ProductSliderGroupAction,
  ProductSliderGroupActionTypes,
  ProductSliderGroupState,
} from '../../types/entities/ProductSliderGroup';

const initialState: ProductSliderGroupState = {
  productSliderGroups: [],
  error: null,
  loading: false,
  currentPage: 1,
  limit: 25,
  totalProductSliderGroups: 0,
};

export const productSliderGroupReducer = (
  state = initialState,
  action: ProductSliderGroupAction,
): ProductSliderGroupState => {
  switch (action.type) {
    case ProductSliderGroupActionTypes.FETCH_PRODUCT_SLIDER_GROUP:
      return { ...state, loading: true };
    case ProductSliderGroupActionTypes.FETCH_PRODUCT_SLIDER_GROUPS_ERROR:
      return { ...state, error: action.payload, loading: false };
    case ProductSliderGroupActionTypes.FETCH_PRODUCT_SLIDER_GROUPS:
      return {
        ...state,
        error: null,
        productSliderGroups: action.payload,
        loading: false,
      };
    case ProductSliderGroupActionTypes.SET_PRODUCT_SLIDER_GROUP_PAGE:
      return {
        ...state,
        error: null,
        currentPage: action.payload,
        loading: false,
      };
    case ProductSliderGroupActionTypes.SET_TOTAL_PRODUCT_SLIDER_GROUP_PAGE:
      return {
        ...state,
        error: null,
        totalProductSliderGroups: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
