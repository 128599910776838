import React from 'react';

import styles from './ComponentsLayout.module.scss';

interface ComponentsLayoutI {
  children?: any;
  classtext?: any;
}

export const ComponentsLayout: React.FC<ComponentsLayoutI> = ({
  children,
  classtext,
}) => {
  return <div className={`${styles.main} ${classtext}`}>{children}</div>;
};
