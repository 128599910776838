import {
  PageAction,
  PageActionTypes,
  PageState,
} from '../../types/entities/Page';

const initialState: PageState = {
  pages: [],
  error: null,
  loading: false,
  currentPage: 1,
  limit: 25,
  totalPages: 0,
};

export const pageReducer = (
  state = initialState,
  action: PageAction,
): PageState => {
  switch (action.type) {
    case PageActionTypes.FETCH_PAGE:
      return { ...state, loading: true };
    case PageActionTypes.FETCH_PAGES_ERROR:
      return { ...state, error: action.payload, loading: false };
    case PageActionTypes.FETCH_PAGES:
      return { ...state, error: null, pages: action.payload, loading: false };
    case PageActionTypes.SET_PAGE_PAGE:
      return {
        ...state,
        error: null,
        currentPage: action.payload,
        loading: false,
      };
    case PageActionTypes.SET_TOTAL_PAGE_PAGE:
      return {
        ...state,
        error: null,
        totalPages: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
