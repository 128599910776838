import { IOrder } from './Order';

export interface IOrderHistory {
  id: number;
  orderId: number | null;
  order: IOrder | undefined;
  field: string | null;
  newValue: string | null;
  oldValue: string | null;
}

export interface OrderHistoryState {
  orderHistories: IOrderHistory[];
  error: string | null;
  loading: boolean;
  currentPage: number;
  limit: number;
  totalOrderHistories: number;
}

export enum OrderHistoryActionTypes {
  FETCH_ORDER_HISTORY = 'FETCH_ORDER_HISTORY',
  FETCH_ORDER_HISTORIES = 'FETCH_ORDER_HISTORIES',
  FETCH_ORDER_HISTORIES_ERROR = 'FETCH_ORDER_HISTORIES_ERROR',
  SET_ORDER_HISTORY_PAGE = 'SET_ORDER_HISTORY_PAGE',
  SET_TOTAL_ORDER_HISTORY_PAGE = 'SET_TOTAL_ORDER_HISTORY_PAGE',
}

interface FetchOrderHistoryAction {
  type: OrderHistoryActionTypes.FETCH_ORDER_HISTORY;
}

interface FetchOrderHistoriesAction {
  type: OrderHistoryActionTypes.FETCH_ORDER_HISTORIES;
  payload: IOrderHistory[];
}

interface FetchOrderHistoriesErrorAction {
  type: OrderHistoryActionTypes.FETCH_ORDER_HISTORIES_ERROR;
  payload: string;
}

interface SetOrderHistoryPageAction {
  type: OrderHistoryActionTypes.SET_ORDER_HISTORY_PAGE;
  payload: number;
}

interface SetTotalOrderHistoryPageAction {
  type: OrderHistoryActionTypes.SET_TOTAL_ORDER_HISTORY_PAGE;
  payload: number;
}

export type OrderHistoryAction =
  | FetchOrderHistoryAction
  | FetchOrderHistoriesAction
  | FetchOrderHistoriesErrorAction
  | SetOrderHistoryPageAction
  | SetTotalOrderHistoryPageAction;
