import { IUser } from './User';
import { IGeoZone } from './Geo/GeoZone';

export interface INews {
  id: number;
  title: string;
  date: Date;
  slug: string;
  shortText: string;
  fullText: string;
  image: string;
  userId: number;
  updateUser: IUser | undefined;
  status: number;
  keywords: string;
  description: string;
  views: number;
  createdAt: Date;
  updatedAt: Date;
  geoZoneId: number;
  geoZone: IGeoZone | undefined;
}

export interface NewsState {
  news: INews[];
  error: string | null;
  loading: boolean;
  currentPage: number;
  limit: number;
  totalNews: number;
}

export enum NewsActionTypes {
  FETCH_ONE_NEWS = 'FETCH_ONE_NEWS',
  FETCH_MANY_NEWS = 'FETCH_MANY_NEWS',
  FETCH_NEWS_ERROR = 'FETCH_NEWS_ERROR',
  SET_NEW_PAGE = 'SET_NEW_PAGE',
  SET_TOTAL_NEW_PAGE = 'SET_TOTAL_NEW_PAGE',
}

interface FetchOneNewsAction {
  type: NewsActionTypes.FETCH_ONE_NEWS;
}

interface FetchManyNewsAction {
  type: NewsActionTypes.FETCH_MANY_NEWS;
  payload: INews[];
}

interface FetchNewsErrorAction {
  type: NewsActionTypes.FETCH_NEWS_ERROR;
  payload: string;
}

interface SetNewPageAction {
  type: NewsActionTypes.SET_NEW_PAGE;
  payload: number;
}

interface SetTotalNewPageAction {
  type: NewsActionTypes.SET_TOTAL_NEW_PAGE;
  payload: number;
}

export type NewsAction =
  | FetchOneNewsAction
  | FetchManyNewsAction
  | FetchNewsErrorAction
  | SetNewPageAction
  | SetTotalNewPageAction;
