import axios, { AxiosResponse } from 'axios';

import { AuthResponse, AuthTypes } from '../types/Auth/Auth';
import { $api } from '../http';
import { ApiUrl } from '../types/ApiUrl';
import { IUser } from '../types/entities/User';

export default class AuthService {
  /**
   * @param phone
   * @param email
   * @param password
   * */
  static async loginByPassword({
    phone,
    email,
    password,
  }: {
    phone: string;
    email: string;
    password: string;
  }): Promise<AxiosResponse<AuthResponse>> {
    if (phone) {
      return $api.post<AuthResponse>(ApiUrl.LOGIN, { phone, password });
    } else {
      return $api.post<AuthResponse>(ApiUrl.LOGIN, { email, password });
    }
  }

  static async loginByCode({
    phone,
    email,
    codePass,
  }: {
    phone: string;
    email: string;
    codePass: string;
  }): Promise<AxiosResponse<AuthResponse>> {
    if (phone) {
      return $api.post<AuthResponse>(ApiUrl.LOGIN_BY_CODE, { phone, codePass });
    } else {
      return $api.post<AuthResponse>(ApiUrl.LOGIN_BY_CODE, { email, codePass });
    }
  }

  static async registration(
    userData: IUser,
  ): Promise<AxiosResponse<AuthResponse>> {
    return $api.post<AuthResponse>(ApiUrl.REGISTRATION, userData);
  }

  static async logout(): Promise<void> {
    return $api.post(ApiUrl.LOGOUT);
  }

  static async refresh(): Promise<AxiosResponse> {
    const response = await axios.post<AuthResponse>(ApiUrl.REFRESH, {
      withCredentials: true,
    });
    localStorage.setItem(AuthTypes.TOKEN, response.data.accessToken);
    localStorage.setItem(AuthTypes.USER, JSON.stringify(response.data.user));
    return response;
  }
}
