export const statusUserOptions = [
  { value: '1', label: 'Активен' },
  { value: '2', label: 'Заблокирован' },
  { value: '3', label: 'Не активирован' },
];

export const emailConfirmUserOptions = [
  { value: '1', label: 'Да' },
  { value: '0', label: 'Нет' },
];
