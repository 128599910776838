import React from 'react';
import { Link, Route } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import { ComponentsLayout } from '../../layout/ComponentsLayout/ComponentsLayout';
import { AttributesList } from '../../Pages/Attributes/AttributesList/AttributesList';
import { AddAttribute } from '../../Pages/Attributes/Attribute/AddAttribute/AddAttribute';
import { UpdateAttribute } from '../../Pages/Attributes/Attribute/UpdateAttribute/UpdateAttribute';

export const AttributeRoutes = (
  <>
    <Route
      path="/attributes/attributes/add"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Добавить атрибут</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <AddAttribute />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/attributes/attributes/update/:id"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/attributes/attributes/list">Список атрибутов</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Обновление атрибута</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <UpdateAttribute />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/attributes/attributes/list"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Список атрибутов</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <AttributesList />
          </ComponentsLayout>
        </>
      }
    />
  </>
);
