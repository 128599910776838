import { Link, Route } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { ComponentsLayout } from '../../layout/ComponentsLayout/ComponentsLayout';
import { AddService } from '../../Pages/Services/Service/AddService/AddService';
import { UpdateService } from '../../Pages/Services/Service/UpdateService/UpdateService';
import { ServicesList } from '../../Pages/Services/ServicesList/ServicesList';

export const ServicesRoutes = (
  <>
    <Route
      path="/services/add"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Добавить услугу</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <AddService />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/services/update/:id"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/services/list">Список услуг</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Обновление категории</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <UpdateService />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/services/list"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Список услуг</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <ServicesList />
          </ComponentsLayout>
        </>
      }
    />
  </>
);
