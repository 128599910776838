import { Dispatch } from 'redux';

import { ApiUrl } from '../../../types/ApiUrl';
import {
  PaymentAction,
  PaymentActionTypes,
} from '../../../types/entities/Payment/Payment';
import { $api } from '../../../http';

interface queryPaymentParamsInterface {
  limit?: any;
  page?: any;
  sortId?: string;
}

export const fetchPaymentsForSelect = (status: 'active' | 'all') => {
  return async (dispatch: Dispatch<PaymentAction>) => {
    try {
      dispatch({ type: PaymentActionTypes.FETCH_PAYMENT });
      const response = await $api.get(
        `${ApiUrl.PAYMENT_SELECT}?status=${status}`,
      );
      dispatch({
        type: PaymentActionTypes.FETCH_PAYMENTS_SELECT,
        payload: response.data,
      });
    } catch (e) {
      dispatch({
        type: PaymentActionTypes.FETCH_PAYMENTS_ERROR,
        payload: 'Произошла ошибка при загрузке способов оплаты',
      });
    }
  };
};

export const fetchPaymentsList = (queryParams: queryPaymentParamsInterface) => {
  const { limit = '25', page = 1, sortId = 'DESC' } = queryParams;

  const urlPaymentParams = new URLSearchParams();
  urlPaymentParams.append('limit', limit);
  urlPaymentParams.append('page', page);
  urlPaymentParams.append('sortId', sortId);

  return async (dispatch: Dispatch<PaymentAction>) => {
    try {
      dispatch({ type: PaymentActionTypes.FETCH_PAYMENT });
      const response = await $api.get(
        `${ApiUrl.PAYMENT}?${urlPaymentParams.toString()}`,
      );
      dispatch({
        type: PaymentActionTypes.FETCH_PAYMENTS,
        payload: response.data.items,
      });
      dispatch({
        type: PaymentActionTypes.SET_TOTAL_PAYMENT_PAGE,
        payload: response.data.total,
      });
      dispatch({ type: PaymentActionTypes.SET_PAYMENT_PAGE, payload: page });
    } catch (e) {
      dispatch({
        type: PaymentActionTypes.FETCH_PAYMENTS_ERROR,
        payload: 'Произошла ошибка при загрузке способов оплаты',
      });
    }
  };
};
