import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Pagination, Row, Table } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useActions } from '../../../hooks/useActions';
import { statusGeoCityOptions } from '../../../helpers/geoCityHelper';
import { SelectOptionHelper } from '../../../helpers/selectHelper';
import { StatusEditor } from '../../../components/Editors/StatusEditor';
import { ApiUrl } from '../../../types/ApiUrl';
import styles from './GeoCitiesList.module.scss';
import FormInput from '../../../components/FormItems/FormInput';
import FormSelect from '../../../components/FormItems/FormSelect';

export const GeoCitiesList: React.FC = () => {
  const { geoCitiesList, limit, currentPage, totalGeoCities } =
    useTypedSelector((state) => state.geoCity);

  const { geoRegions } = useTypedSelector((state) => state.geoRegion);

  const [sort, setSort] = useState('DESC');
  const [edit, setEdit] = useState(false);

  const { fetchGeoCitiesList, fetchGeoRegionsForSelect } = useActions();

  const regionsOptions = SelectOptionHelper(geoRegions);

  const [form] = Form.useForm();

  useEffect(() => {
    fetchGeoRegionsForSelect();
    fetchGeoCitiesList({
      limit: limit,
      page: currentPage,
      sortId: sort,
      id: form.getFieldValue('id'),
      regionId: form.getFieldValue('regionId'),
      name: form.getFieldValue('name'),
      code: form.getFieldValue('code'),
      status: form.getFieldValue('status'),
    });
  }, [edit]);

  const onShowSizeChange = (currentPage: number, limit: number) => {
    fetchGeoCitiesList({
      limit: limit,
      page: currentPage,
      sortId: sort,
      id: form.getFieldValue('id'),
      regionId: form.getFieldValue('regionId'),
      name: form.getFieldValue('name'),
      code: form.getFieldValue('code'),
      status: form.getFieldValue('status'),
    });
  };
  const handlePagination = (currentPage: number, limit: number) => {
    fetchGeoCitiesList({
      limit: limit,
      page: currentPage,
      sortId: sort,
      id: form.getFieldValue('id'),
      regionId: form.getFieldValue('regionId'),
      name: form.getFieldValue('name'),
      code: form.getFieldValue('code'),
      status: form.getFieldValue('status'),
    });
  };

  const applyFilters = () => {
    fetchGeoCitiesList({
      limit: limit,
      page: 1,
      sortId: sort,
      id: form.getFieldValue('id'),
      regionId: form.getFieldValue('regionId'),
      name: form.getFieldValue('name'),
      code: form.getFieldValue('code'),
      status: form.getFieldValue('status'),
    });
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.field === 'id') {
      if (sorter.order === 'ascend') {
        setSort('ASC');
        fetchGeoCitiesList({ limit: limit, page: currentPage, sortId: 'ASC' });
      } else {
        setSort('DESC');
        fetchGeoCitiesList({
          limit: limit,
          page: currentPage,
          sortId: 'DESC',
        });
      }
    }
  };

  const columns = [
    {
      title: 'ID города',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => {
        return (
          <div key={id}>
            <Link to={`/content/geo/geo-city/update/${id}`}>{id}</Link>
          </div>
        );
      },
      sorter: true,
    },
    {
      title: 'Регион',
      key: 'regionId',
      render: (data: any) => {
        if (data.region) {
          return <>{data.region.name}</>;
        } else {
          return '---';
        }
      },
    },
    {
      title: 'Название',
      dataIndex: 'name',
      key: 'name',
      render: (name: string) => {
        if (name) {
          return <>{name}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Код',
      dataIndex: 'code',
      key: 'code',
      render: (code: string) => {
        if (code) {
          return <>{code}</>;
        } else {
          return '---';
        }
      },
    },
    {
      title: 'Статус',
      key: 'status',
      render: (data: any) => {
        return (
          <StatusEditor
            status={data.status}
            id={data.id}
            options={statusGeoCityOptions}
            setEdit={setEdit}
            url={ApiUrl.UPDATE_GEO_CITY_STATUS}
          />
        );
      },
    },
    {
      title: 'Действия с городом',
      dataIndex: 'id',
      key: 'edit',
      render: (id: string) => {
        return (
          <>
            <Link to={`/content/geo/geo-city/update/${id}`}>
              <EditOutlined style={{ marginLeft: 50 }} />
            </Link>
          </>
        );
      },
    },
  ];

  return (
    <div className={styles.main}>
      <h1>Города</h1>
      <h4 style={{ textAlign: 'left' }}>Фильтры полей</h4>
      <Form form={form}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={8}>
            <FormInput
              name={'id'}
              label={'ID города'}
              placeholder={'Выберите город'}
              search={true}
              onSearch={(value) => {
                fetchGeoCitiesList({
                  limit: limit,
                  page: currentPage,
                  sortId: sort,
                  id: value,
                });
              }}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={8}>
            <FormSelect
              name={'regionId'}
              label={'Регион'}
              placeholder={'Выберите регион'}
              showSearch={true}
              options={regionsOptions}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={8}>
            <FormInput
              name={'name'}
              label={'Город'}
              placeholder={'Введите название города'}
              search={true}
              onSearch={(value) => {
                fetchGeoCitiesList({
                  limit: limit,
                  page: currentPage,
                  sortId: sort,
                  name: value,
                });
              }}
              labelCol={{ span: 24 }}
            />
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={8}>
            <FormInput
              name={'code'}
              label={'Код'}
              placeholder={'Введите код города'}
              search={true}
              onSearch={(value) => {
                fetchGeoCitiesList({
                  limit: limit,
                  page: currentPage,
                  sortId: sort,
                  code: value,
                });
              }}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={8}>
            <FormSelect
              name={'status'}
              label={'Статус'}
              placeholder={'Выберите статус'}
              showSearch={true}
              options={statusGeoCityOptions}
              labelCol={{ span: 24 }}
            />
          </Col>
        </Row>
        <Button
          style={{ marginBottom: 20 }}
          type="primary"
          htmlType="submit"
          onClick={applyFilters}
        >
          Применить фильтры
        </Button>
        <Button
          style={{ marginLeft: 20 }}
          type="ghost"
          htmlType="reset"
          onClick={() => {
            return fetchGeoCitiesList({
              limit: limit,
              page: 1,
              sortId: sort,
            });
          }}
        >
          Очистить фильтры
        </Button>
      </Form>
      <Pagination
        style={{ marginBottom: 20 }}
        total={totalGeoCities}
        current={currentPage}
        showQuickJumper={true}
        onChange={handlePagination}
        onShowSizeChange={onShowSizeChange}
        pageSizeOptions={[10, 25, 50, 100]}
        defaultPageSize={25}
      />
      <Table
        bordered
        columns={columns}
        dataSource={geoCitiesList}
        pagination={false}
        onChange={handleChange}
        rowKey="id"
      />
    </div>
  );
};
