import { IAttributeGroup } from './AttributeGroup';

export interface IAttributeOption {
  id: number;
  attributeId: number;
  position: number;
  value: string;
}

export interface IAttribute {
  id: number;
  name: string | null;
  title: string | null;
  type: number;
  unit: string | null;
  sort: number;
  sortFilters: number;
  isFilter: number;
  description: string | null;
  onec: number;
  smaleName: string | null;
  required: number;
  groupId: number;
  group?: IAttributeGroup;
  options?: IAttributeOption[];
}

export interface AttributeState {
  attributes: IAttribute[];
  error: string | null;
  loading: boolean;
  currentPage: number;
  limit: number;
  totalAttributes: number;
}

export enum AttributeActionTypes {
  FETCH_ATTRIBUTE = 'FETCH_ATTRIBUTE',
  FETCH_ATTRIBUTES = 'FETCH_ATTRIBUTES',
  FETCH_ATTRIBUTES_ERROR = 'FETCH_ATTRIBUTES_ERROR',
  SET_ATTRIBUTE_PAGE = 'SET_ATTRIBUTE_PAGE',
  SET_TOTAL_ATTRIBUTE_PAGE = 'SET_TOTAL_ATTRIBUTE_PAGE',
}

interface FetchAttributeAction {
  type: AttributeActionTypes.FETCH_ATTRIBUTE;
}

interface FetchAttributesAction {
  type: AttributeActionTypes.FETCH_ATTRIBUTES;
  payload: IAttribute[];
}

interface FetchAttributesErrorAction {
  type: AttributeActionTypes.FETCH_ATTRIBUTES_ERROR;
  payload: string;
}

interface SetAttributePageAction {
  type: AttributeActionTypes.SET_ATTRIBUTE_PAGE;
  payload: number;
}

interface SetTotalAttributePageAction {
  type: AttributeActionTypes.SET_TOTAL_ATTRIBUTE_PAGE;
  payload: number;
}

export type AttributeAction =
  | FetchAttributeAction
  | FetchAttributesAction
  | FetchAttributesErrorAction
  | SetAttributePageAction
  | SetTotalAttributePageAction;
