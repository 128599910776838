import React, { useCallback, useEffect, useState } from 'react';
import { Button, Form, Tabs } from 'antd';
import { RcFile, UploadFile, UploadProps } from 'antd/es/upload/interface';
import { useNavigate, useParams } from 'react-router-dom';

import styles from './Product.module.scss';
import { MainAttributes } from './AddProduct/TabsForm/MainAttributes/MainAttributes';
import { Attributes } from './AddProduct/TabsForm/Attributes';
import { HistorySave } from './AddProduct/TabsForm/HistorySave/HistorySave';
import { InfoProvider } from './AddProduct/TabsForm/InfoProvider';
import { ApiUrl } from '../../../types/ApiUrl';
import { useHttp, UseHttpI } from '../../../hooks/useHttp';
import { createTree, SelectOptionHelper } from '../../../helpers/selectHelper';
import { useTypedSelector } from '../../../hooks/useTypedSelector';
import NotificationAlert from '../../../components/Notification';
import { IProductImage } from '../../../types/entities/ProductImage';
import { ImageUpload } from './AddProduct/TabsForm/ImageUpload/ImageUpload';
import { useActions } from '../../../hooks/useActions';
import { SEO } from './AddProduct/TabsForm/SEO';
import { Prices } from './AddProduct/TabsForm/Prices';
import { useTrimField } from '../../../hooks/useTrimField';
import {
  SUBMIT_METHOD_ADD,
  ROLES,
  UPDATE_SUBMIT_METHOD,
} from '../../../types/constants';

interface ProductProps {
  titlePage: string;
  submitMethod: string;
}

interface UploadImage extends UploadFile {
  title?: string;
  alt?: string;
}

export const Product: React.FC<ProductProps> = ({
  titlePage,
  submitMethod,
}) => {
  const { fetchCategoriesForSelect } = useActions();
  const { categories } = useTypedSelector((state) => state.category);
  const { producers } = useTypedSelector((state) => state.producer);
  const { user } = useTypedSelector((state) => state.auth);
  const { id } = useParams();

  const { fetchTypes } = useActions();

  const navigate = useNavigate();

  const [fileMainList, setFileMainList] = useState<UploadImage[]>([]);
  const [fileOtherList, setFileOtherList] = useState<UploadImage[]>([]);
  const [currentImage, setCurrentImage] = useState<UploadImage | null>(null);
  const [previewMainOpen, setPreviewMainOpen] = useState(false);
  const [previewOtherOpen, setPreviewOtherOpen] = useState(false);
  const [previewMainImage, setPreviewMainImage] = useState('');
  const [previewOtherImage, setPreviewOtherImage] = useState('');
  const [previewMainTitle, setPreviewMainTitle] = useState('');
  const [previewOtherTitle, setPreviewOtherTitle] = useState('');

  const [currentCategory, setCurrentCategory] = useState<number[] | null>(null);

  const producerOptions = SelectOptionHelper(producers);
  const categoryTree = createTree(categories);
  const { request } = useHttp();

  function getCurrentImage() {
    if (currentImage) {
      if (!currentImage.alt) currentImage.alt = '';
      return currentImage;
    }
  }

  const getData = useCallback(async () => {
    if (id) {
      try {
        const fetchProduct = await request({
          url: `${ApiUrl.PRODUCT}/${id}`,
          method: 'get',
        });

        if (fetchProduct.data?.productImages.length > 0) {
          const mainArr = fetchProduct.data?.productImages.filter(
            (image: IProductImage) => {
              return image.main;
            },
          );
          let main = mainArr[0];
          if (mainArr.length > 1) {
            main = mainArr[0];
            for (const image of fetchProduct.data?.productImages) {
              if (image.id !== main.id) {
                image.main = false;
              }
            }
          }

          const other = fetchProduct.data?.productImages.filter(
            (image: IProductImage) => {
              return !image.main;
            },
          );

          if (main !== undefined) {
            setFileMainList([
              {
                uid: main.id,
                name: main.name,
                title: main.title,
                status: 'done',
                alt: main.alt,
                url: `${ApiUrl.FILE}/${main.name}?dir=store,product&root=1`,
              },
            ]);
          }

          if (other.length > 0) {
            const otherImages = other.map((image: IProductImage) => {
              return {
                uid: image.id,
                name: image.name,
                title: image.title,
                status: 'done',
                alt: image.alt,
                url: `${ApiUrl.FILE}/${image.name}?dir=store,product&root=1`,
              };
            });

            setFileOtherList(otherImages);
          }
        }

        const categoryOption = fetchProduct.data.categories.map((item: any) => {
          return item.id;
        });
        form.setFieldsValue({
          productInputTitle: fetchProduct.data?.title,
          productSku: fetchProduct.data?.sku,
          productSlug: fetchProduct.data?.slug,
          productAveragePrice: fetchProduct.data?.averagePrice,
          productDescription: fetchProduct.data?.description,
          productShortDescription: fetchProduct.data?.shortDescription,
          productCondition: fetchProduct.data?.condition ? fetchProduct.data.condition.toString() : null,
          productProducer: fetchProduct.data?.producerId,
          productCategory: categoryOption,
          attributeTypeProduct: fetchProduct.data.typeId,
          metaDescription: fetchProduct.data?.metaDescription,
          metaTitle: fetchProduct.data?.metaTitle,
          keywords: fetchProduct.data?.keywords,
        });
        setCurrentCategory(categoryOption || null);
      } catch (e) {}
    }
  }, [request]);

  const [form] = Form.useForm();

  useEffect(() => {
    getData();
    if (!id) {
      form.setFieldsValue({
        productCondition: '0',
      });
    }
    fetchCategoriesForSelect();
    fetchTypes();
  }, []);

  const onChangeMain: UploadProps['onChange'] = ({ fileList: newFileList }) => {
    setFileMainList(newFileList);
  };

  const onChangeOther: UploadProps['onChange'] = ({
    fileList: newFileList,
  }) => {
    setFileOtherList(newFileList);
  };

  const handleMainCancel = () => setPreviewMainOpen(false);
  const handleOtherCancel = () => {
    setPreviewOtherOpen(false);
  };

  const getBase64 = (file: RcFile): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });

  const onChangeAlt = (e: any, uid: number, isMain: boolean) => {
    const clone = isMain ? fileMainList : fileOtherList;
    const image = clone.find((item) => Number(item.uid) === uid);
    if (image) {
      image.alt = e.target.value;
      if (isMain) setFileMainList(clone);
      else setFileOtherList(clone);
    }
  };
  const onMainPreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setCurrentImage(fileMainList.find((item) => item.uid === file.uid) || null);
    setPreviewMainImage(file.url || (file.preview as string));
    setPreviewMainOpen(true);
    setPreviewMainTitle(file.name);
  };

  const onOtherPreview = async (file: any) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj as RcFile);
    }

    setCurrentImage(
      fileOtherList.find((item) => item.uid === file.uid) || null,
    );
    setPreviewOtherImage(file.url || (file.preview as string));
    setPreviewOtherOpen(true);
    setPreviewOtherTitle(file.name);
  };

  const onFinish = async (e: any) => {
    console.log(e);
    try {
      useTrimField(form, null, e);

      for (const key in e) {
        if (key.includes('attributeTypeProductSelect') && !e[key]) {
          e[key] = null;
        }
      }
      const otherList = fileOtherList.map((file) => {
        const imageName = file?.response?.name
          ? file?.response?.name
          : file?.name
          ? file?.name
          : null;
        const imageTitle = file?.name
          ? file?.name
          : file?.title
          ? file?.title
          : null;
        const imageAlt = file?.alt;
        return {
          imageName: imageName,
          imageTitle: imageTitle,
          imageAlt: imageAlt,
        };
      });

      const requestObject: UseHttpI =
        submitMethod === SUBMIT_METHOD_ADD
          ? {
              url: ApiUrl.PRODUCT,
              method: 'post',
              body: {
                ...e,
                userId: user?.id,
                mainImageName: fileMainList[0]?.response?.name
                  ? fileMainList[0].response.name
                  : null,
                mainImageTitle: fileMainList[0]?.name
                  ? fileMainList[0].name
                  : null,
                otherImages: JSON.stringify(otherList),
              },
            }
          : {
              url: `${ApiUrl.PRODUCT}/${id}`,
              method: 'put',
              body: {
                ...e,
                userId: user?.id,
                mainImageName: fileMainList[0]?.response?.name
                  ? fileMainList[0].response.name
                  : fileMainList[0]?.name
                  ? fileMainList[0].name
                  : null,
                mainImageTitle: fileMainList[0]?.name
                  ? fileMainList[0].name
                  : fileMainList[0]?.title
                  ? fileMainList[0].title
                  : null,
                mainImageAlt: fileMainList[0]?.alt,
                otherImages: JSON.stringify(otherList),
              },
            };

      const response = await request(requestObject);

      switch (response.config.method) {
        case 'post':
          navigate('/products/list');
          return NotificationAlert('success', 'Товар добавлен');
        case 'put':
          return NotificationAlert('success', 'Товар обновлен');
        default:
          return NotificationAlert('error', 'Упс');
      }
    } catch (err: any) {
      if (err.response.data.length) {
        return err.response.data.map((el: any) => {
          return NotificationAlert('error', el);
        });
      } else {
        return NotificationAlert(
          'error',
          err.response.data?.message || err.message,
        );
      }
    }
  };

  const items = [
    {
      label: 'ОБЩЕЕ',
      key: '1',
      children: (
        <MainAttributes producerOptions={producerOptions} form={form} />
      ),
      roles: [ROLES.content],
    },
    {
      label: 'АТРИБУТЫ',
      key: '2',
      children: (
        <Attributes
          productId={id}
          form={form}
          categoryTree={categoryTree}
          currentCategory={currentCategory}
          setCurrentCategory={setCurrentCategory}
        />
      ),
      roles: [ROLES.content],
    },
    {
      label: 'ИЗОБРАЖЕНИЯ',
      key: '3',
      children: (
        <ImageUpload
          fileMainList={fileMainList}
          fileOtherList={fileOtherList}
          onChangeMain={onChangeMain}
          onChangeOther={onChangeOther}
          onMainPreview={onMainPreview}
          onOtherPreview={onOtherPreview}
          previewMainOpen={previewMainOpen}
          previewOtherOpen={previewOtherOpen}
          previewMainImage={previewMainImage}
          previewOtherImage={previewOtherImage}
          previewMainTitle={previewMainTitle}
          previewOtherTitle={previewOtherTitle}
          handleMainCancel={handleMainCancel}
          handleOtherCancel={handleOtherCancel}
          currentImage={getCurrentImage()}
          onChangeAlt={onChangeAlt}
        />
      ),
      roles: [ROLES.content, ROLES.seo],
    },
    // {
    //   label: 'СКЛАД',
    //   key: '4',
    //   children: <Warehouse />,
    //   roles: [ROLES.content],
    // },
    {
      label: 'ИСТОРИЯ СОХРАНЕНИЙ',
      key: '5',
      children: <HistorySave />,
      roles: [ROLES.content],
    },
    {
      label: 'ИНФОРМАЦИЯ О ПОСТАВЩИКЕ',
      key: '6',
      children: <InfoProvider />,
      roles: [ROLES.content],
    },
    {
      label: ROLES.seo,
      key: '7',
      children: <SEO />,
      roles: [ROLES.seo, ROLES.content],
    },
    {
      label: 'ЦЕНЫ',
      key: '8',
      children: <Prices sku={form.getFieldValue('productSku')} form={form} />,
      roles: [ROLES.content],
    },
  ];
  const roles = useTypedSelector((state) => state.auth.user?.roles);
  let filterItem = [...items];

  if (!roles?.find((role) => role.value === ROLES.admin)) {
    filterItem = items.filter((item) => {
      if (!roles) return false;
      if (!item.roles.length) return true;
      for (const role of roles) {
        if (item.roles.includes(role.value)) return true;
      }
      return false;
    });
  }
  return (
    <div className={`${styles.main} product`}>
      <Form
        onFinish={onFinish}
        labelCol={{ span: 6 }}
        form={form}
        initialValues={{ status: 0 }}
      >
        <h1>{titlePage}</h1>
        <Tabs defaultActiveKey="1" items={filterItem} />
        <Form.Item>
          <Button type="primary" htmlType="submit">
            {submitMethod === SUBMIT_METHOD_ADD ? 'Добавить' : 'Обновить'}
          </Button>
        </Form.Item>
        {submitMethod === UPDATE_SUBMIT_METHOD && (
          <a
            className={styles.viewLink}
            href={`https://set-tehniki.com/store/${id}.html`}
            target="_blank"
          >
            Посмотреть на сайте
          </a>
        )}
      </Form>
    </div>
  );
};
