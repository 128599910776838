import {
  GeoRegionAction,
  GeoRegionActionTypes,
  GeoRegionState,
} from '../../../types/entities/Geo/GeoRegion';

const initialState: GeoRegionState = {
  geoRegions: [],
  geoRegionsList: [],
  error: null,
  loading: false,
  currentPage: 1,
  limit: 25,
  totalGeoRegions: 0,
};

export const geoRegionReducer = (
  state = initialState,
  action: GeoRegionAction,
): GeoRegionState => {
  switch (action.type) {
    case GeoRegionActionTypes.FETCH_GEO_REGION:
      return { ...state, loading: true };
    case GeoRegionActionTypes.FETCH_GEO_REGIONS_ERROR:
      return { ...state, error: action.payload, loading: false };
    case GeoRegionActionTypes.FETCH_GEO_REGIONS:
      return {
        ...state,
        error: null,
        geoRegionsList: action.payload,
        loading: false,
      };
    case GeoRegionActionTypes.SET_GEO_REGION_PAGE:
      return {
        ...state,
        error: null,
        currentPage: action.payload,
        loading: false,
      };
    case GeoRegionActionTypes.SET_TOTAL_GEO_REGION_PAGE:
      return {
        ...state,
        error: null,
        totalGeoRegions: action.payload,
        loading: false,
      };
    case GeoRegionActionTypes.FETCH_GEO_REGIONS_SELECT:
      return {
        ...state,
        error: null,
        geoRegions: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
