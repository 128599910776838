import { Button, Form, Input, Spin } from 'antd';
import { FC, useEffect, useState } from 'react';
import NotificationAlert from '../../components/Notification';
import { useHttp } from '../../hooks/useHttp';
import { ApiUrl } from '../../types/ApiUrl';

export const SEOFiles: FC = () => {
  const [isLoad, setIsload] = useState<boolean>(false);
  const [form] = Form.useForm();
  const { request } = useHttp();

  const onFinish = async () => {
    const formData = new FormData();
    const sitemapFile = new File(
      [form.getFieldValue('sitemap')],
      'sitemap.xml',
      {
        type: 'text/plain',
      },
    );
    const robotsFile = new File([form.getFieldValue('robots')], 'robots.txt', {
      type: 'text/plain',
    });
    formData.append('file', sitemapFile);
    formData.append('file', robotsFile);
    console.log(formData.getAll('file'));
    const response = await request({
      url: `${ApiUrl.SEO_FILES}`,
      method: 'put',
      body: formData,
    });
    if (response.status.toString()[0])
      return NotificationAlert('success', 'Данные обновлены');
    else return NotificationAlert('error', 'Что-то пошло не так');
  };

  const loadFiles = async () => {
    const filesContent = await request({
      url: `${ApiUrl.SEO_FILES}`,
      method: 'get',
    });
    form.setFieldsValue({
      robots: filesContent.data?.robots,
      sitemap: filesContent.data?.sitemap,
    });
    setIsload(true);
  };

  const generateSitemap = async () => {
    setIsload(false);
    const content = await request({
      url: `${ApiUrl.SITEMAP}`,
      method: 'get',
    });
    form.setFieldsValue({ sitemap: content.data });
    setIsload(true);
  };

  useEffect(() => {
    loadFiles();
  }, []);

  return (
    <>
      {isLoad ? (
        <Form
          onFinish={onFinish}
          form={form}
          labelCol={{ span: 6 }}
          style={{ width: '50vw' }}
        >
          <div style={{ textAlign: 'left', marginBottom: '30px' }}>
            <h3>robots.txt</h3>
            <Form.Item name={'robots'}>
              <Input.TextArea
                autoSize={true}
                style={{ maxHeight: '700px' }}
                placeholder={'robots'}
              />
            </Form.Item>
          </div>
          <div style={{ textAlign: 'left', marginBottom: '30px' }}>
            <h3>sitemap.xml</h3>
            <Form.Item name={'sitemap'}>
              <Input.TextArea
                autoSize={true}
                style={{ maxHeight: '700px' }}
                placeholder={'sitemap'}
              />
            </Form.Item>
            <Button type="primary" onClick={generateSitemap}>
              Автоматическая генерация
            </Button>
          </div>
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Обновить
            </Button>
          </Form.Item>
        </Form>
      ) : (
        <Spin size="large" tip="Загрузка..." />
      )}
    </>
  );
};
