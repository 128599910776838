import {
  ProducerAction,
  ProducerActionTypes,
  ProducerState,
} from '../../types/entities/Producer';

const initialState: ProducerState = {
  producers: [],
  error: null,
  loading: false,
  currentPage: 1,
  limit: 25,
  totalProducers: 0,
};

export const producerReducer = (
  state = initialState,
  action: ProducerAction,
): ProducerState => {
  switch (action.type) {
    case ProducerActionTypes.FETCH_PRODUCER:
      return { ...state, loading: true };
    case ProducerActionTypes.FETCH_PRODUCERS_ERROR:
      return { ...state, error: action.payload, loading: false };
    case ProducerActionTypes.FETCH_PRODUCERS:
      return {
        ...state,
        error: null,
        producers: action.payload,
        loading: false,
      };
    case ProducerActionTypes.SET_PRODUCER_PAGE:
      return {
        ...state,
        error: null,
        currentPage: action.payload,
        loading: false,
      };
    case ProducerActionTypes.SET_TOTAL_PRODUCER_PAGE:
      return {
        ...state,
        error: null,
        totalProducers: action.payload,
        loading: false,
      };
    default:
      return state;
  }
};
