import React, { useEffect, useState } from 'react';
import { Button, Col, Form, Pagination, Row, Table } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

import { useTypedSelector } from '../../../hooks/useTypedSelector';
import { useActions } from '../../../hooks/useActions';
import styles from './MenuItemsList.module.scss';
import { statusMenuOptions } from '../../../helpers/menuHelper';
import {
  createTreeMenu,
  SelectOptionHelper,
} from '../../../helpers/selectHelper';
import { StatusEditor } from '../../../components/Editors/StatusEditor';
import { ApiUrl } from '../../../types/ApiUrl';
import FormInput from '../../../components/FormItems/FormInput';
import FormSelect from '../../../components/FormItems/FormSelect';
import FormTreeSelect from '../../../components/FormItems/FormTreeSelect';

export const MenuItemsList: React.FC = () => {
  const { menuItems, menuItemsList, limit, currentPage, totalMenuItems } =
    useTypedSelector((state) => state.menuItem);
  const { menus } = useTypedSelector((state) => state.menu);

  const [sort, setSort] = useState('DESC');
  const [edit, setEdit] = useState(false);

  const menuItemTree = createTreeMenu(menuItems);

  const { fetchMenuItemsList, fetchMenus, fetchMenuItemsForSelect } =
    useActions();

  const menuOptions = SelectOptionHelper(menus);

  const [form] = Form.useForm();

  useEffect(() => {
    fetchMenuItemsForSelect();
    fetchMenus({ limit: 0 });
    fetchMenuItemsList({
      limit: limit,
      page: currentPage,
      sortId: sort,
      title: form.getFieldValue('title'),
      menuId: form.getFieldValue('menu'),
      status: form.getFieldValue('status'),
      parentId: form.getFieldValue('parent'),
    });
  }, [edit]);

  const onShowSizeChange = (currentPage: number, limit: number) => {
    fetchMenuItemsList({
      limit: limit,
      page: currentPage,
      sortId: sort,
      title: form.getFieldValue('title'),
      menuId: form.getFieldValue('menu'),
      status: form.getFieldValue('status'),
      parentId: form.getFieldValue('parent'),
    });
  };

  const handlePagination = (currentPage: number, limit: number) => {
    fetchMenuItemsList({
      limit: limit,
      page: currentPage,
      sortId: sort,
      title: form.getFieldValue('title'),
      menuId: form.getFieldValue('menu'),
      status: form.getFieldValue('status'),
      parentId: form.getFieldValue('parent'),
    });
  };

  const applyFilters = () => {
    fetchMenuItemsList({
      limit: limit,
      page: 1,
      sortId: sort,
      title: form.getFieldValue('title'),
      menuId: form.getFieldValue('menu'),
      status: form.getFieldValue('status'),
      parentId: form.getFieldValue('parent'),
    });
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.field === 'id') {
      if (sorter.order === 'ascend') {
        setSort('ASC');
        fetchMenuItemsList({
          limit: limit,
          page: currentPage,
          sortId: 'ASC',
        });
      } else {
        setSort('DESC');
        fetchMenuItemsList({
          limit: limit,
          page: currentPage,
          sortId: 'DESC',
        });
      }
    }
  };

  const columns = [
    {
      title: 'ID пункта меню',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => {
        return (
          <div key={id}>
            <Link to={`/menu/menu-item/update/${id}`}>{id}</Link>
          </div>
        );
      },
      sorter: true,
    },
    {
      title: 'Заголовок',
      dataIndex: 'title',
      key: 'title',
      render: (title: string) => {
        if (title) {
          return <>{title}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Адрес',
      dataIndex: 'href',
      key: 'href',
      render: (href: string) => {
        if (href) {
          return <>{href}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Меню',
      key: 'menu',
      render: (data: any) => {
        if (data.menu) {
          return <>{data.menu.name}</>;
        } else {
          return 'Не указано';
        }
      },
    },
    {
      title: 'Родитель',
      dataIndex: 'parentMenuItem',
      key: 'parentMenuItem',
      render: (parentMenuItem: any) => {
        if (parentMenuItem) {
          return <>{parentMenuItem.title}</>;
        } else {
          return '---';
        }
      },
    },
    {
      title: 'Статус',
      key: 'status',
      render: (data: any) => {
        return (
          <StatusEditor
            status={data.status}
            id={data.id}
            options={statusMenuOptions}
            setEdit={setEdit}
            url={ApiUrl.UPDATE_MENU_ITEM_STATUS}
          />
        );
      },
    },
    {
      title: 'Действия с пунктом меню',
      dataIndex: 'id',
      key: 'edit',
      render: (id: string) => {
        return (
          <>
            <Link to={`/menu/menu-item/update/${id}`}>
              <EditOutlined style={{ marginLeft: 50 }} />
            </Link>
          </>
        );
      },
    },
  ];

  return (
    <div className={styles.main}>
      <h1>Пункты меню</h1>
      <h4 style={{ textAlign: 'left' }}>Фильтры полей</h4>
      <Form form={form}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <FormInput
              name={'title'}
              label={'Заголовок'}
              placeholder={'Заголовок'}
              search={true}
              onSearch={(value) => {
                fetchMenuItemsList({
                  limit: limit,
                  page: currentPage,
                  sortId: sort,
                  title: value,
                });
              }}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={12}>
            <FormSelect
              name={'menu'}
              label={'Меню'}
              placeholder={'Выберите меню'}
              options={menuOptions}
              showSearch={true}
              labelCol={{ span: 24 }}
            />
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={12}>
            <FormSelect
              name={'status'}
              label={'Статус'}
              placeholder={'Выберите статус'}
              options={statusMenuOptions}
              showSearch={true}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={12}>
            <FormTreeSelect
              name={'parent'}
              label={'Родитель'}
              placeholder={'Выберите родителя'}
              treeData={menuItemTree.length > 0 ? menuItemTree : undefined}
              showSearch={true}
              labelCol={{ span: 24 }}
            />
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={24} style={{ marginBottom: 20 }}>
            <Button type="primary" htmlType="submit" onClick={applyFilters}>
              Применить фильтры
            </Button>
            <Button
              style={{ marginLeft: 20 }}
              type="ghost"
              htmlType="reset"
              onClick={() => {
                return fetchMenuItemsList({
                  limit: limit,
                  page: 1,
                  sortId: sort,
                });
              }}
            >
              Очистить фильтры
            </Button>
          </Col>
        </Row>
      </Form>
      <Pagination
        style={{ marginBottom: 20 }}
        total={totalMenuItems}
        current={currentPage}
        showQuickJumper={true}
        onChange={handlePagination}
        onShowSizeChange={onShowSizeChange}
        pageSizeOptions={[10, 25, 50, 100]}
        defaultPageSize={25}
      />
      <Table
        bordered
        columns={columns}
        dataSource={menuItemsList}
        pagination={false}
        onChange={handleChange}
        rowKey="id"
      />
    </div>
  );
};
