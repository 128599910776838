import React from 'react';
import { Tabs } from 'antd';
import styles from './Order.module.scss';
import { OrderInfo } from './TabsForm/OrderInfo';
import { YandexUTM } from './TabsForm/YandexUTM';
import { ROLES } from '../../../types/constants';

export const Order: React.FC = () => {
  const items = [
    {
      label: 'ОБЩЕЕ',
      key: '1',
      children: <OrderInfo />,
      roles: [ROLES.content],
    },
    {
      label: 'UTM МЕТКИ',
      key: '2',
      children: <YandexUTM />,
      roles: [ROLES.content],
    },
  ];

  return (
    <div className={styles.main}>
      <h1>Редактирование заказа</h1>
      <Tabs defaultActiveKey="1" items={items} />
    </div>
  );
};
