import { Link, Route } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { ComponentsLayoutBlock } from '../../layout/ComponentLayoutBlock/ComponentLayoutBlock';
import { ManualRequests } from '../../Pages/AdditiaonalServices/ManualRequests/ManualRequests';
import { RolesInterceptor } from '../../components/Interceptors/RolesInterceptor';
import { ROLES } from '../../types/constants';


export const AdditionalServices = (
  <>
    <Route
      path="/manual-requests"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayoutBlock>
            <RolesInterceptor roles={[ROLES.admin]}>
              <ManualRequests />
            </RolesInterceptor>
          </ComponentsLayoutBlock>
        </>
      }
    />
  </>
);
