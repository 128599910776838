import { Dispatch } from 'redux';

import { ApiUrl } from '../../../types/ApiUrl';
import {
  OrderAction,
  OrderActionTypes,
} from '../../../types/entities/Order/Order';
import { $api } from '../../../http';

interface queryOrderParamsInterface {
  limit?: any;
  page?: any;
  sortId?: string;
  sortPrice?: string;
  name?: string;
  phone?: string;
  totalPrice?: string;
  userId?: string;
  statusId?: string;
  managerId?: string;
  numberOneC?: string;
  numberID?: string;
}

export const fetchOrders = (queryParams: queryOrderParamsInterface) => {
  const {
    limit = '25',
    page = 1,
    sortId = 'DESC',
    sortPrice,
    name,
    phone,
    totalPrice,
    userId,
    statusId,
    managerId,
    numberOneC,
    numberID,
  } = queryParams;

  const urlOrderParams = new URLSearchParams();
  urlOrderParams.append('limit', limit);
  urlOrderParams.append('page', page);
  urlOrderParams.append('sortId', sortId);
  if (sortPrice !== undefined) urlOrderParams.append('sortPrice', sortPrice);
  if (name !== undefined) urlOrderParams.append('name', name);
  if (phone !== undefined) urlOrderParams.append('phone', phone);
  if (totalPrice !== undefined) urlOrderParams.append('totalPrice', totalPrice);
  if (userId !== undefined) urlOrderParams.append('userId', userId);
  if (statusId !== undefined) urlOrderParams.append('statusId', statusId);
  if (managerId !== undefined) urlOrderParams.append('managerId', managerId);
  if (numberOneC !== undefined) urlOrderParams.append('numberOneC', numberOneC);
  if (numberID !== undefined) urlOrderParams.append('id', numberID);

  return async (dispatch: Dispatch<OrderAction>) => {
    try {
      dispatch({ type: OrderActionTypes.FETCH_ORDER });
      const response = await $api.get(
        `${ApiUrl.ORDER}?${urlOrderParams.toString()}`,
      );
      dispatch({
        type: OrderActionTypes.FETCH_ORDERS,
        payload: response.data.items,
      });
      dispatch({
        type: OrderActionTypes.SET_TOTAL_ORDER_PAGE,
        payload: response.data.total,
      });
      dispatch({ type: OrderActionTypes.SET_ORDER_PAGE, payload: page });
    } catch (e) {
      dispatch({
        type: OrderActionTypes.FETCH_ORDERS_ERROR,
        payload: 'Произошла ошибка при загрузке магазинов',
      });
    }
  };
};
