export interface IMenuItem {
  id: number;
  title: string;
  parentId: number;
  regularLink: boolean;
  href: string;
  class: string;
  titleAttr: string;
  beforeLink: string;
  afterLink: string;
  target: string;
  rel: string;
  conditionName: string;
  conditionDenial: string;
  sort: number;
  status: number;
  menuId: number;
}

export interface MenuItemState {
  menuItems: IMenuItem[];
  menuItemsList: IMenuItem[];
  error: string | null;
  loading: boolean;
  currentPage: number;
  limit: number;
  totalMenuItems: number;
}

export enum MenuItemActionTypes {
  FETCH_MENU_ITEM = 'FETCH_MENU_ITEM',
  FETCH_MENU_ITEMS = 'FETCH_MENU_ITEMS',
  FETCH_MENU_ITEMS_ERROR = 'FETCH_MENU_ITEMS_ERROR',
  SET_MENU_ITEM_PAGE = 'SET_MENU_ITEM_PAGE',
  SET_TOTAL_MENU_ITEM_PAGE = 'SET_TOTAL_MENU_ITEM_PAGE',
  FETCH_MENU_ITEMS_SELECT = 'FETCH_MENU_ITEMS_SELECT',
}

interface FetchMenuItemAction {
  type: MenuItemActionTypes.FETCH_MENU_ITEM;
}

interface FetchMenuItemsAction {
  type: MenuItemActionTypes.FETCH_MENU_ITEMS;
  payload: IMenuItem[];
}

interface FetchMenuItemsErrorAction {
  type: MenuItemActionTypes.FETCH_MENU_ITEMS_ERROR;
  payload: string;
}

interface SetMenuItemPageAction {
  type: MenuItemActionTypes.SET_MENU_ITEM_PAGE;
  payload: number;
}

interface SetTotalMenuItemPageAction {
  type: MenuItemActionTypes.SET_TOTAL_MENU_ITEM_PAGE;
  payload: number;
}

interface FetchMenuItemsSelectAction {
  type: MenuItemActionTypes.FETCH_MENU_ITEMS_SELECT;
  payload: IMenuItem[];
}

export type MenuItemAction =
  | FetchMenuItemAction
  | FetchMenuItemsAction
  | FetchMenuItemsErrorAction
  | SetMenuItemPageAction
  | SetTotalMenuItemPageAction
  | FetchMenuItemsSelectAction;
