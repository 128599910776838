import React from "react";
import "./Dashboard.scss"
import { Button } from "antd";
import NotificationAlert from "../../components/Notification";
import { useHttp } from "../../hooks/useHttp";
import { ApiUrl } from "../../types/ApiUrl";

export const Dashboard: React.FC = () => {
  const { request } = useHttp();

  const updateCache = async () => {
    try {
      await request({
        url: `${ApiUrl.UPDATE_CACHE_MAIN}`,
        method: "get"
      });
      return NotificationAlert("success", "Кэш обновлён");
    } catch (e) {
      console.log(e);
    }
  };

  const resetRedisCache = async () => {
    try {
      await request({
        url: `${ApiUrl.RESET_CACHE_REDIS}`,
        method: "get"
      });
      return NotificationAlert("success", "Кэш в Redis сброшен");
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <h1>Админка</h1>

      <div className="admin-buttons">
        <Button type="primary" onClick={updateCache}>
          Обновить кэш главной страницы
        </Button>

        <Button type="primary" onClick={resetRedisCache}>
          Сбросить кэш в Redis
        </Button>
      </div>
    </div>
  );
};
