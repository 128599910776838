import React from 'react';
import { Link, Route } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import { AddProducer } from '../../Pages/Producers/Producer/AddProducer/AddProducer';
import { ComponentsLayout } from '../../layout/ComponentsLayout/ComponentsLayout';
import { ProducersList } from '../../Pages/Producers/ProducersList/ProducersList';
import { UpdateProducer } from '../../Pages/Producers/Producer/UpdateProducer/UpdateProducer';

export const ProducerRoutes = (
  <>
    <Route
      path="/producers/add"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Добавить производителя</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <AddProducer />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/producers/update/:id"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/producers/list">Список производителей</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Обновление производителя</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <UpdateProducer />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/producers/list"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Список производителей</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <ProducersList />
          </ComponentsLayout>
        </>
      }
    />
  </>
);
