import { FC, useEffect, useState } from 'react';
import { ApiUrl } from '../../types/ApiUrl';
import { useHttp } from '../../hooks/useHttp';
import { Link } from 'react-router-dom';
import { Button, Col, Form, Pagination, Row, Table } from 'antd';
import FormInput from '../../components/FormItems/FormInput';
import { DeleteOutlined } from '@ant-design/icons';
import styles from './CommentsList.module.scss';

export const CommentsList: FC = () => {
  const { request } = useHttp();
  const [comments, setComments] = useState([]);
  const [sort, setSort] = useState('ASC');
  const [limit, setLimit] = useState(15);
  const [totalComments, setTotalComents] = useState(100);
  const [currentPage, setCurrentPage] = useState(1);

  const fetchData = async (params?: any) => {
    const response = await request({
      url: `${ApiUrl.COMMENTS}/all`,
      method: 'get',
      params,
    });
    setComments(response.data.data);
    setTotalComents(response.data.total);
  };

  useEffect(() => {
    fetchData();
  }, []);

  const applyFilters = () => {
    fetchData({
      limit: limit,
      page: 1,
      sortId: sort,
      filters: {
        id: form.getFieldValue('id'),
        userId: form.getFieldValue('userId'),
        productId: form.getFieldValue('productId'),
      },
    });
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.field === 'id') {
      if (sorter.order === 'ascend') {
        setSort('ASC');
        fetchData({ limit: limit, page: currentPage, sortId: 'ASC' });
      } else {
        setSort('DESC');
        fetchData({
          limit: limit,
          page: currentPage,
          sortId: 'DESC',
        });
      }
    }
  };

  const handlePagination = (currentPage: number, limit: number) => {
    setCurrentPage(currentPage);
    fetchData({
      limit: limit,
      page: currentPage,
      sortId: sort,
    });
  };

  const [form] = Form.useForm();

  const columns = [
    {
      title: 'ID отзыва',
      dataIndex: 'id',
      key: 'id',
      render: (id: string) => {
        return <div key={id}>{id}</div>;
      },
      sorter: true,
    },
    {
      title: 'id товара',
      dataIndex: 'productId',
      key: 'productId',
      render: (productId: number) => {
        if (productId) {
          return <Link to={`/products/update/${productId}`}>{productId}</Link>;
        } else {
          return '';
        }
      },
    },
    {
      title: 'id пользователя',
      dataIndex: 'userId',
      key: 'userId',
      render: (userId: number) => {
        if (userId) {
          return <Link to={`/users/update/${userId}`}>{userId}</Link>;
        } else {
          return '';
        }
      },
    },
    {
      title: 'достоинства',
      dataIndex: 'advantages',
      key: 'advantages',
      render: (advantages: string) => {
        if (advantages) {
          return <div className={styles.ceil}>{advantages}</div>;
        } else {
          return 'Пусто';
        }
      },
    },
    {
      title: 'недостатки',
      dataIndex: 'disadvantages',
      key: 'disadvantages',
      render: (disadvantages: string) => {
        if (disadvantages) {
          return <div className={styles.ceil}>{disadvantages}</div>;
        } else {
          return 'Пусто';
        }
      },
    },
    {
      title: 'комментарий',
      dataIndex: 'comment',
      key: 'comment',
      render: (comment: string) => {
        if (comment) {
          return <div className={styles.ceil}>{comment}</div>;
        } else {
          return 'Пусто';
        }
      },
    },
    {
      title: 'оценка',
      dataIndex: 'score',
      key: 'score',
      render: (comment: number) => {
        if (comment) {
          return <>{comment}</>;
        } else {
          return 'Пусто';
        }
      },
    },
    {
      title: 'Действия с отзывом',
      dataIndex: 'id',
      key: 'id',
      render: (id: number) => {
        return (
          <Button
            onClick={async (e) => {
              const response = await request({
                url: `${ApiUrl.COMMENTS}/${id}`,
                method: 'delete',
              });
              fetchData();
            }}
          >
            <DeleteOutlined />
          </Button>
        );
      },
    },
  ];

  return (
    <div>
      <Form form={form}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={8}>
            <FormInput
              name={'id'}
              label={'id'}
              placeholder={'Введите id'}
              search={true}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={8}>
            <FormInput
              name={'userId'}
              label={'id пользователя'}
              placeholder={'Введите id пользователя'}
              search={true}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={8}>
            <FormInput
              name={'productId'}
              label={'id товара'}
              placeholder={'Введите id товара'}
              search={true}
              labelCol={{ span: 24 }}
            />
          </Col>
        </Row>
        <Row
          gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}
          style={{ marginBottom: '15px' }}
        >
          <Col span={12}>
            <Button
              style={{ marginTop: 40 }}
              type="primary"
              htmlType="submit"
              onClick={applyFilters}
            >
              Применить фильтры
            </Button>
            <Button
              style={{ marginLeft: 20 }}
              type="ghost"
              htmlType="reset"
              onClick={() => {
                return fetchData({
                  limit: limit,
                  page: 1,
                  sortId: sort,
                });
              }}
            >
              Очистить фильтры
            </Button>
          </Col>
        </Row>
      </Form>
      <Pagination
        style={{ marginBottom: 20 }}
        total={totalComments}
        current={currentPage}
        showQuickJumper={true}
        onChange={handlePagination}
        // onShowSizeChange={onShowSizeChange}
        pageSizeOptions={[10, 25, 50, 100]}
        defaultPageSize={limit}
      />
      <Table
        bordered
        columns={columns}
        dataSource={comments}
        pagination={false}
        onChange={handleChange}
        rowKey="id"
      />
    </div>
  );
};
