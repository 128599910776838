import React from 'react';
import { Link, Route } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import { ComponentsLayout } from '../../layout/ComponentsLayout/ComponentsLayout';
import { PagesList } from '../../Pages/Pages/PagesList/PagesList';
import { AddPage } from '../../Pages/Pages/Page/AddPage/AddPage';
import { UpdatePage } from '../../Pages/Pages/Page/UpdatePage/UpdatePage';
import { ComponentsLayoutBlock } from '../../layout/ComponentLayoutBlock/ComponentLayoutBlock';
import { UpdateMainPage } from '../../Pages/Pages/MainPage/UpdateMainPage/UpdateMainPage';

export const PageRoutes = (
  <>
    <Route
      path="/content/pages/add"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Добавить страницу</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayoutBlock>
            <AddPage />
          </ComponentsLayoutBlock>
        </>
      }
    />
    <Route
      path="/content/pages/update/:id"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/categories/list">Список страниц</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Обновление страницы</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayoutBlock>
            <UpdatePage />
          </ComponentsLayoutBlock>
        </>
      }
    />
    <Route
      path="/content/pages/list"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Список страниц</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <PagesList />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/content/pages/main"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Главная страница</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <UpdateMainPage />
          </ComponentsLayout>
        </>
      }
    />
  </>
);
