import React, { useEffect } from 'react';
import { Button, Form } from 'antd';
import NotificationAlert from '../../components/Notification';
import { useHttp } from '../../hooks/useHttp';
import { ApiUrl } from '../../types/ApiUrl';
import FormInput from '../../components/FormItems/FormInput';

export const Constants: React.FC = () => {
  const [form] = Form.useForm();
  const { request } = useHttp();

  const onFinish = async (e: any) => {
    const response = await request({
      url: `${ApiUrl.CONSTANTS}`,
      method: 'put',
      body: e,
    });
  };

  const init = async () => {
    const fetchConstants = await request({
      url: `${ApiUrl.CONSTANTS}`,
      method: 'get',
    });
    form.setFieldsValue({
      moneyRefundEmail: fetchConstants.data?.moneyRefundEmail || '',
      onlineSalesEmail: fetchConstants.data?.onlineSalesEmail || '',
      warrantyEmail: fetchConstants.data?.warrantyEmail || '',
      retailSalesEmail: fetchConstants.data?.retailSalesEmail || '',
      secretaryEmail: fetchConstants.data?.secretaryEmail || '',
      hrEmail: fetchConstants.data?.hrEmail || '',
      attributesEmail: fetchConstants.data?.attributesEmail || '',
    });
  };

  useEffect(() => {
    init();
  }, []);
  return (
    <Form form={form} onFinish={onFinish}>
      <FormInput
        name="moneyRefundEmail"
        label="Почта для завлений о возврате"
      ></FormInput>
      <FormInput
        name="onlineSalesEmail"
        label="Почта руководителя отдела интернет продаж"
      ></FormInput>
      <FormInput
        name="warrantyEmail"
        label="Почта руководителя отдела гарантийного обслуживания"
      ></FormInput>
      <FormInput
        name="retailSalesEmail"
        label="Почта руководителя розничных продаж"
      ></FormInput>
      <FormInput
        name="secretaryEmail"
        label="Почта секретаря"
      ></FormInput>
      <FormInput
        name="hrEmail"
        label="Почта менеджера по найму"
      ></FormInput>
      <FormInput
        name="attributesEmail"
        label="Почта специалиста по контенту"
      ></FormInput>
      <Button type="primary" htmlType="submit">
        Обновить
      </Button>
    </Form>
  );
};
