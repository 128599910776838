import React, { useEffect, useState } from "react";
import { Button, Col, Form, Pagination, Popconfirm, Row, Table } from "antd";
import { DeleteOutlined, EditOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";

import styles from "./ImagesList.module.scss";
import { useTypedSelector } from "../../../hooks/useTypedSelector";
import { useActions } from "../../../hooks/useActions";
import { StatusEditor } from "../../../components/Editors/StatusEditor";
import { ApiUrl } from "../../../types/ApiUrl";
import { statusImageOptions } from "../../../helpers/imageHelper";
import { GeoZoneEditor } from "../../../components/Editors/GeoZoneEditor";
import { SelectOptionHelper } from "../../../helpers/selectHelper";
import NotificationAlert from "../../../components/Notification";
import { useHttp } from "../../../hooks/useHttp";
import FormInput from "../../../components/FormItems/FormInput";
import FormSelect from "../../../components/FormItems/FormSelect";
import { useClipboard } from "use-clipboard-copy";

export const ImagesList: React.FC = () => {
  const { images, limit, currentPage, totalImages } = useTypedSelector(
    (state) => state.image
  );
  const { geoZones } = useTypedSelector((state) => state.geoZone);
  const { sliderGalleries } = useTypedSelector((state) => state.sliderGallery);
  const { request } = useHttp();

  const [sort, setSort] = useState("DESC");
  const [edit, setEdit] = useState(false);
  const [editGeo, setEditGeo] = useState(false);

  const { fetchImages, fetchGeoZonesForSelect, fetchSliderGalleries } =
    useActions();

  const geoZoneOptions = SelectOptionHelper(geoZones);
  const sliderOptions = SelectOptionHelper(sliderGalleries);

  const [form] = Form.useForm();

  const clipboard = useClipboard();

  const deleteImage = async (imageId: number) => {
    try {
      let sliders;
      if (Array.isArray(form.getFieldValue("gallery"))) {
        sliders = form.getFieldValue("gallery").join(",");
      }

      await request({
        url: `${ApiUrl.IMAGE}/${imageId}`,
        method: "delete"
      });
      await fetchImages({
        limit: limit,
        page: currentPage,
        sortId: sort,
        id: form.getFieldValue("id"),
        name: form.getFieldValue("name"),
        geoZoneId: form.getFieldValue("geoZoneId"),
        status: form.getFieldValue("status"),
        sliders: sliders
      });
      return NotificationAlert("success", "Изображение удалено");
    } catch (e) {
    }
  };

  useEffect(() => {
    let sliders;
    if (Array.isArray(form.getFieldValue("gallery"))) {
      sliders = form.getFieldValue("gallery").join(",");
    }
    fetchSliderGalleries({ limit: "0" });
    fetchGeoZonesForSelect();
    fetchImages({
      limit: limit,
      page: currentPage,
      sortId: sort,
      id: form.getFieldValue("id"),
      name: form.getFieldValue("name"),
      geoZoneId: form.getFieldValue("geoZoneId"),
      status: form.getFieldValue("status"),
      sliders: sliders
    });
  }, [edit, editGeo]);

  const onShowSizeChange = (currentPage: number, limit: number) => {
    let sliders;
    if (Array.isArray(form.getFieldValue("gallery"))) {
      sliders = form.getFieldValue("gallery").join(",");
    }

    fetchImages({
      limit: limit,
      page: currentPage,
      sortId: sort,
      id: form.getFieldValue("id"),
      name: form.getFieldValue("name"),
      geoZoneId: form.getFieldValue("geoZoneId"),
      status: form.getFieldValue("status"),
      sliders: sliders
    });
  };

  const handlePagination = (currentPage: number, limit: number) => {
    let sliders;
    if (Array.isArray(form.getFieldValue("gallery"))) {
      sliders = form.getFieldValue("gallery").join(",");
    }

    fetchImages({
      limit: limit,
      page: currentPage,
      sortId: sort,
      id: form.getFieldValue("id"),
      name: form.getFieldValue("name"),
      geoZoneId: form.getFieldValue("geoZoneId"),
      status: form.getFieldValue("status"),
      sliders: sliders
    });
  };

  const applyFilters = () => {
    let sliders;
    if (Array.isArray(form.getFieldValue("gallery"))) {
      sliders = form.getFieldValue("gallery").join(",");
    }

    fetchImages({
      limit: limit,
      page: 1,
      sortId: sort,
      id: form.getFieldValue("id"),
      name: form.getFieldValue("name"),
      geoZoneId: form.getFieldValue("geoZoneId"),
      status: form.getFieldValue("status"),
      sliders: sliders
    });
  };

  const handleChange = (pagination: any, filters: any, sorter: any) => {
    if (sorter.field === "id") {
      if (sorter.order === "ascend") {
        setSort("ASC");
        fetchImages({ limit: limit, page: currentPage, sortId: "ASC" });
      } else {
        setSort("DESC");
        fetchImages({ limit: limit, page: currentPage, sortId: "DESC" });
      }
    }
  };

  const copyImageLink = (event: React.MouseEvent<HTMLDivElement>) => {
    const link = (event.target as HTMLDivElement).outerText;
    clipboard.copy(link);
  };

  const copyPageLink = (event: React.MouseEvent<HTMLDivElement>) => {
    const link = (event.target as HTMLDivElement).outerText;
    clipboard.copy(link);
  };

  const columns = [
    {
      title: "ID изображения",
      dataIndex: "id",
      key: "id",
      render: (id: string) => {
        return (
          <div key={id}>
            <Link to={`/content/images/update/${id}`}>{id}</Link>
          </div>
        );
      },
      sorter: true
    },
    {
      title: "Позиция",
      key: "position",
      render: (data: any) => {
        if (data.sliders.length && data.sliders[0].SliderGalleryImage) {
          return <>{data.sliders[0].SliderGalleryImage.position}</>;
        } else {
          return "---";
        }
      }
    },
    {
      title: "Изображение",
      key: "image",
      render: (data: any) => {
        if (data.filePath) {
          return (
            <img
              alt={data.alt}
              style={{ maxWidth: 150 }}
              src={`${ApiUrl.FILE}/${data.filePath}?dir=image&root=0`}
            />
          );
        } else {
          return "Изображение не выбрано";
        }
      }
    },
    {
      title: "Ссылка на изображение",
      dataIndex: "filePath",
      key: "filePath",
      render: (filePath: string) => {
        if (filePath) {
          return <div style={{ cursor: "pointer" }} onClick={copyImageLink}>
            {`https://www.admin.set-tehniki.com/api/file/${filePath}?dir=image&amp;root=0?`}
          </div>;
        } else {
          return "---";
        }
      }
    },
    {
      title: "Ссылка на страницу",
      dataIndex: "link",
      key: "link",
      render: (link: string) => {
        if (link) {
          return <div style={{ cursor: "pointer" }} onClick={copyPageLink}>
            {link}
          </div>;
        } else {
          return "---";
        }
      }
    },
    {
      title: "Название",
      dataIndex: "name",
      key: "name",
      render: (name: string) => {
        if (name) {
          return <>{name}</>;
        } else {
          return "---";
        }
      }
    },
    {
      title: "Пользователь",
      key: "updateUserId",
      render: (data: any) => {
        const user = data.updateUser;
        if (user) {
          return (
            `${user.lastName !== null ? user.lastName : ""}` +
            " " +
            `${user.firstName !== null ? user.firstName : ""}` +
            " " +
            `${user.middleName !== null ? user.middleName : ""}`
          );
        } else {
          return "---";
        }
      }
    },
    {
      title: "Галерея",
      key: "sliders",
      render: (data: any) => {
        if (data.sliders.length > 0) {
          return data.sliders.map((item: any, index: any) => {
            return (
              <span key={item.id}>
                {item.name}
                {index === data.sliders.length - 1 ? "" : ", "}
              </span>
            );
          });
        } else {
          return "---";
        }
      }
    },
    {
      title: "Статус",
      key: "status",
      render: (data: any) => {
        return (
          <StatusEditor
            status={data.status}
            id={data.id}
            options={statusImageOptions}
            setEdit={setEdit}
            url={ApiUrl.UPDATE_IMAGE_STATUS}
          />
        );
      }
    },
    {
      title: "Геозона",
      key: "geoZoneId",
      render: (data: any) => {
        if (data.geoZoneId) {
          return (
            <GeoZoneEditor
              geoZoneId={data.geoZoneId}
              id={data.id}
              options={geoZoneOptions}
              setEdit={setEditGeo}
              url={ApiUrl.UPDATE_IMAGE_GEO_ZONE}
            />
          );
        } else {
          return "---";
        }
      }
    },
    {
      title: "Действия с изображением",
      key: "actions",
      render: (data: any) => {
        return (
          <div style={{ display: "flex", justifyContent: "center" }}>
            <Button type="link" style={{ color: "inherit" }}>
              <Link to={`/content/images/update/${data.id}`}>
                <EditOutlined />
              </Link>
            </Button>
            <Button type="link" style={{ color: "inherit" }}>
              <Popconfirm
                title={"Вы точно хотите удалить изображение?"}
                onConfirm={() => deleteImage(data.id)}
              >
                <DeleteOutlined style={{ cursor: "pointer" }} />
              </Popconfirm>
            </Button>
          </div>
        );
      }
    }
  ];

  return (
    <div className={styles.main}>
      <h1>Список изображений</h1>
      <h4 style={{ textAlign: "left" }}>Фильтры полей</h4>
      <Form form={form}>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={8}>
            <FormInput
              name={"id"}
              label={"ID"}
              placeholder={"Введите ID"}
              search={true}
              onSearch={(value) => {
                fetchImages({
                  limit: limit,
                  page: currentPage,
                  sortId: sort,
                  id: value
                });
              }}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={8}>
            <FormInput
              name={"name"}
              label={"Название"}
              placeholder={"Введите название"}
              search={true}
              onSearch={(value) => {
                fetchImages({
                  limit: limit,
                  page: currentPage,
                  sortId: sort,
                  name: value
                });
              }}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={8}>
            <FormSelect
              name={"geoZoneId"}
              label={"Геозона"}
              placeholder={"Выберите геозону"}
              options={geoZoneOptions}
              showSearch={true}
              labelCol={{ span: 24 }}
            />
          </Col>
        </Row>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col span={8}>
            <FormSelect
              name={"status"}
              label={"Статус"}
              placeholder={"Выберите статус"}
              options={statusImageOptions}
              showSearch={true}
              labelCol={{ span: 24 }}
            />
          </Col>
          <Col span={8}>
            <FormSelect
              name={"gallery"}
              label={"Галерея"}
              placeholder={"Выберите галерею"}
              options={sliderOptions}
              showSearch={true}
              mode={"multiple"}
              labelCol={{ span: 24 }}
            />
          </Col>
        </Row>
        <Button
          style={{ marginBottom: 20 }}
          type="primary"
          htmlType="submit"
          onClick={applyFilters}
        >
          Применить фильтры
        </Button>
        <Button
          style={{ marginLeft: 20 }}
          type="ghost"
          htmlType="reset"
          onClick={() => {
            return fetchImages({
              limit: limit,
              page: 1,
              sortId: sort
            });
          }}
        >
          Очистить фильтры
        </Button>
      </Form>
      <Pagination
        style={{ marginBottom: 20 }}
        total={totalImages}
        current={currentPage}
        showQuickJumper={true}
        onChange={handlePagination}
        onShowSizeChange={onShowSizeChange}
        pageSizeOptions={[10, 25, 50, 100]}
        defaultPageSize={25}
      />
      <Table
        bordered
        columns={columns}
        dataSource={images}
        pagination={false}
        onChange={handleChange}
        rowKey="id"
      />
    </div>
  );
};
