import { Form, TreeSelect } from 'antd';
import React from 'react';

interface ITreeSelect {
  name: string;
  label: string;
  required?: boolean;
  placeholder: string;
  labelCol?: { span: number };
  treeData: any;
  showSearch?: boolean;
  valuePropName?: 'option';
  multiple?: boolean;
  onChange?: (value: number[]) => void;
}

const FormTreeSelect: React.FC<ITreeSelect> = ({
  name,
  label,
  required,
  valuePropName,
  placeholder,
  labelCol,
  showSearch,
  treeData,
  multiple,
  onChange,
}) => {
  const settings = {
    name,
    label,
    rules: required
      ? [
          {
            required: true,
            message: 'Пожалуйста, заполните поле!',
          },
        ]
      : undefined,
    valuePropName: valuePropName ? valuePropName : 'value',
    labelCol,
  };

  return (
    <Form.Item {...settings}>
      <TreeSelect
        multiple={multiple}
        placeholder={placeholder}
        showSearch={showSearch}
        allowClear
        treeData={treeData}
        treeLine={{ showLeafIcon: false }}
        treeNodeFilterProp="title"
        onChange={onChange}
      />
    </Form.Item>
  );
};

export default FormTreeSelect;
