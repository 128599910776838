import React from 'react';
import { Link, Route } from 'react-router-dom';
import { Breadcrumb } from 'antd';

import { ComponentsLayout } from '../../layout/ComponentsLayout/ComponentsLayout';
import { SlidersList } from '../../Pages/Sliders/SlidersList/SlidersList';
import { AddSlider } from '../../Pages/Sliders/Slider/AddSlider/AddSlider';
import { UpdateSlider } from '../../Pages/Sliders/Slider/UpdateSlider/UpdateSlider';

export const SliderRoutes = (
  <>
    <Route
      path="/content/sliders/add"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Добавить галерею</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <AddSlider />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/content/sliders/update/:id"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/content/sliders/list">Список галерей</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Обновление галереи</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <UpdateSlider />
          </ComponentsLayout>
        </>
      }
    />
    <Route
      path="/content/sliders/list"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Список галерей</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <SlidersList />
          </ComponentsLayout>
        </>
      }
    />
  </>
);
