import { Link, Route } from 'react-router-dom';
import { Breadcrumb } from 'antd';
import { ComponentsLayoutBlock } from '../../layout/ComponentLayoutBlock/ComponentLayoutBlock';
import { ComponentsLayout } from '../../layout/ComponentsLayout/ComponentsLayout';
import { ArticlesList } from '../../Pages/Articles/ArticlesList/ArticlesList';
import { UpdateArticles } from '../../Pages/Articles/Articles/UpdateArticles/UpdateArticles';
import { AddArticles } from '../../Pages/Articles/Articles/AddArticles/AddArticles';


export const ArticlesRoutes = (
  <>
    <Route
      path="/content/articles/add"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Добавить статью</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayoutBlock>
            <AddArticles />
          </ComponentsLayoutBlock>
        </>
      }
    />
    <Route
      path="/content/articles/update/:id"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>
              <Link to="/content/articles/list">Список статей</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Обновление статьи</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayoutBlock>
            <UpdateArticles />
          </ComponentsLayoutBlock>
        </>
      }
    />
    <Route
      path="/content/articles/list"
      element={
        <>
          <Breadcrumb>
            <Breadcrumb.Item>
              <Link to="/home">Админка</Link>
            </Breadcrumb.Item>
            <Breadcrumb.Item>Список статей</Breadcrumb.Item>
          </Breadcrumb>
          <ComponentsLayout>
            <ArticlesList />
          </ComponentsLayout>
        </>
      }
    />
  </>
);
