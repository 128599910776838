import React from 'react';

import { Image } from '../Image';
import { UPDATE_SUBMIT_METHOD } from '../../../../types/constants';

export const UpdateImage: React.FC = () => {
  const titlePage = 'Обновление изображения';
  const submitMethod = UPDATE_SUBMIT_METHOD;

  const props = {
    titlePage: titlePage,
    submitMethod: submitMethod,
  };

  return (
    <>
      <Image {...props} />
    </>
  );
};
